import addCheckTemplate from "./project/add-check/add-check-controller.html";
import addFormCheckTemplate from "./project/add-form-check/add-form-check-controller.html";
import addMultipleChecksTemplate from "./project/add-multiple-checks/add-multiple-checks-controller.html";
import createGroupTemplate from "./project/create-group/create-group-controller.html";
import projectChecksTemplate from "./project/project-checks/project-checks-ctrl.html";
import projectReportsTemplate from "./project/project-reports/project-reports-controller.html";
import projectSettingsTemplate from "./project/project-settings/project-settings-ctrl.html";
import manageUsersTemplate from "./project/project-users/manage-users-controller.html";
import testTemplate from "./project/test/test-controller.html";
import { areaResolve } from "./resolvers/area-resolve.js";
import { areasResolve } from "./resolvers/areas-resolve.js";
import { statusResolve } from "./resolvers/status-resolve.js";
import { testResolve } from "./resolvers/test-resolve.js";

export function config(
	$httpProvider,
	$routeProvider,
	$locationProvider,
	$sceDelegateProvider,
) {
	$httpProvider.interceptors.push("testomatoErrorHandler");
	$locationProvider.hashPrefix("");

	if (window.location.hostname === "testomato.local") {
		$sceDelegateProvider.resourceUrlWhitelist([
			"self",
			"http://testomato.local:8080/**",
		]); // Enable webpack hot reload
	}

	$routeProvider
		.when("/tests", {
			templateUrl: projectChecksTemplate,
			controller: "ProjectChecksCtrl",
			controllerAs: "ProjectChecksCtrl",
			reloadOnSearch: false,
			resolve: { areas: areasResolve, status: statusResolve },
		})
		.when("/area/:areaId/add-check", {
			templateUrl: addCheckTemplate,
			controller: "AddCheckController",
			controllerAs: "AddCheckController",
			resolve: { area: areaResolve },
		})
		.when("/area/:areaId/add-form-check", {
			templateUrl: addFormCheckTemplate,
			controller: "AddFormCheckController",
			controllerAs: "AddFormCheckController",
			resolve: { area: areaResolve },
		})
		.when("/area/:areaId/add-multiple-checks", {
			templateUrl: addMultipleChecksTemplate,
			controller: "AddMultipleChecksController",
			controllerAs: "AddMultipleChecksController",
			resolve: { area: areaResolve },
		})
		.when("/create-group", {
			templateUrl: createGroupTemplate,
			controller: "CreateGroupController",
			controllerAs: "CreateGroupController",
		})
		.when("/test/:testId", {
			templateUrl: testTemplate,
			controller: "TestController",
			controllerAs: "TestController",
			resolve: { test: testResolve },
		})
		.when("/settings", {
			templateUrl: projectSettingsTemplate,
			controller: "ProjectSettingsController",
			controllerAs: "ProjectSettingsController",
		})
		.when("/users", {
			templateUrl: manageUsersTemplate,
			controller: "ManageUsersController",
			controllerAs: "ManageUsersController",
		})
		.when("/reports", {
			templateUrl: projectReportsTemplate,
			controller: "ProjectReportsController",
			controllerAs: "ProjectReportsController",
			resolve: {
				google: "Google",
			},
		});
}

config.$inject = [
	"$httpProvider",
	"$routeProvider",
	"$locationProvider",
	"$sceDelegateProvider",
];
