import angular from "angular";

let flashes = [];

angular
	.module("flash", [])

	.component("flashMessages", {
		template: `<section class="fixed top-4 right-4 z-[9999] w-96" ng-if="flashes">
	<div
		role="alert"
		ng-repeat="flash in flashes"
		class="flex items-center gap-3 shadow-md rounded-md py-2.5 pl-4 pr-2 mb-3 bg-white dark:bg-gray-800 dark:border dark:border-gray-700 {{flash.myClass}}">
		<ul ng-switch="flash.type">
			<li class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-lime-500 bg-lime-200 rounded-full dark:bg-lime-800 dark:text-lime-200" ng-switch-when="success">
				<svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
				</svg>
			</li>
			<li class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-full dark:bg-red-800 dark:text-red-200" ng-switch-when="error">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="w-5 h-5" viewBox="0 0 16 16">
          <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0L7.005 3.1ZM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"/>
				</svg>
			</li>
			<li class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-yellow-500 bg-yellow-100 rounded-full dark:bg-yellow-800 dark:text-yellow-200" ng-switch-when="warning">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="w-5 h-5" viewBox="0 0 16 16">
          <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0L7.005 3.1ZM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"/>
				</svg>
			</li>
			<li class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-full dark:bg-blue-800 dark:text-blue-200" ng-switch-when="info">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="w-5 h-5" viewBox="0 0 16 16">
          <path d="m9.708 6.075-3.024.379-.108.502.595.108c.387.093.464.232.38.619l-.975 4.577c-.255 1.183.14 1.74 1.067 1.74.72 0 1.554-.332 1.933-.789l.116-.549c-.263.232-.65.325-.905.325-.363 0-.494-.255-.402-.704l1.323-6.208Zm.091-2.755a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0Z"/>
				</svg>
			</li>
		</ul>

		<span class="grow">{{flash.message}}</span>
		
		<button
			type="button"
			ng-click="flash.close(flash)"
			class="text-gray-800 dark:textx-gray-500 bg-transparent dark:bg-transparent hover:bg-gray-200 dark:hover:bg-gray-900 text-opacity-50 dark:text-opacity-50 hover:text-opacity-100 dark:hover:text-opacity-100 border-0 text-xl font-semibold leading-none right-0 top-0 py-1 px-2 outline-none focus:outline-none">
			<span>×</span>
		</button>
	</div>
</section>
		`,

		controller: class FlashMessagesController {
			constructor($scope) {
				$scope.flashes = flashes;
			}

			static get $inject() {
				return ["$scope"];
			}
		},
	})

	.factory("Flash", [
		"$timeout",
		($timeout) => ({
			error(message, myClass, timeout) {
				this.add("error", message, myClass, timeout);
				return this;
			},

			info(message, myClass, timeout) {
				this.add("info", message, myClass, timeout);
				return this;
			},

			warning(message, myClass, timeout) {
				this.add("warning", message, myClass, timeout);
				return this;
			},

			success(message, myClass, timeout) {
				this.add("success", message, myClass, timeout);
				return this;
			},

			add(type, message, myClass, timeout) {
				const flash = {
					type,
					message,
					myClass,
					close(flash) {
						const index = flashes.indexOf(flash);
						if (index > -1) {
							flashes.splice(index, 1);
						}
					},
				};

				flash.timer = $timeout(() => {
					flash.close(flash);
				}, timeout || 4000);

				flashes.unshift(flash);
				return this;
			},

			limit(limit) {
				flashes.splice(limit);
				return this;
			},

			reset() {
				flashes = [];
				return this;
			},
		}),
	]);
