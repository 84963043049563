import autosize from "autosize/dist/autosize.js";

/**
 * @see https://github.com/jackmoore/autosize
 * @returns {{link: link, restrict: string}}
 */
export function autoexpand() {
	return {
		restrict: "A",
		link($scope, $element, $attributes) {
			const textarea = $element[0];
			autosize(textarea);
			$scope.$watch($attributes.ngModel, () => autosize.update(textarea));
		},
	};
}
