<template>
	<input
		v-model="url"
		autocomplete="url"
		type="url"
		v-bind="$attrs"
		@input="onInput"
		@keyup="onKeyup"
	>
</template>

<script lang="ts" setup>
import { useDebounceFn } from "@vueuse/core";
import { addProtocol } from "../functions/add-protocol.js";

const url = defineModel({ required: true, type: String, default: "" });

const onKeyup = useDebounceFn((event: KeyboardEvent) => {
	const input = event.target as HTMLInputElement;

	// remove HTTPS and www from the beginning
	const domain = input.value.replace(/^(https?:\/\/)?(www\.)?/i, "");

	// IP address or domain name with TLD
	const urlOrIp =
		/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$|^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}$/;
	input.setCustomValidity(
		!urlOrIp.test(domain) ? "Please enter valid URL or IP address" : "",
	);
}, 150);

const onInput = useDebounceFn((event: Event) => {
	const input = event.target as HTMLInputElement;
	url.value = input.value = addProtocol(input.value);
}, 150);
</script>