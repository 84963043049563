import { createPopper } from "@popperjs/core";

export class ResponseTimeTooltip {
	constructor(chartBody) {
		this.tooltip = chartBody
			.append("div")
			.attr("class", "absolute invisible opacity-0 pointer-events-none z-50");

		this.popper = createPopper(document.documentElement, this.tooltip.node(), {
			placement: "bottom-start",
			modifiers: [{ name: "offset", options: { offset: [10, 10] } }],
		});
	}

	/**
	 * Nastaví element u kterého tooltip zobrazit
	 * @param {Element} element
	 * @return {ResponseTimeTooltip}
	 */
	setElement(element) {
		this.popper.state.elements.reference = element;
		this.popper.update();
		return this;
	}

	/**
	 * Aktualizuje obsah tooltipu
	 * @param {ResponseTimeRecord} record
	 * @return {ResponseTimeTooltip}
	 */
	updateContent(record) {
		const preccision = 3;

		this.tooltip.html(`
			<div class="panel drop-shadow">
				<div class="panel-header font-semibold">
					Response time at ${record.responseDate.toLocaleString()} 
				</div>
				<div class="panel-body bg-white rounded-b dark:bg-gray-900">
					<table class="text-right w-full">
						<tr class="border-b">
							<th class="p-1">DNS Lookup:</th>
							<td class="p-1">${Number(record.dnsLookupTime.toFixed(preccision))}&nbsp;ms</td>
						</tr>
						<tr>
							<th class="p-1">Initial connection:</th>
							<td class="p-1">${Number(record.initialConnectionTime.toFixed(preccision))}&nbsp;ms</td>
						</tr>									
						<tr>
							<th class="p-1">Waiting time:</th>
							<td class="p-1">${Number(record.waitingTime.toFixed(preccision))}&nbsp;ms</td>
						</tr>
						<tr>
							<th class="p-1">Request time:</th>
							<td class="p-1">${Number(record.requestTime.toFixed(preccision))}&nbsp;ms</td>
						</tr>
						<tr>
							<th class="p-1">SSL time:</th>
							<td class="p-1">${Number(record.sslTime.toFixed(preccision))}&nbsp;ms</td>
						</tr>
						<tr>
							<th class="p-1">Content download:</th>
							<td class="p-1">${Number(record.contentDownloadTime.toFixed(preccision))}&nbsp;ms</td>
						</tr>
						<tr class="border-t-2">
							<th class="p-1">Resonse time:</th>
							<td class="p-1">${Number(record.totalResponseTime.toFixed(preccision))}&nbsp;ms</td>	
						</tr>
					</table>	
				</div>
			</div>`);

		return this;
	}

	/**
	 * Zobrazí tooltip
	 * @return {ResponseTimeTooltip}
	 */
	show() {
		this.tooltip
			.transition()
			.duration(100)
			.style("opacity", 1)
			.style("visibility", "visible");
		return this;
	}

	/**
	 * Skryje tooltip
	 * @return {ResponseTimeTooltip}
	 */
	hide() {
		this.tooltip
			.transition()
			.duration(200)
			.style("opacity", 0)
			.style("visibility", "hidden");
		return this;
	}
}
