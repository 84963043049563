import { getCurrentProjectId } from "../functions/get-current-project-id.js";

const statusResolve = ($q, Project) => {
	const deferred = $q.defer();
	Project.status(
		{ projectId: getCurrentProjectId() },
		(status) => {
			deferred.resolve(status);
		},
		(error) => {
			deferred.reject(error);
		},
	);

	return deferred.promise;
};

statusResolve.$inject = ["$q", "Project"];

export { statusResolve };
