export default class Timer {
	startedAt: number;
	callback: TimerHandler;
	delay: number;
	timer: number;
	remaining: number;

	constructor(callback: TimerHandler, delay: number) {
		this.startedAt = Date.now();
		this.callback = callback;
		this.delay = delay;
		this.remaining = delay;
		this.timer = setTimeout(callback, delay);
	}

	get progress(): number {
		const elapsed = Date.now() - this.startedAt;
		return Math.min(elapsed / this.delay, 1);
	}

	pause() {
		this.stop();
		this.remaining -= Date.now() - this.startedAt;
	}

	resume() {
		this.stop();
		this.startedAt = Date.now();
		this.timer = setTimeout(this.callback, this.remaining);
	}

	stop() {
		clearTimeout(this.timer);
	}
}
