import { ndjson } from "../../functions/ndjson.js";
import createProjectTemplate from "./create-project-controller.html";

const createValidUrl = (url) => {
	try {
		return new URL(/:\/\//.test(url) ? url : `https://${url}`).toString();
	} catch {
		// Do nothing with errors
	}
};

export default class CreateProjectController {
	constructor($scope, subscription, Project, Flash) {
		this.$scope = $scope;
		this.$scope.template = createProjectTemplate;
		this.$scope.urls = [""];
		this.$scope.emptyProject = 0;
		this.$scope.projects = [];
		// Download subscription plans
		subscription.then((subscription) => {
			this.$scope.addProjectAllowed = subscription.isAllowed.addProject;
			this.$scope.subscription = subscription;
		});

		this.subscription = subscription;
		this.Project = Project;
		this.Flash = Flash;
	}

	static get $inject() {
		return ["$scope", "subscription", "Project", "Flash"];
	}

	/**
	 * @param addPresetChecks (0 = create empty projects, 1 = create preset projects)
	 * @returns {Promise<void>}
	 */
	async createProjects(addPresetChecks) {
		// 1. process urls (unique, valid, non empty urls :)

		const urls = [
			...new Set(
				this.$scope.urls.filter(Boolean).map((url) => createValidUrl(url)),
			),
		];

		// 2. prepare empty Projects

		this.$scope.projects = urls.map((url) => ({ url, progress: 0 }));

		// 3. fetch JSON chunks from API...

		try {
			const response = await fetch("/api/project/create", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ urls, addPresetChecks }),
			});

			for await (const project of ndjson(response)) {
				const index = this.$scope.projects.findIndex(
					(pr) => pr.url === project.originalUrl,
				);
				this.$scope.$apply(() => {
					this.$scope.projects[index] = project;
				});
			}
		} catch (error) {
			this.Flash.error(`ERROR:${error.message}`);
			console.error(error);
		}
	}
}
