import angular from "angular";
import { notificationsSettings } from "../user/notifications-settings.js";
import { billingAddress } from "./billing-address.component.js";
import { braintreeWebDropIn } from "./braintree-web-dropin.component.js";
import { cancelSubscription } from "./cancel-subscription.js";
import { chartResponseTime } from "./chart-response-time.js";
import { chartUptime } from "./chart-uptime.js";
import { combobox } from "./combobox.component.js";
import { favicon } from "./favicon.component.js";
import { formEditor } from "./form-editor.component.js";
import { formPreview } from "./form-preview.component.js";
import { help } from "./help.component.js";
import { modal } from "./modal.component.js";
import { multiple } from "./multiple.component.js";
import { paymentPlanSelect } from "./payment-plan-select.component.js";
import { groupRules } from "./rules/group-rules.component.js";
import { rulesSummary } from "./rules/rules-summary.component.js";
import { rules } from "./rules/rules.component.js";
import { ruletype } from "./rules/ruletype.component.js";
import { templateRules } from "./rules/template-rules.component.js";
import { spinner } from "./spinner.component.js";
import { status } from "./status.component.js";

export default angular
	.module("testomato.components", [])
	.component("paymentPlanSelect", paymentPlanSelect)
	.component("braintreeWebDropIn", braintreeWebDropIn)
	.component("billingAddress", billingAddress)
	.component("combobox", combobox)
	.component("favicon", favicon)
	.component("spinner", spinner)
	.component("multiple", multiple)
	.component("status", status)
	.component("ruletype", ruletype)
	.component("formEditor", formEditor)
	.component("formPreview", formPreview)
	.component("cancelSubscription", cancelSubscription)
	.component("help", help)
	.component("rules", rules)
	.component("modal", modal)
	.component("templateRules", templateRules)
	.component("groupRules", groupRules)
	.component("rulesSummary", rulesSummary)
	.component("chartResponseTime", chartResponseTime)
	.component("chartUptime", chartUptime)
	.component("notificationsSettings", notificationsSettings);
