export function Role($resource) {
	return $resource(
		"/api/role/:roleId",
		{},
		{
			add: { method: "POST", params: {} },
			update: { method: "PUT", params: {} },
			remove: { method: "DELETE", params: {} },
		},
	);
}

Role.$inject = ["$resource"];
