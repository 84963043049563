<template>

	<Breadcrumb>
		<BreadcrumbItem href="/dashboard">Home</BreadcrumbItem>
		<BreadcrumbItem href="/app/project/create">Add website</BreadcrumbItem>
	</Breadcrumb>

	<section class="p-4 mx-auto max-w-screen-xl">
		<AddNewProjectNotAllowed v-if="subscription.isAllowed.addProject === false"/>
		<AddWebsiteForm v-if="subscription.isAllowed.addProject === true"/>
	</section>
</template>

<script lang="ts" setup>
import BreadcrumbItem from "../../components/breadcrumb/breadcrumb-item.vue";
import Breadcrumb from "../../components/breadcrumb/breadcrumb.vue";
import AddNewProjectNotAllowed from "../../components/project-create/add-new-project-not-allowed.vue";
import AddWebsiteForm from "../../components/project-create/add-website-form.vue";

import { onBeforeMount, ref } from "vue";
import { api } from "../../api/api";

const subscription = ref({ isAllowed: { addProject: undefined } });

onBeforeMount(async () => {
	subscription.value = await api.get("user/me/subscription").json();
});
</script>