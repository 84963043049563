import type Timer from "./timer.ts";

type NotificationType = "info" | "success" | "warning" | "error" | "default";

export type Notification = {
	id?: string;
	title?: string;
	message?: string;
	type?: NotificationType;
	autoClose?: boolean | number;
	icon?: boolean;
	timer?: Timer | undefined;
};

export const defaultOptions: Notification = {
	message: "",
	type: "default",
	icon: true,
	autoClose: 4000,
};
