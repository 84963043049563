import Swal from "sweetalert2";
import "./confirm.scss";

/**
 * Náhrada za window.confirm() dialog pro potvrzení nebezpečných akcí
 *
 * @see https://sweetalert2.github.io/
 * @param text
 * @param options
 * @returns {Promise<boolean>}
 */
export async function confirmDialog(...arguments_) {
	const dialog = Swal.mixin({
		title: "Are you sure?",
		padding: "2em",
		width: 480,
		showCancelButton: true,
		showCloseButton: true,
		reverseButtons: true,
		icon: "error",
		customClass: {
			popup: "dark:bg-gray-700 dark:text-white",
			confirmButton: "btn btn-danger mx-2",
			cancelButton: "btn btn-secondary mx-2",
		},
		buttonsStyling: false,
	});

	const results = await dialog.fire(...arguments_);
	return results.isConfirmed;
}
