export const modal = {
	transclude: {
		title: "?modalTitle",
		body: "?modalBody",
		action: "?modalAction",
	},

	bindings: {
		modalShow: "=?",
		modalTitle: "@?",
		wrapperClass: "@?",
		modalClass: "@?",
	},

	template: `
		<div 
			role="dialog" 
			class="hidden fixed inset-0 z-40 bg-black bg-opacity-20 dark:bg-opacity-40"
			ng-class="{hidden: !$ctrl.modalShow}" 
			aria-hidden="{{!$ctrl.modalShow}}">
			<div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none {{$ctrl.wrapperClass || 'flex justify-center items-center'}}">
				<div class="relative w-auto mx-auto max-w-full {{$ctrl.modalClass}}">
					<div class="border-0 rounded-lg drop-shadow relative flex flex-col w-full bg-white dark:bg-gray-800 outline-none focus:outline-none divide-y divide-gray-200 dark:divide-gray-700">
						<div class="p-3 flex gap-2 items-center justify-between">
							<h4 class="font-semibold" ng-transclude="title"></h4>
							<button 
								type="button"
							  ng-click="$ctrl.close()" 
							  class="border-0 hover:dark:bg-gray-900 py-1 px-2">&times;</button>
						</div>
						<div class="p-3 overflow-y-auto" ng-transclude="body"></div>
						<div class="p-3 flex gap-2 items-center justify-between">						
							<div ng-transclude="action"></div>
							<button type="button" ng-click="$ctrl.close()">
								<i class="material-icons">close</i> Close
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>`,

	controller: class ModalController {
		constructor($scope) {
			this.$scope = $scope;

			// background scroll disabled
			$scope.$watch("$ctrl.modalShow", () => {
				document.body.style.overflowY = this.modalShow ? "hidden" : "auto";
				document.body.style.height = this.modalShow ? "100vh" : "auto";
			});
		}

		static get $inject() {
			return ["$scope"];
		}

		$onInit() {
			document.addEventListener("keydown", (event) => {
				if (event.key === "Escape") {
					this.$scope.$apply(() => {
						this.modalShow = false;
					});
				}
			});
		}

		close() {
			this.modalShow = false;
		}
	},
};
