<template>
	<transition name="toast">
		<TransitionGroup class="fixed top-4 right-4 w-full max-w-md flex flex-col-reverse gap-2 z-50" name="toast" tag="ul">
			<li
				v-for="toast in notifications" :key="toast.id" :class="toast.type"
				class="flex items-center gap-x-3 w-full p-3 rounded-lg shadow border dark:border-gray-900"
				@mouseleave="toast.timer?.resume()"
				@mouseover="toast.timer?.pause()">

				<div v-if="toast.icon === true">
					<svg v-if="toast.type === 'info'" class="size-6" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<path d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<svg v-if="toast.type === 'success'" class="size-6" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<path d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<svg v-if="toast.type === 'error' || toast.type === 'warning'" class="size-6" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<path d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" stroke-linecap="round"
						      stroke-linejoin="round"/>
					</svg>
				</div>

				<article class="prose prose-sky dark:prose-invert text-inherit prose-strong:text-inherit">
					<h5 v-if="toast.title" class="font-bold">{{ toast.title }}</h5>
					<div v-html="toast.message"></div>
				</article>

				<button aria-label="Close" class="self-start" data-collapse-toggle="useToast-default" type="button" @click.prevent="dismiss(toast)">
					<span class="sr-only">Close</span>
					<svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
						<path clip-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
						      fill-rule="evenodd"></path>
					</svg>
				</button>
			</li>
		</TransitionGroup>
	</transition>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import type { Notification } from "./notification";
import Timer from "./timer";

const notifications = ref<Notification[]>([]);

function show(notification: Notification) {
	if (!notification.id) {
		notification.id = Math.random().toString(36).slice(2, 9);
	}

	if (notification.autoClose) {
		const limit =
			(notification.autoClose as number) + notification.message.length * 30;
		notification.timer = new Timer(() => dismiss(notification), limit);
	}

	notifications.value = [...notifications.value, notification];
}

function dismiss(notification: Notification) {
	notification.timer?.stop();
	notifications.value = notifications.value.filter(
		(n) => n.id !== notification.id,
	);
}

function clean() {
	notifications.value = [];
}

defineExpose({ show, clean });
</script>

<style scoped src="./toasts.css"/>
