import { DateTime } from "luxon";

/**
 * Převede řetězec na objekt Interval
 * @param {string} name
 * @typedef {{name:string, start:DateTime, end:DateTime}} DateInteval
 * @return DateInteval
 */
export function getReportIntrvalByName(name) {
	switch (name) {
		case "Today": {
			return {
				name: "Today",
				start: DateTime.local().startOf("day"),
				end: DateTime.local().endOf("day"),
			};
		}

		case "Last day": // Falls through
		case "Yesterday": {
			return {
				name: "Yesterday",
				start: DateTime.local().minus({ day: 1 }).startOf("day"),
				end: DateTime.local().minus({ day: 1 }).endOf("day"),
			};
		}

		case "This week": {
			return {
				name,
				start: DateTime.local().startOf("week").startOf("day"),
				end: DateTime.local().endOf("week").endOf("day"),
			};
		}

		case "Last week": {
			return {
				name: "Last week",
				start: DateTime.local()
					.minus({ week: 1 })
					.startOf("week")
					.startOf("day"),
				end: DateTime.local().minus({ week: 1 }).endOf("week").endOf("day"),
			};
		}

		case "This month": {
			return {
				name: "This month",
				start: DateTime.local().startOf("month").startOf("day"),
				end: DateTime.local().endOf("month").endOf("day"),
			};
		}

		case "Last month": {
			return {
				name: "Last month",
				start: DateTime.local()
					.minus({ month: 1 })
					.startOf("month")
					.startOf("day"),
				end: DateTime.local().minus({ month: 1 }).endOf("month").endOf("day"),
			};
		}

		case "Last 7 days": {
			return {
				name: "Last 7 days",
				start: DateTime.local().minus({ days: 7 }).startOf("day"),
				end: DateTime.local().endOf("day"),
			};
		}

		case "Last 15 days": {
			return {
				name: "Last 15 days",
				start: DateTime.local().minus({ days: 15 }).startOf("day"),
				end: DateTime.local().endOf("day"),
			};
		}

		default: {
			// Last 30 days is default
			return {
				name: "Last 30 days",
				start: DateTime.local().minus({ days: 30 }).startOf("day"),
				end: DateTime.local().endOf("day"),
			};
		}
	}
}
