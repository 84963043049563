export const rulesSummary = {
	bindings: {
		rules: "<?",
	},

	template: `
	<ol>
		<li ng-repeat="rule in $ctrl.rules" class="mb-1 last:mb-0">
			<p class="mb-1">
				<span ng-switch="rule.type">
					<span ng-switch-when="text">Text on Page</span>
					<span ng-switch-when="html">HTML on Page</span>
					<span ng-switch-when="status">HTTP Status</span>
					<span ng-switch-when="header">HTTP Header</span>
					<span ng-switch-when="location">Redirect Location</span>
				</span>
				<span ng-switch="rule.op">
					<span ng-switch-when="eq">is equal to</span>
					<span ng-switch-when="sub">contains</span>
					<span ng-switch-when="!sub">does not contain</span>
					<span ng-switch-when="eqs">equals</span>
					<span ng-switch-when="match">matches</span>
					<span ng-switch-when="!match">does not match</span>
					<span ng-switch-when="xpath">matches XPath</span>
				</span>							
			</p>
			<pre class="border border-gray-300 dark:border-gray-700 text-sm py-1 px-2 rounded"><code>{{rule.val}}</code></pre>
		</li>
	</ol>
`,
};
