/**
 * Resize the textarea automatically
 * @param textarea
 */
export function autosize(textarea) {
	textarea.style.boxSizing = "border-box";
	textarea.style.resize = "none";
	const offset = textarea.offsetHeight - textarea.clientHeight;
	textarea.style.height = "auto";
	textarea.style.height = `${textarea.scrollHeight + offset}px`;
}
