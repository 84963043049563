function subscription($q, User) {
	const deferred = $q.defer();

	User.subscription(
		(subscription) => {
			deferred.resolve(subscription);
		},
		(error) => {
			deferred.reject(error);
		},
	);

	return deferred.promise;
}

subscription.$inject = ["$q", "User"];

export { subscription };
