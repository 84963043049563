const areaResolve = ($q, $route, Area) => {
	const d = $q.defer();
	Area.get({ areaId: $route.current.params.areaId }, (area) => {
		d.resolve(area);
	});
	return d.promise;
};

areaResolve.$inject = ["$q", "$route", "Area"];

export { areaResolve };
