/**
 * Vraci data prave prihlaseneho uzivatele
 * @param $q
 * @param Auth
 * @returns {deferred.promise|{then}}
 */
function auth($q, Auth) {
	const deferred = $q.defer();

	Auth.get(
		{},
		(auth) => {
			deferred.resolve(auth);
		},
		(error) => {
			deferred.reject(error);
		},
	);

	return deferred.promise;
}

auth.$inject = ["$q", "Auth"];

export { auth };
