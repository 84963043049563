import { DateTime, Interval } from "luxon";

/**
 * Time duration for people
 * @param start
 * @param end
 * @returns {string}
 */
export function duration(start, end) {
	const { days, hours, minutes, seconds } = Interval.fromDateTimes(
		DateTime.fromSeconds(start),
		end ? DateTime.fromSeconds(end) : DateTime.now(),
	)
		.toDuration(["days", "hours", "minutes", "seconds"])
		.toObject();

	if (days) {
		return `${days}d ${hours}h ${minutes}min`;
	}

	if (hours) {
		return `${hours}h ${minutes}min`;
	}

	if (minutes) {
		return `${minutes}min`;
	}

	return `${Math.floor(seconds) || 0}sec`;
}
