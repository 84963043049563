/**
 * Created by Alex on 11/6/2014.
 */
export default function keycharm(options) {
  var preventDefault = options && options.preventDefault || false;

  var container = options && options.container || window;

  var _exportFunctions = {};
  var _bound = {keydown:{}, keyup:{}};
  var _keys = {};
  var i;

  // a - z
  for (i = 97; i <= 122; i++) {_keys[String.fromCharCode(i)] = {code:65 + (i - 97), shift: false};}
  // A - Z
  for (i = 65; i <= 90; i++) {_keys[String.fromCharCode(i)] = {code:i, shift: true};}
  // 0 - 9
  for (i = 0;  i <= 9;   i++) {_keys['' + i] = {code:48 + i, shift: false};}
  // F1 - F12
  for (i = 1;  i <= 12;   i++) {_keys['F' + i] = {code:111 + i, shift: false};}
  // num0 - num9
  for (i = 0;  i <= 9;   i++) {_keys['num' + i] = {code:96 + i, shift: false};}

  // numpad misc
  _keys['num*'] = {code:106, shift: false};
  _keys['num+'] = {code:107, shift: false};
  _keys['num-'] = {code:109, shift: false};
  _keys['num/'] = {code:111, shift: false};
  _keys['num.'] = {code:110, shift: false};
  // arrows
  _keys['left']  = {code:37, shift: false};
  _keys['up']    = {code:38, shift: false};
  _keys['right'] = {code:39, shift: false};
  _keys['down']  = {code:40, shift: false};
  // extra keys
  _keys['space'] = {code:32, shift: false};
  _keys['enter'] = {code:13, shift: false};
  _keys['shift'] = {code:16, shift: undefined};
  _keys['esc']   = {code:27, shift: false};
  _keys['backspace'] = {code:8, shift: false};
  _keys['tab']       = {code:9, shift: false};
  _keys['ctrl']      = {code:17, shift: false};
  _keys['alt']       = {code:18, shift: false};
  _keys['delete']    = {code:46, shift: false};
  _keys['pageup']    = {code:33, shift: false};
  _keys['pagedown']  = {code:34, shift: false};
  // symbols
  _keys['=']     = {code:187, shift: false};
  _keys['-']     = {code:189, shift: false};
  _keys[']']     = {code:221, shift: false};
  _keys['[']     = {code:219, shift: false};



  var down = function(event) {handleEvent(event,'keydown');};
  var up = function(event) {handleEvent(event,'keyup');};

  // handle the actualy bound key with the event
  var handleEvent = function(event,type) {
    if (_bound[type][event.keyCode] !== undefined) {
      var bound = _bound[type][event.keyCode];
      for (var i = 0; i < bound.length; i++) {
        if (bound[i].shift === undefined) {
          bound[i].fn(event);
        }
        else if (bound[i].shift == true && event.shiftKey == true) {
          bound[i].fn(event);
        }
        else if (bound[i].shift == false && event.shiftKey == false) {
          bound[i].fn(event);
        }
      }

      if (preventDefault == true) {
        event.preventDefault();
      }
    }
  };

  // bind a key to a callback
  _exportFunctions.bind = function(key, callback, type) {
    if (type === undefined) {
      type = 'keydown';
    }
    if (_keys[key] === undefined) {
      throw new Error("unsupported key: " + key);
    }
    if (_bound[type][_keys[key].code] === undefined) {
      _bound[type][_keys[key].code] = [];
    }
    _bound[type][_keys[key].code].push({fn:callback, shift:_keys[key].shift});
  };


  // bind all keys to a call back (demo purposes)
  _exportFunctions.bindAll = function(callback, type) {
    if (type === undefined) {
      type = 'keydown';
    }
    for (var key in _keys) {
      if (_keys.hasOwnProperty(key)) {
        _exportFunctions.bind(key,callback,type);
      }
    }
  };

  // get the key label from an event
  _exportFunctions.getKey = function(event) {
    for (var key in _keys) {
      if (_keys.hasOwnProperty(key)) {
        if (event.shiftKey == true && _keys[key].shift == true && event.keyCode == _keys[key].code) {
          return key;
        }
        else if (event.shiftKey == false && _keys[key].shift == false && event.keyCode == _keys[key].code) {
          return key;
        }
        else if (event.keyCode == _keys[key].code && key == 'shift') {
          return key;
        }
      }
    }
    return "unknown key, currently not supported";
  };

  // unbind either a specific callback from a key or all of them (by leaving callback undefined)
  _exportFunctions.unbind = function(key, callback, type) {
    if (type === undefined) {
      type = 'keydown';
    }
    if (_keys[key] === undefined) {
      throw new Error("unsupported key: " + key);
    }
    if (callback !== undefined) {
      var newBindings = [];
      var bound = _bound[type][_keys[key].code];
      if (bound !== undefined) {
        for (var i = 0; i < bound.length; i++) {
          if (!(bound[i].fn == callback && bound[i].shift == _keys[key].shift)) {
            newBindings.push(_bound[type][_keys[key].code][i]);
          }
        }
      }
      _bound[type][_keys[key].code] = newBindings;
    }
    else {
      _bound[type][_keys[key].code] = [];
    }
  };

  // reset all bound variables.
  _exportFunctions.reset = function() {
    _bound = {keydown:{}, keyup:{}};
  };

  // unbind all listeners and reset all variables.
  _exportFunctions.destroy = function() {
    _bound = {keydown:{}, keyup:{}};
    container.removeEventListener('keydown', down, true);
    container.removeEventListener('keyup', up, true);
  };

  // create listeners.
  container.addEventListener('keydown',down,true);
  container.addEventListener('keyup',up,true);

  // return the public functions.
  return _exportFunctions;
}
