export function Authentication($resource) {
	return $resource(
		"/api/authentication/:action",
		{},
		{
			search: { method: "POST", params: { action: "search" } }, // vyhledá způsoby přihlášení
			verify: {
				method: "POST",
				params: { action: "verify" },
				responseType: "text",
				interceptor: {
					response: (response) =>
						`data:text/html;charset=utf-8,${encodeURIComponent(response.data || "")}`,
				},
			}, // ověří přihlášení
		},
	);
}

Authentication.$inject = ["$resource"];
