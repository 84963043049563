import { confirmDialog } from "../../functions/confirm.js";

export default class ProjectSettingsController {
	constructor($scope, $location, $filter, $q, Project, User, Flash) {
		this.$scope = $scope;
		this.$filter = $filter;
		this.$q = $q;
		this.Project = Project;
		this.User = User;
		this.Flash = Flash;

		// options
		Project.options({ projectId: $scope.project.id }, (options) => {
			$scope.validPeriods = options.testing;
			$scope.uptimeOptions = options.uptime;
			$scope.locations = options.location;
			$scope.listOfUserAgents = options.listOfUserAgents;
			$scope.userAgent =
				$scope.project.userAgent || $scope.listOfUserAgents[0].value;
			$scope.sslAlertPeriods = options.sslAlertPeriods;
		});

		$scope.notifications = Project.notifications({
			projectId: $scope.project.id,
		});
		$scope.integrations = User.integrations();
		$scope.subscription = User.subscription({ projectId: $scope.project.id });
		$scope.selectedUserAgent = $scope.project.userAgent;
		$scope.projectApiUrl = `${$location.protocol()}://${$location.host()}/api/project/${$scope.project.id}`;
	}

	static get $inject() {
		return ["$scope", "$location", "$filter", "$q", "Project", "User", "Flash"];
	}

	saveProject(
		projectId,
		update,
		message = "Project settings was successfully saved.",
	) {
		this.Project.update({ projectId }, update, () => {
			this.Flash.success(message).limit(3);
		});
	}

	saveNotifications(projectId, notifications) {
		this.Project.setNotifications(
			{ projectId },
			notifications,
			(notifications) => {
				this.$scope.notifications = notifications;
				this.Flash.success("Notifications settings was successfully saved.");
			},
		);
	}

	async deleteProject(projectId) {
		const isConfirmed = await confirmDialog({
			title: "Delete project?",
			text: "Are you sure you want to delete this project? (This action cannot be undone!)",
			confirmButtonText: "Yes, delete project",
		});

		if (isConfirmed) {
			this.Project.remove({ projectId }, () => {
				this.Flash.success("Project was successfully deleted");
				window.setTimeout(() => {
					window.location.pathname = "/";
				}, 500);
			});
		}
	}
}
