export * from "./project";
import ky from "ky";

export const api = ky.create({
	prefixUrl: "/api",
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
	hooks: {
		beforeError: [
			async (error) => {
				const json = await error.response.json();

				if (json.message) {
					error.message = json.message;
				}

				return error;
			},
		],
	},
});
