export const favicon = {
	bindings: {
		url: "<?",
		width: "@?",
		height: "@?",
	},

	template: `
			<img
				src="https://www.google.com/s2/favicons?domain={{ctl.url}}"
				width="22px"
				height="22px"
				class="bg-gray-200 p-1 rounded w-[22px] h-[22px]"
			/>
	`,

	controller: class FaviconController {
		constructor($scope) {
			this.$scope = $scope;
		}

		static get $inject() {
			return ["$scope"];
		}
	},
	controllerAs: "ctl",
};
