import { ndjson } from "../../functions/ndjson.js";

/**
 * @name AddMultipleChecksController
 * @type AddMultipleChecksController
 */
export default class AddMultipleChecksController {
	constructor($scope, $timeout, $location, area, Test, Flash) {
		this.$scope = $scope;
		this.area = area;
		this.$scope.area = area;
		this.$timeout = $timeout;
		this.$location = $location;
		this.Test = Test;
		this.Flash = Flash;

		$scope.input = { urls: $location.search().url || "", rows: 2 };
		$scope.tests = [];
		$scope.urls = [""];
		$scope.step = 1;
	}

	static get $inject() {
		return ["$scope", "$timeout", "$location", "area", "Test", "Flash"];
	}

	onInput() {
		this.$scope.input.rows =
			((this.$scope.input.urls || "").match(/\n/g) || []).length + 2;
		if (this.$scope.input.rows > 100) {
			this.$scope.input.rows = 100;
			this.Flash.error("Sorry! You can only add 100 pages at a time.").limit(3);
		}
	}

	async create() {
		this.$scope.step = 2;

		const urls = this.$scope.urls.filter(Boolean);
		this.$scope.tests = urls.map((url) => ({ url, loading: true }));

		const response = await fetch("/api/test/add-multiple-checks", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				areaId: this.area.id,
				projectId: this.$scope.project.id,
				urls,
			}),
		});

		let index = 0;
		for await (const data of ndjson(response)) {
			this.$scope.$apply(() => {
				this.$scope.tests.splice(index, 1, data);
				index++;
			});
		}
	}

	removeTest($index) {
		if (this.$scope.tests.length > 1) {
			this.$scope.tests.splice($index, 1);
		}
	}

	save() {
		this.Test.batchSave(
			{
				areaId: this.area.id,
				projectId: this.$scope.project.id,
				tests: this.$scope.tests,
			},
			(response) => {
				if (response.ok) {
					this.$timeout(() => {
						this.$location.path("/tests");
					});
					this.Flash.add(
						response.error ? "error" : "success",
						response.message,
					).limit(3);
				}
			},
		);
	}
}
