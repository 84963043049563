import notificationsSettingTemplate from "./notifications-settings.html";

export const notificationsSettings = {
	bindings: {
		items: "=?",
		inputClass: "@?",
		inputPlaceholder: "@?",
		inputMaxCount: "@?",
	},

	templateUrl: notificationsSettingTemplate,
	controller: class NotificationsSettingsController {
		constructor($scope, User, Flash, Project) {
			this.$scope = $scope;
			this.User = User;
			this.Flash = Flash;
			this.Project = Project;

			this.$scope.me = User.me();
			this.$scope.integrations = User.integrations();
			this.$scope.notifications = User.notifications();
		}

		saveUserProfile(data) {
			this.User.update(data, (response) => {
				if (response.message) {
					this.Flash.add(
						response.error ? "error" : "success",
						response.message,
					);
				}
			});
		}

		saveNotifications(notifications) {
			this.Project.setNotifications(
				{ projectId: notifications._id },
				notifications,
			);
			this.Flash.success("Project notifications preference saved!");
		}

		testIntegration(name) {
			this.User.testIntegration({ name }, this.$scope.integrations, () => {
				this.Flash.success("Done!");
			});
		}

		static get $inject() {
			return ["$scope", "User", "Flash", "Project"];
		}
	},
};
