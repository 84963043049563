import { httpStatusCodes } from "../functions/http.js";

function toInt(string) {
	return Number.parseInt(string, 10);
}

export const status = {
	bindings: {
		code: "=",
	},

	template: `		
		<a
			href="{{$ctrl.getStatusHelp()}}" 
			target="_blank"
			class="label px-1 m-0 text-xs rounded {{$ctrl.getLabelClass()}}"	 
		>{{$ctrl.getLabelText()}}</a>
	`,

	controller: class HttpStatusLabelController {
		constructor($scope) {
			this.$scope = $scope;
		}

		static get $inject() {
			return ["$scope"];
		}

		getLabelText() {
			return this.code in httpStatusCodes
				? `${this.code} ${httpStatusCodes[this.code]}`
				: `HTTP Status ${this.code}`;
		}

		getStatusHelp() {
			return `https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/${this.code ?? ""}`;
		}

		getLabelClass() {
			// 200...300
			if (toInt(this.code) >= 200 && toInt(this.code) < 300) {
				return "label-success";
			} // OK

			// 300...400
			if (toInt(this.code) >= 300 && toInt(this.code) < 400) {
				return "label-primary";
			} // Redirects

			// 400...500
			if (toInt(this.code) >= 400 && toInt(this.code) < 500) {
				return "label-danger";
			} // Warning

			// 500...
			if (toInt(this.code) >= 500) {
				return "label-danger";
			} // Errors
		}
	},
};
