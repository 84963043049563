import * as d3 from "d3";
import { SimpleTooltip } from "./simple-tooltip.js";

/**
 * Vykreslí uptime chart pomocí D3 knihovny
 *
 * @param {Element} element
 * @param {array} data
 */
export function uptimeChart(element, { up, timeout, down }) {
	// Prepare element content
	element.innerHTML = "";

	// Prepare data
	const dataset = [
		{ value: up, class: "fill-lime-500 dark:fill-lime-700" },
		{ value: down, class: "fill-red-600 dark:fill-red-800" },
		{ value: timeout, class: "fill-amber-400 dark:fill-amber-400" },
	];

	// Add figure & SVG element
	const chartBody = d3.select(element).append("figure");
	const tooltip = new SimpleTooltip(chartBody);

	// const size = Math.max(figure.node().getBoundingClientRect().height, figure.node().getBoundingClientRect().width);
	const width = 250;
	const height = 250;

	const svg = chartBody
		.append("svg")
		.attr("class", "mx-auto")
		.attr("height", height)
		.attr("width", width)
		.attr("viewBox", [0, 0, width, height])
		.attr("xmlns", "http://www.w3.org/2000/svg");

	const outerRadius = width / 2;
	const innerRadius = outerRadius * 0.85;

	const chartContainer = svg
		.append("g")
		.attr("transform", `translate(${outerRadius},${outerRadius})`);

	const className = d3.scaleOrdinal(dataset.map((d) => d.class));

	// Calculate arcs

	const pie = d3
		.pie()
		.sort(null)
		.value((d) => d.value);

	const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

	// Sectors
	chartContainer
		.selectAll("path")
		.data(pie(dataset))
		.enter()
		.append("path")
		.attr("class", (d, i) => className(i))
		.attr("d", arc);

	// Uptime text
	const uptime = svg
		.append("svg:text")
		.attr("class", "font-bold fill-lime-500 dark:fill-lime-700 leading-none")
		.attr("text-anchor", "middle")
		.attr("dominant-baseline", "central")
		.attr("font-size", 30)
		.attr("x", "50%")
		.attr("y", "50%")
		.text(`${Number(up.toFixed(4))}%`)
		.on("mouseover", (event) =>
			tooltip.setText("Uptime percentage").setElement(event.toElement).show(),
		)
		.on("mouseleave", () => tooltip.hide());

	// Změna velikosti fontu Uptime percentage
	const maxWidth = width * 0.7;
	if (uptime.node().getComputedTextLength() < maxWidth) {
		uptime.attr(
			"font-size",
			maxWidth /
				(uptime.node().getComputedTextLength() / uptime.attr("font-size")),
		);
	}

	const uptimeBox = {
		top:
			uptime.node().getBoundingClientRect().top -
			svg.node().getBoundingClientRect().top,
		right:
			svg.node().getBoundingClientRect().right -
			uptime.node().getBoundingClientRect().right,
		bottom:
			uptime.node().getBoundingClientRect().bottom -
			svg.node().getBoundingClientRect().top,
		left:
			uptime.node().getBoundingClientRect().right -
			svg.node().getBoundingClientRect().left,
	};

	// Titulek "Uptime" nad uptime percentage

	svg
		.append("svg:text")
		.attr(
			"class",
			"font-semibold text-xl fill-gray-600 dark:fill-gray-100 leading-none pointer-events-none",
		)
		.attr("text-anchor", "middle")
		.attr("dominant-baseline", "central")
		.attr("x", "50%")
		.attr("y", outerRadius - innerRadius + uptimeBox.top / 2)
		.text("Uptime");

	// Downtime percentage
	if (down > 0) {
		svg
			.append("svg:text")
			.attr("class", "font-semibold text-lg fill-red-600 dark:fill-red-800")
			.attr("text-anchor", "start")
			.attr("dominant-baseline", "hanging")
			.attr("x", uptimeBox.right)
			.attr("y", uptimeBox.bottom)
			.text(`${Number(down.toFixed(4))}%`)
			.on("mouseover", (event) =>
				tooltip
					.setText("Downtime percentage")
					.setElement(event.toElement)
					.show(),
			)
			.on("mouseleave", () => tooltip.hide());
	}

	// Timeouts percentage
	if (timeout > 0) {
		svg
			.append("svg:text")
			.attr(
				"class",
				"font-semibold text-lg fill-amber-400 dark:fill-amber-400 leading-none",
			)
			.attr("text-anchor", "end")
			.attr("dominant-baseline", "hanging")
			.attr("font-size", "1.3em")
			.attr("x", uptimeBox.left)
			.attr("y", uptimeBox.bottom)
			.text(`${Number(timeout.toFixed(4))}%`)
			.on("mouseover", (event) =>
				tooltip
					.setText("Timeout percentage")
					.setElement(event.toElement)
					.show(),
			)
			.on("mouseleave", () => tooltip.hide());
	}
}
