/**
 * Schová doménu
 * @return {string}
 * @param s
 * @param url
 */
export function hideDomain(s, url) {
	const domain = url.replace(/^[^/]*\/\/([^/]*).*$/, "$1");
	const regexp = new RegExp(String(`\\.?${domain}`));
	return s.replace(regexp, "\u2026", s);
}
