export function User($resource) {
	return $resource(
		"/api/user/:userId/:action",
		{},
		{
			me: { method: "GET", params: { userId: "me" } },
			deleteSocialAccount: {
				method: "DELETE",
				params: { userId: "me", action: "socialAccount" },
			},
			update: { method: "PUT", params: { userId: "me" } },
			subscription: {
				method: "GET",
				params: { userId: "me", action: "subscription" },
			},
			collaborators: {
				method: "GET",
				params: { userId: "me", action: "collaborators" },
				isArray: true,
			},
			notifications: {
				method: "GET",
				params: { userId: "me", action: "notifications" },
				isArray: true,
			},
			integrations: {
				method: "GET",
				params: { userId: "me", action: "integrations" },
			},
			testIntegration: {
				method: "POST",
				params: { userId: "me", action: "testIntegration" },
			},
			regenerateApiKey: {
				method: "POST",
				params: { userId: "me", action: "regenerateApiKey" },
			},
		},
	);
}

User.$inject = ["$resource"];
