/**
 * Copy check object settings
 * @param check
 * @returns {any}
 * @constructor
 */
export default function CreateCheckCopy(check) {
	const copy = structuredClone(check);

	copy.id = null; // ID will be different
	copy.name = `Copy of ${check.name}`;
	copy.run = "disabled";
	copy.enabled = false;
	copy.status = null;

	return copy;
}
