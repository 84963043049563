export const templateRules = {
	bindings: {
		status: "<?",
		templateId: "=?",
		templateVars: "=?",
		hasTemplates: "=?",
		hasAnyFailing: "=?",
	},

	template: `
	<section ng-form="templateRulesComponentForm">		
		<div class="mb-3">
			<label for="select-template" class="form-label">Apply template <span class="text-gray-500 dark:text-gray-500">(optional)</span></label>
			<div class="flex gap-1">
				<select
					id="select-template"
					ng-model="$ctrl.templateId"
					ng-options="template.id as template.name for template in templates"
					ng-change="$ctrl.onChangeTemplate($ctrl.templateId)"
					>
				</select>
				<a
					class="btn btn-secondary"
					href="/templates#/template/{{$ctrl.templateId}}"
					target="_response"
					ng-disabled="!$ctrl.templateId"
					title="Edit template"><i class="material-icons">edit</i> Edit template</a>
			</div>
		</div>
		
		<div class="mb-3" ng-repeat="var in $ctrl.templateVars">
			<label for="var-{{var.name}}" class="form-label">{{var.name}} - <span class="text-muted">template variable</span></label>
			<input
				type="text"
				id="var-{{var.name}}"
				ng-model="var.val"
				placeholder="Please enter value of {{var.name}} variable"
			/>
		</div>
		
		<!-- Group/templates rules -->
		<div ng-if="template.rules.length">
			<table ng-if="template.rules.length" class="table mb-0">
				<tr ng-repeat="rule in template.rules" ng-class="$ctrl.getRuleStatusClass($index)">
					<th class="w-1 align-top text-right ok:bg-lime-100 dark:ok:bg-lime-900 ok:border-lime-300 dark:ok:border-lime-800 warning:bg-yellow-100 dark:warning:bg-yellow-600 error:bg-red-100 dark:error:bg-red-900 error:border-red-200 dark:error:border-red-800" ng-switch="rule.type">
						<span class="inline-block pt-1" ng-switch-when="text">Text on Page</span>
						<span class="inline-block pt-1" ng-switch-when="html">HTML on Page</span>
						<span class="inline-block pt-1" ng-switch-when="status">HTTP Status</span>
						<span class="inline-block pt-1" ng-switch-when="header">HTTP Header</span>
						<span class="inline-block pt-1" ng-switch-when="location">Redirect Location</span>
					</th>
					<td class="w-1 align-top ok:bg-lime-100 dark:ok:bg-lime-900 ok:border-lime-300 dark:ok:border-lime-800  warning:bg-yellow-100 dark:warning:bg-yellow-600 error:bg-red-100 dark:error:bg-red-900 error:border-red-200 dark:error:border-red-800" ng-switch="rule.op">
						<span class="inline-block pt-1" ng-switch-when="eq">is equal to</span>
						<span class="inline-block pt-1" ng-switch-when="sub">contains</span>
						<span class="inline-block pt-1" ng-switch-when="!sub">does not contain</span>
						<span class="inline-block pt-1" ng-switch-when="eqs">equals</span>
						<span class="inline-block pt-1" ng-switch-when="match">matches</span>
						<span class="inline-block pt-1" ng-switch-when="!match">does not match</span>
						<span class="inline-block pt-1" ng-switch-when="xpath">matches XPath</span>
					</td>
					<td class="ok:bg-lime-100 dark:ok:bg-lime-900 ok:border-lime-300 dark:ok:border-lime-800  warning:bg-yellow-100 dark:warning:bg-yellow-600 error:bg-red-100 dark:error:bg-red-900 error:border-red-200 dark:error:border-red-800">
						<pre class="text-sm"><code>{{rule.val || '~ value not set ~'}}</code></pre>
						<ol ng-if="$parent.templateRulesComponentForm.$pristine && statuses[$index].failures.length" class="mt-2">
							<li ng-repeat="failure in statuses[$index].failures" class="text-red-900 dark:text-red-100">
								<p class="font-semibold">{{failure.message}}</p>
								<p class="text-sm">{{failure.details}}</p>
							</li>
						</ol>
					</td>
				</tr>
			</table>
		</div>
	</section>
	`,

	controller: class TemplateRulesController {
		constructor($scope, Template) {
			this.$scope = $scope;
			this.$scope.template = {};
			this.$scope.variables = {};
			this.$scope.statuses = {};
			this.Template = Template;
		}

		static get $inject() {
			return ["$scope", "Template"];
		}

		getRuleStatusClass($index) {
			return this.$scope.templateRulesComponentForm.$pristine &&
				this.$scope.statuses[$index]?.status
				? this.$scope.statuses[$index].status
				: "unknown";
		}

		onChangeTemplate() {
			this.$scope.template = this.$scope.templates.find(
				(t) => t.id === this.templateId,
			);
			this.updateTemplateVars();
		}

		/**
		 * Získá seznam %% proměnných v template a doplné jím hodnoty, které jsme tam už měli
		 */
		updateTemplateVars() {
			if (this.$scope.template?.rules) {
				this.templateVars = [
					...new Set(
						(this.$scope.template.rules || [])
							.reduce((p, rule) => p + rule.val, "") // Spojí všechny hodnoty do jednoho řetězce
							.match(/%[^%\s]+%/g), // Najde %proměnné%
					),
				].map((name) => {
					// templateVars mohou být prázdné (ještě nebylo nic nastaveno)
					const templateVariable = this.templateVars?.find(
						(v) => name === `%${v.name}%`,
					);
					return {
						name: name.slice(1, -1), // Odseknu procenta %variable%
						val: templateVariable?.val || "",
					};
				});
			}
		}

		/**
		 * Pokusíme se vyhledat pravidlo mezi failujícícmi pravidli
		 */
		updateRulesStatus() {
			if (this.status) {
				this.$scope.statuses = this.$scope.template.rules.map((rule, order) => {
					const isFailing = this.status.failures
						.filter(
							(failed) =>
								failed.rule.origin === "template" &&
								failed.rule.order === order &&
								failed.rule.templateId === this.templateId,
						)
						.slice(0, 1);
					if (isFailing.length > 0) {
						this.hasAnyFailing = true;
					}

					return {
						failures: isFailing,
						status: isFailing.length > 0 ? "error" : "ok",
					};
				});
			}
		}

		$onChanges() {
			this.Template.user((templates) => {
				this.hasTemplates = templates.length > 0;
				if (this.hasTemplates) {
					templates.unshift({
						id: false,
						name: "-- Rules template (not selected) --",
						rules: [],
					});
					this.$scope.templates = templates;
					this.$scope.template = this.$scope.templates.find(
						(t) => t.id === this.templateId,
					);

					this.updateTemplateVars();
					this.updateRulesStatus();

					this.onChangeTemplate(this.templateId); // Vyhledám template
				}
			});
		}
	},
};
