import {
	htmlFormDataSerialize,
	htmlFormNormalizer,
} from "../forms/html-form-normalizer.js";

export const formEditor = {
	bindings: {
		formHtml: "<", // Vstupní HTML formuláře
		formParams: "=?", // Vstupní parametry
	},

	template: '<div ng-bind-html="formTrustedHtml"></div>',

	controller: class FormEditorController {
		constructor($sce, $scope, $element, $timeout) {
			this.$sce = $sce;
			this.$scope = $scope;
			this.$element = $element;
			this.$timeout = $timeout;
		}

		static get $inject() {
			return ["$sce", "$scope", "$element", "$timeout"];
		}

		$onChanges() {
			if (this.formHtml) {
				this.$scope.formTrustedHtml = this.$sce.trustAsHtml(
					htmlFormNormalizer(this.formHtml, this.formParams),
				);

				// Musím počkat na vykreslení formuláře
				this.$timeout(() => {
					const inputs = this.$element[0].querySelectorAll(
						"input,textarea,select",
					);
					for (const input of inputs) {
						input.addEventListener("input", () => this.onFormChange());
					}

					this.onFormChange(); // Initial state
				});
			}
		}

		onFormChange() {
			this.$scope.$apply(() => {
				// create clone form append child
				const form = document.createElement("form");
				form.append(this.$element[0].cloneNode(true));

				this.formParams = htmlFormDataSerialize(form);
				// Console.log(this.formParams); // debug params
			});
		}
	},
};
