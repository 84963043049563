export function Template($resource) {
	return $resource(
		"/api/template/:templateId/:action",
		{},
		{
			user: { method: "GET", params: { action: "user" }, isArray: true },
			add: { method: "POST", params: {} },
			update: { method: "PUT", params: {} },
		},
	);
}

Template.$inject = ["$resource"];
