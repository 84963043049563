import { confirmDialog } from "../../functions/confirm.js";

export default class ManageUsersController {
	constructor(
		$scope,
		$filter,
		$timeout,
		Project,
		User,
		Permission,
		Role,
		Flash,
	) {
		this.$scope = $scope;

		// Init values
		this.$scope.users = [];
		this.$scope.roles = [];
		this.$scope.subscription = null;
		this.defaultRole = null;

		this.$filter = $filter;
		this.$timeout = $timeout;
		this.Project = Project;
		this.User = User;
		this.Permission = Permission;
		this.Role = Role;
		this.Flash = Flash;

		Project.roles({ projectId: this.$scope.project.id }, (roles) => {
			this.$scope.roles = roles;
			this.defaultRole = this.$filter("filter")(
				roles,
				{ id: 6 },
				false,
			).shift(); // Read only is default role
		});

		Project.users({ projectId: this.$scope.project.id }, (users) => {
			this.$scope.users = users;
		});

		User.subscription({ projectId: this.$scope.project.id }, (subscription) => {
			this.$scope.subscription = subscription;
		});

		Permission.project({}, (permissions) => {
			this.$scope.permissions = permissions;
		});
	}

	// -------------------------------------------------------------------------------------------------------------------
	// User and Roles Management
	// -------------------------------------------------------------------------------------------------------------------

	changeUserRole(user) {
		this.Project.changeUserRole(
			{ projectId: this.$scope.project.id },
			{ userId: user.id, roleId: user.role.id },
			() => {
				// nothing right now
				this.Flash.success(
					`User role changed successfully to ${user.role.name}`,
				).limit(3);
			},
		);
	}

	async leaveProject() {
		const isConfirmed = await confirmDialog({
			title: "Leave this project?",
			text: "Are you sure you want to leave this project?",
			confirmButtonText: "Yes, leave project!",
		});

		if (isConfirmed) {
			this.Project.leave({ projectId: this.$scope.project.id }, null, () => {
				this.$timeout(() => {
					window.location.pathname = "/";
				}, 400);
			});
		}
	}

	// -------------------------------------------------------------------------------------------------------------------
	// User Management
	// -------------------------------------------------------------------------------------------------------------------

	async deleteUser(user) {
		const isConfirmed = await confirmDialog({
			title: "Remove user?",
			text: "Are you sure you want to remove the user?",
			confirmButtonText: "Yes, remove user!",
		});

		if (isConfirmed) {
			this.Project.deleteUser(
				{ projectId: this.$scope.project.id },
				{ users: [user.id] },
				() => {
					const index = this.$scope.users.indexOf(user);
					this.$scope.users.splice(index, 1);
				},
			);
		}
	}

	addUser(newUser) {
		// Try search for user in current users list
		const alreadyThere = this.$filter("filter")(
			this.$scope.users,
			{ email: newUser.email },
			true,
		);

		if (alreadyThere.length === 0) {
			// Add new user

			this.Project.addUser(
				{ projectId: this.$scope.project.id },
				{ email: newUser.email, role: newUser.role.id },
				(user) => {
					this.$scope.newUser = null;
					this.$scope.users.push(user);

					// Reset newUser form
					newUser.email = null;
					newUser.role = null;
					newUser.$setUntouched();
					newUser.$setPristine();
				},
			);
		} else {
			// Highlight existing user in table

			this.$scope.showAlreadyThereMessage = true;
			this.$scope.highlightUserId = alreadyThere[0].id;
			this.$timeout(() => {
				this.$scope.highlightUserId = false;
				this.$scope.showAlreadyThereMessage = false;
			}, 4000);
		}
	}

	// -------------------------------------------------------------------------------------------------------------------
	// Roles Management
	// -------------------------------------------------------------------------------------------------------------------

	filterByRole(id) {
		this.$scope.filterByRole = id;
	}

	saveRole(role) {
		this.Role.update(
			{ roleId: role.id },
			{ permissions: role.permissionsSwitches },
			(newRole) => {
				// Update users in changed role
				for (const user of this.$filter("filter")(
					this.$scope.users,
					{ role: { id: role.id } },
					true,
				)) {
					user.role = newRole;
				}
			},
		);
	}

	addNewRole(newRole) {
		this.Role.add(
			{},
			{ name: newRole.name, permissions: { read: true } },
			(role) => {
				this.$scope.roles.push(role);
				newRole.name = null;
				newRole.$setUntouched();
				newRole.$setPristine();
			},
		);
	}

	async deleteRole(role) {
		const isConfirmed = await confirmDialog({
			title: `Delete ${role.name} role?`,
			text: `Are your sure you want to delete role ${role.name}? This can affect all your Projects.`,
			confirmButtonText: "Yes, delete role!",
		});

		if (isConfirmed) {
			this.Role.remove({ roleId: role.id }, () => {
				// Set default role to all users with deleted role
				for (const user of this.$filter("filter")(
					this.$scope.users,
					{ role: { id: role.id } },
					true,
				)) {
					user.role = this.defaultRole;
				}

				const index = this.$scope.roles.indexOf(role);
				this.$scope.roles.splice(index, 1);
			});
		}
	}
}

ManageUsersController.$inject = [
	"$scope",
	"$filter",
	"$timeout",
	"Project",
	"User",
	"Permission",
	"Role",
	"Flash",
];
