export function Permission($resource) {
	return $resource(
		"/api/permission/:permissionId/:action",
		{},
		{
			project: {
				method: "GET",
				params: { permissionId: "project" },
				isArray: true,
			},
		},
	);
}

Permission.$inject = ["$resource"];
