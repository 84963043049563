import { confirmDialog } from "../functions/confirm.js";

const TemplatesController = function TemplatesController(
	$scope,
	$location,
	auth,
	Template,
	User,
	Flash,
) {
	const EmptyTemplate = function () {
		this.name = "";
		this.rules = [];
	};

	const getCurrentTemplateId = () => {
		const matches = window.location.href.match(/template\/([a-z\d]+)/i);
		return matches ? matches[1] : null;
	};

	$scope.template = new EmptyTemplate();

	// Curently edited template
	$scope.currentTemplateId = getCurrentTemplateId();

	$scope.templates = [];

	Template.user((templates) => {
		$scope.templates = templates;
		$scope.showHelp = templates.length === 0;
	});

	/**
	 * Save template
	 */
	$scope.saveTemplate = (template) => {
		if (template === $scope.template) {
			Template.add({}, template, (template) => {
				$scope.templates.push(template);
				$scope.showHelp = $scope.templates.length === 0;
				$scope.template = new EmptyTemplate();
				$scope.closeTemplate();
				Flash.success("Template saved");
			});
		} else {
			Template.update({ templateId: template.id }, template, () => {
				$scope.closeTemplate();
				Flash.success("Template saved");
			});
		}
	};

	/**
	 * Close edited template
	 */
	$scope.closeTemplate = () => {
		$scope.currentTemplateId = null;
		$location.path("/template/");
		$location.replace();
	};

	$scope.removeTemplate = async (template) => {
		const isConfirmed = await confirmDialog({
			title: "Delte tempalte?",
			text: `Are you sure you want to delete template ${template.name}?`,
			confirmButtonText: "Yes, delete template!",
		});

		if (isConfirmed) {
			Template.delete({ templateId: template.id }, () => {
				const i = $scope.templates.indexOf(template);
				if (i > -1) {
					$scope.templates.splice(i, 1);
				}

				Flash.success("Template deleted");
				$scope.showHelp = $scope.templates.length === 0;
			});
		}
	};
};

TemplatesController.$inject = [
	"$scope",
	"$location",
	"auth",
	"Template",
	"User",
	"Flash",
];

export default TemplatesController;
