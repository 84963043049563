import { DateTime } from "luxon";
import template from "./sign-up-form.directive.html";

export function signUpForm() {
	return {
		restrict: "E",
		templateUrl: template,
		link($scope, element, attributes) {
			$scope.redirect = $scope.$eval(attributes.redirect);
			$scope.origin = $scope.$eval(attributes.origin);
			$scope.submit = attributes.submit;
			$scope.newsletter = $scope.$eval(attributes.newsletter);
			$scope.timezone = DateTime.local().isValid
				? DateTime.local().zoneName
				: "Europe/Prague";

			/** Trackování dokončení registrace */
			$scope.completeRegistration = () => {
				// Tag Manager (GA4...)
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					event: "sign_up",
					method: "",
				});

				// Facebook Ads - dočasně vypnuto (na FB teď neinzerujeme)
				// Pokud bysme znova inzerovali na FB, tak trekovat přes Tag manager (jako GA4) a ne pomocí fbq()
				// fbq('track', 'CompleteRegistration');
			};
		},
	};
}
