import { getInputLabel } from "./get-input-label.js";

export function htmlFormPreview(html) {
	const inputHtml = document.createElement("div");
	inputHtml.innerHTML = html;

	const output = document.createElement("div");

	for (const input of inputHtml.querySelectorAll(
		"input,textarea,button,select",
	)) {
		const inputLabel = getInputLabel(inputHtml, input);

		if (
			input.nodeName === "INPUT" &&
			input.name &&
			!["hidden", "file", "reset", "submit", "radio", "checkbox"].includes(
				input.type,
			)
		) {
			output.insertAdjacentHTML(
				"beforeend",
				`
				<div class="mb-2 last:mb-0">
					<label class="opacity-60 dark:opacity-50">${inputLabel}</label>
					<input
							type="${input.type}"
							value="${input.value}"
							placeholder="${input.name}"
							autocomplete="current-password"
							class="pointer-events-none" disabled>
				</div>
			`,
			);
		}

		// Submit, reset buttons
		if (input.nodeName === "INPUT" && ["submit"].includes(input.type)) {
			output.insertAdjacentHTML(
				"beforeend",
				`
				<div class="mb-2 last:mb-0">
					<button class="btn" disabled>${input.value || input.name}</button>
				</div>
			`,
			);
		}

		// Radio, checkbox
		if (
			input.nodeName === "INPUT" &&
			["checkbox", "radio"].includes(input.type)
		) {
			output.insertAdjacentHTML(
				"beforeend",
				`
				<div class="mb-2 last:mb-0">
					<label class="opacity-60 dark:opacity-50">
					<input type="${input.type}" class="pointer-events-none" disabled> ${inputLabel}</label>
				</div>
			`,
			);
		}

		// Buttons
		if (input.nodeName === "BUTTON" && input.type !== "reset") {
			output.insertAdjacentHTML(
				"beforeend",
				`
				<div class="mb-2 last:mb-0">
					<button type="button" class="pointer-events-none" disabled>${input.textContent}</button>
				</div>
			`,
			);
		}

		if (input.nodeName === "TEXTAREA" && input.name) {
			output.insertAdjacentHTML(
				"beforeend",
				`
				<div class="mb-2 last:mb-0">
					<label class="opacity-60 dark:opacity-50">${inputLabel}</label>
					<textarea class="pointer-events-none" disabled></textarea>
				</div>
			`,
			);
		}

		if (input.nodeName === "SELECT" && input.name) {
			output.insertAdjacentHTML(
				"beforeend",
				`
				<div class="mb-2 last:mb-0">
					<label class="opacity-60 dark:opacity-50">${inputLabel}</label>
					<select class="pointer-events-none" disabled>${input.firstChild.outerHTML}</select>
				</div>
			`,
			);
		}
	}

	return output.innerHTML;
}
