/**
 * Provede označená obsahu vybraného elementu
 * @param element
 */
export function select(element) {
	element = element || event.currentTarget;
	const range = document.createRange();
	range.selectNodeContents(element);
	const sel = window.getSelection();
	sel.removeAllRanges();
	sel.addRange(range);
}

window.select = select; // global function
