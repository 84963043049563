<template>
	<div>
    <textarea
	    v-model="urls"
	    :class="maxUrls ? 'mb-1' : 'mb-3'"
	    v-bind="$attrs"
	    @input="onInput"
    ></textarea>
		<div v-if="maxUrls" class="flex items-center gap-2 justify-end">
			<p class="text-gray-500 text-sm text-right">
				You have entered {{ urlsCount }} of {{ maxUrls }} URL addresses.
			</p>
			<div class="w-full bg-gray-200 rounded-full h-1.5 my-1 dark:bg-gray-700 max-w-48">
				<div :style="{width: progress + `%`}" class="bg-blue-400 h-1.5 rounded-full dark:bg-blue-500"></div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { useDebounceFn } from "@vueuse/core";
import { computed } from "vue";
import { addProtocol } from "../functions/add-protocol.js";
import { autosize } from "../functions/autosize.js";

const urls = defineModel({
	required: true,
	type: String,
	default: [],
});

const props = defineProps({
	maxUrls: { type: Number, default: 0 },
});

const progress = computed(() => {
	return Math.min(
		100,
		(urls.value.split("\n").filter(Boolean).length / props.maxUrls) * 100,
	);
});

const urlsCount = computed(() => {
	return urls.value.split("\n").filter(Boolean).length;
});

function onInput(event: Event) {
	const textarea = (event as InputEvent).target as HTMLTextAreaElement;

	// Add protocol to each URL
	useDebounceFn(() => {
		// Add protocol to each URL
		urls.value = textarea.value = textarea.value
			.split("\n")
			.map((url) => {
				return addProtocol(url);
			})
			.join("\n");

		if (props.maxUrls) {
			// validate textarea
			textarea.setCustomValidity(
				urls.value.split("\n").filter(Boolean).length > props.maxUrls
					? `You have entered more than ${props.maxUrls} unique addresses. Please remove some.`
					: "",
			);
			textarea.reportValidity();
		}
	}, 250)();

	// Autosize textarea
	autosize(textarea);
}
</script>
