/**
 * Directiva řešící autofocus elementu
 *
 * <el
 *     autofocus
 *     autofocus = "autofocus"
 *     autofocus = [angular expresssion]
 * >
 * @param $timeout
 * @returns {{link: link, restrict: string}}
 */
export function autofocus($timeout) {
	return {
		restrict: "A",
		link(scope, element, attributes) {
			scope.$watch(attributes.autofocus, (value) => {
				if (
					value === undefined ||
					autofocus === "autofocus" ||
					value === true
				) {
					$timeout(() => {
						element[0].focus();
					});
				}
			});
		},
	};
}

autofocus.$inject = ["$timeout"];
