const getAreaDetailsKey = (projectId, areaId) =>
	["checkDetails", projectId, areaId].join("-");

/**
 * Uloží stav zobrazení area details
 * @param state
 * @param projectId
 * @param areaId
 */
export function saveAreaDetailsVisibility(state, projectId, areaId) {
	const key = getAreaDetailsKey(projectId, areaId);
	if (state) {
		localStorage.setItem(key, true);
	} else {
		localStorage.removeItem(key);
	}
}

/**
 * Vrací viditelnost area details
 * @param projectId
 * @param areaId
 * @return {boolean}
 */
export function getAreaDetailsVisibility(projectId, areaId) {
	const key = getAreaDetailsKey(projectId, areaId);
	return Boolean(localStorage.getItem(key) || false);
}
