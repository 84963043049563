import * as d3 from "d3";
import { escape } from "lodash";
import { SimpleTooltip } from "./simple-tooltip.js";

export function issueDataToHierarchy(data) {
	const group = d3.group(
		data,
		(incident) => incident.status,
		(incident) => incident.shorthand,
	);
	const byStatus = d3.rollup(
		data,
		(incidents) => incidents.length,
		(incident) => incident.status,
	);

	const children = Array.from(group, ([status, children]) => ({
		name: status === "new" ? "Unresolved" : "Resolved",
		status,
		total: byStatus.get(status),
		children: Array.from(children, ([shorthand, incidents]) => ({
			name: shorthand,
			incidents,
			value: incidents.length,
			status,
		})),
	}));

	return { name: "Issues", total: data.length, children };
}

export class IssueChart {
	/**
	 * @param {Element} element
	 * @param {array} data
	 */
	constructor(element, data) {
		element.innerHTML = "";

		this.data = issueDataToHierarchy(data);

		// přidám graf
		this.chartBody = d3.select(element).append("figure");
		this.chartWidth = this.chartBody.node().getBoundingClientRect().width;
		this.chartHeight = Math.max(
			this.chartBody.node().getBoundingClientRect().height,
			280,
		);

		this.svg = this.chartBody
			.append("svg")
			.attr("width", this.chartWidth)
			.attr("height", this.chartHeight)
			.attr("viewBox", [0, 0, this.chartWidth, this.chartHeight])
			.attr("preserveAspectRatio", "xMinYMin meet")
			.attr("xmlns", "http://www.w3.org/2000/svg")
			.attr("style", "max-width: 100%; height: auto; height: intrinsic;");

		this.tooltip = new SimpleTooltip(this.chartBody);
		this.addTreeMap();
	}

	addTreeMap() {
		const hierrarchy = d3
			.hierarchy(this.data)
			.sum((d) => d.value + 2) // zvětší uměle velikost plochy (jinak jsou obdélník s value 1 moc malé)
			.sort((a, b) => b.value - a.value);

		d3
			.treemap()
			.size([this.chartWidth, this.chartHeight])
			.paddingOuter(4)
			.paddingTop(15)
			.paddingBottom(15)
			.paddingInner(3)(hierrarchy);

		const nodes = this.svg
			.selectAll("g")
			.data(hierrarchy.leaves())
			.join("g")
			.attr("transform", (d) => `translate(${d.x0},${d.y0})`);

		// add treemap rects
		nodes
			.append("rect")
			.attr("width", (d) => d.x1 - d.x0)
			.attr("height", (d) => d.y1 - d.y0)
			.attr("title", (d) => d.data.name)
			.attr("class", (d) =>
				d.data.status === "resolved"
					? "fill-lime-400 dark:fill-lime-700"
					: "fill-red-600 dark:fill-red-800",
			)
			.attr("rx", 6)
			.on("mousemove", (event) => {
				const d = d3.select(event.target).data().pop();

				this.tooltip
					.setElement(event.toElement)
					.setText(`${d.data.value}× ${d.data.name}`)
					.show();
			})
			.on("mouseout", () => {
				this.tooltip.hide();
			});

		this.svg
			.append("g")
			.selectAll("text")
			.data(hierrarchy.leaves())
			.join("text")
			.attr("text-anchor", "middle")
			.attr("dominant-baseline", "middle")
			.style("pointer-events", "none")
			.attr(
				"class",
				(d) =>
					`text-base ${d.data.status === "resolved" ? "fill-gray-900 dark:fill-white" : "fill-white"}`,
			)
			.attr("x", (d) => d.x0 + (d.x1 - d.x0) / 2)
			.attr("y", (d) => d.y0 + (d.y1 - d.y0) / 2)
			.html(
				(d) =>
					`<tspan class="font-bold">${d.data.value}×</tspan> ${escape(d.data.name)}`,
			)
			.each(function (d) {
				const label = d3.select(this);

				const width = d.x1 - d.x0 - 6; // 6px spacing
				const height = d.y1 - d.y0 - 6; // 6px spacing
				const x = d.x0 + (d.x1 - d.x0) / 2;
				const y = d.y0 + (d.y1 - d.y0) / 2;

				// Label je širší než obdélník, do kterého se má vepsat
				if (label.node().getComputedTextLength() > width) {
					// zkusím jej otočit o 90
					if (label.node().getComputedTextLength() < height) {
						label.attr("transform", `rotate(90 ${x} ${y})`);
					} else {
						// změním obsah - vypíšu pouze počet a budu doufat
						label.text(`${d.data.value}×`);

						// pořád se nevejde, tak jej skryju
						if (label.node().getComputedTextLength() > width) {
							label.style("visibility", "hidden");
						}
					}
				}
			});

		const root = hierrarchy.descendants().findLast((d) => d.depth === 0);

		this.svg
			.append("text")
			.attr("text-anchor", "start")
			.attr("dominant-baseline", "hanging")
			.attr(
				"class",
				"font-semibold text-xl fill-gray-600 dark:fill-gray-100 leading-none",
			)
			.attr("x", 6)
			.attr("y", 6)
			.text(`Total incidents ${root.data.total}×`);

		// Group titles
		this.svg
			.append("g")
			.selectAll("titles")
			.data(hierrarchy.descendants().filter((d) => d.depth === 1))
			.enter()
			.append("text")
			.attr("text-anchor", "start")
			.attr("dominant-baseline", "middle")
			.attr(
				"class",
				(d) =>
					`${d.data.status === "new" ? "fill-red-500 dark:fill-red-700" : "fill-lime-600 dark:fill-lime-700"} text-md`,
			)
			.attr("x", (d) => d.x0)
			.attr("y", (d) => d.y1 + 2)
			.html(
				(d) =>
					`<tspan class="font-bold text-xl">${d.data.total}×</tspan> ${d.data.name}`,
			)
			.each(function () {
				const label = d3.select(this);
				const parent = this.parentNode;
				if (
					label.node().getBoundingClientRect().left +
						label.node().getBoundingClientRect().width >
					parent.getBoundingClientRect().width
				) {
					label.attr("text-anchor", "end").attr("x", (d) => d.x1);
				}
			});
	}
}
