export function Project($resource) {
	return $resource(
		"/api/project/:projectId/:action",
		{},
		{
			create: { method: "POST", params: { action: "create" }, isArray: true },
			update: { method: "PUT", params: {} },
			remove: { method: "DELETE", params: {} },
			status: { method: "GET", params: { action: "status" } },
			areas: { method: "GET", params: { action: "areas" }, isArray: true },
			users: { method: "GET", params: { action: "users" }, isArray: true },
			roles: { method: "GET", params: { action: "roles" }, isArray: true },
			permissions: { method: "GET", params: { action: "permissions" } },
			options: { method: "GET", params: { action: "options" } },
			notifications: { method: "GET", params: { action: "notifications" } },
			setNotifications: { method: "POST", params: { action: "notifications" } },
			addUser: { method: "POST", params: { action: "users" } },
			changeUserRole: {
				method: "POST",
				params: { action: "changeUserRole" },
				isArray: true,
			},
			deleteUser: {
				method: "DELETE",
				params: { action: "users", users: "@users" },
				isArray: true,
			},
			leave: { method: "POST", params: { action: "leave" }, isArray: true },
			sortTests: { method: "POST", params: { action: "sortTests" } },
			createTests: { method: "POST", params: { action: "createTests" } },
		},
	);
}

Project.$inject = ["$resource"];
