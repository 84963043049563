/**
 * Direktiva pro seznam testů
 */
import { range } from "../functions/range.js";

/**
 * Direktiva pro vybrání elementu (označení třídou)
 * @returns {{link(*, *): void, restrict: string}}
 */
export function selectable() {
	let globalHandler;
	let lastIndex = 0;

	return {
		restrict: "A",
		link($scope, $element) {
			let hasGlobalHandler;
			$element.on("click", (event) => {
				if (event.target.nodeName === "A" || event.target.nodeName === "SPAN") {
					return;
				}

				$scope.$apply(() => {
					const index = $scope.status.tests.indexOf($scope.test);

					if (event.ctrlKey || event.metaKey) {
						if ($scope.test.selected) {
							delete $scope.test.selected;
						} else {
							$scope.test.selected = true;
							lastIndex = index;
						}
					} else if (event.shiftKey) {
						// najdu si rozsah označení
						const start = Math.min(index, lastIndex);
						const stop = Math.max(index, lastIndex);

						// označím jako selected
						for (const i of range(start, stop)) {
							$scope.status.tests[i].selected = true;
						}

						// update last index
						lastIndex = stop;
					} else {
						$scope.test.selected = !$scope.test.selected;
						lastIndex = index;
					}

					// pošlu změnu vybraných testů nahoru
					// viz. project-check-ctrl.js který poslouchá
					$scope.$emit(
						"test.selected.change",
						$scope.status.tests
							.filter((test) => test.selected)
							.map((test) => test.id),
					);
				});
			});

			// Globální handler (kliknu někde mimo testy)
			if (!hasGlobalHandler) {
				hasGlobalHandler = true;
				if (globalHandler) {
					// Pokud jsem registrovali jiný pro starý scope, zrušíme ho
					document.body.removeEventListener("click", globalHandler);
				}

				globalHandler = (event) => {
					// kliknu někde mimo [selectable] element
					if (!event.target.closest("[selectable]")) {
						$scope.$apply(() => {
							for (const test of $scope.status.tests) {
								delete test.selected;
							}

							$scope.$emit("test.selected.change", []);
						});
					}
				};

				document.body.addEventListener("click", globalHandler);
			}
		},
	};
}
