import "../css/style.css";
// global functions
import "./app/functions/copy.js";
import "./app/functions/select.js";
import "./user-invalid.js";
// Testomato app
import "./app/app.module.js";
import "./app/charts/report-charts.js";
import "./testomato/dashboard.js";
// Vue app
import "./vue.app.js";

// Angular je stará sračka, která HMR stejně neumí
// @see https://parceljs.org/features/development#hot-reloading
if (module.hot) {
	module.hot.dispose(() => {
		window.location.reload();
	});
}
