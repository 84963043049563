import * as d3 from "d3";
import { DateTime } from "luxon";
import { Timeline } from "vis-timeline/esnext";
import { getTimelineData } from "../../charts/get-timeline-data.js";
import { IssueChart } from "../../charts/issue-chart.js";
// @see https://observablehq.com/@d3/calendar
import { timelineOptions } from "../../charts/timeline-options.js";
import { duration } from "../../filters/duration.filter.js";
import { getReportIntrvalByName } from "./get-reports-interval-by-name.js";

export default class ProjectReportsController {
	constructor($scope, $timeout, $location, $http, Incident, google) {
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$location = $location;
		this.$http = $http;
		this.Incident = Incident;
		this.google = google;

		this.$scope.interval = { start: null, end: null, name: null };
		this.$scope.max = DateTime.local().toISODate();
		this.$scope.min = DateTime.local().minus({ years: 1 }).toISODate();
		this.$scope.showIncidents = null;
		this.$scope.incidents = [];
		this.$scope.incident = null;
		this.$scope.stats = { min: undefined, median: undefined, max: undefined };
		this.$scope.showIncidentDialog = false;
		this.$scope.showUptimeChart = null;
		this.$scope.uptimeData = [];
		this.$scope.showResponseTimeChart = null;
		this.$scope.responseTimeData = [];
		this.$scope.timelineCluster = timelineOptions.cluster;

		// timeline
		const timeline = document.querySelector("#issueTimelineChart");
		if (timeline) {
			this.timeline = new Timeline(
				document.querySelector("#issueTimelineChart"),
			);
			this.timeline.setOptions(timelineOptions);
		}

		// incident dialog close
		const incidentDialog = document.querySelector("#incidentDialog");
		if (incidentDialog) {
			incidentDialog.addEventListener("click", (event) => {
				if (event.target === incidentDialog) {
					incidentDialog.close();
				}
			});
		}

		// detect interval
		const interval =
			this.$location.search().interval ||
			JSON.parse(localStorage.getItem("reports-interval")) ||
			"Last 30 days";
		this.setInterval(interval);
	}

	/**
	 * Nastavím custom interval výměrem data
	 * @param {DateTime} startDate
	 * @param {DateTime} endDate
	 */
	setCustomInterval(startDate, endDate) {
		const start = DateTime.fromJSDate(startDate).startOf("day").toISO();
		const end = DateTime.fromJSDate(endDate).endOf("day").toISO();
		this.setInterval({ start, end });
	}

	/**
	 * Aktualizuje zobrazení po změně intervalu
	 * @param {string} name
	 * @param {DateTime} start
	 * @param {DateTime} end
	 */
	setInterval({ name = "Custom range", start = null, end = null }) {
		// dostal jsem pouze název intervalu, zbytek si musím dohledat
		if (typeof name === "string" && start === null && end === null) {
			this.$scope.interval = getReportIntrvalByName(name);
			localStorage.setItem(
				"reports-interval",
				JSON.stringify({ name: this.$scope.interval.name }),
			); // Save
		}

		// uživatel si vybral custom range
		end = end || DateTime.local().endOf("day");
		if (start && end) {
			this.$scope.interval = {
				name,
				start: DateTime.fromISO(start),
				end: DateTime.fromISO(end),
			};
			localStorage.setItem(
				"reports-interval",
				JSON.stringify(this.$scope.interval),
			); // Save
		}

		// se to nepovedlo... použiju default
		if (
			!this.$scope.interval.start.isValid ||
			!this.$scope.interval.end.isValid
		) {
			this.$scope.interval = getReportIntrvalByName();
			localStorage.setItem(
				"reports-interval",
				JSON.stringify({ name: this.$scope.interval.name }),
			); // Save
		}

		this.$scope.start = this.$scope.interval.start.toJSDate();
		this.$scope.end = this.$scope.interval.end.toJSDate();

		this.Incident.byInterval(
			{
				projectId: this.$scope.project.id,
				start: this.$scope.interval.start.toISO(),
				end: this.$scope.interval.end.toISO(),
			},
			(incidents) => {
				this.$scope.showIncidents = incidents.length > 0;
				this.$scope.incidents = incidents;
				this.$timeout(() => {
					this.updateView(incidents);
				});
			},
		);

		this.$http
			.get(
				`/api/project/${this.$scope.project.id}/uptime?start=${this.$scope.interval.start.toISO()}&end=${this.$scope.interval.end.toISO()}`,
			)
			.then((response) => {
				this.$scope.showUptimeChart = Boolean(
					response?.data?.isUptimeEnabled &&
						response?.data?.uptimeByDays?.length > 0,
				);
				if (this.$scope.showUptimeChart) {
					this.$scope.uptimeData = response.data;
				}
			});

		this.$http
			.get(
				`/api/project/${this.$scope.project.id}/responseTimes?start=${this.$scope.interval.start.toISO()}&end=${this.$scope.interval.end.toISO()}`,
			)
			.then((response) => {
				this.$scope.showResponseTimeChart = Boolean(response?.data?.length > 1);
				if (this.$scope.showResponseTimeChart) {
					this.$scope.responseTimeData = response.data;
				}
			});
	}

	updateView(incidents) {
		// spočítá incidenty na základě jejich statusu
		const countByStatus = d3.rollup(
			incidents,
			(incidents) => incidents.length,
			(incident) => incident.status,
		);

		// najde nejdelší nevyřešený incident
		const longest = incidents
			.filter((incident) => incident.status === "new")
			.sort((a, b) => b.created - a.created)
			.pop();

		this.$scope.incidentsStats = {
			total: incidents.length,
			resolved: countByStatus.get("resolved"),
			unresolved: countByStatus.get("new"),
			longest: longest ? duration(longest.created) : undefined,
		};

		if (incidents.length > 0) {
			// překreslíme issue type chart
			const issueChartElement = document.querySelector("#issueTypeChart");
			new IssueChart(issueChartElement, incidents);

			if (this.timeline) {
				// překreslím timeline
				const { items, groups } = getTimelineData(
					incidents,
					this.$scope.start,
					this.$scope.end,
				);
				this.timeline.setItems(items);
				this.timeline.setGroups(groups);
				timelineOptions.min = this.$scope.start;
				timelineOptions.max = this.$scope.end;
				this.timeline.setOptions(timelineOptions);
				this.timeline.setWindow(this.$scope.start, this.$scope.end);
				this.timeline.redraw();

				// Issues timeline click

				this.timeline.on("click", (event) => {
					if (event?.isCluster === false && event?.item) {
						// vyhledám incident
						const incident = incidents.find(
							(incident) => incident._id === event.item,
						);

						// zobrazím dialog
						this.$scope.$apply(() => {
							this.$scope.incident = incident;
							document.querySelector("#incidentDialog").showModal();
							this.$scope.showIncidentDialog = true;
						});
					}
				});
			}
		}
	}

	timelineToggleCluster() {
		this.$scope.timelineCluster = !this.$scope.timelineCluster;
		timelineOptions.cluster = this.$scope.timelineCluster;
		this.timeline.setOptions(timelineOptions);
	}

	timelineZoomIn() {
		this.timeline.zoomIn(0.5);
	}

	timelineZoomOut() {
		this.timeline.zoomOut(0.5);
	}

	static get $inject() {
		return ["$scope", "$timeout", "$location", "$http", "Incident", "google"];
	}
}
