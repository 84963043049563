export function Incident($resource) {
	return $resource(
		"/api/incident/:action",
		{},
		{
			byInterval: {
				method: "GET",
				params: { action: "byInterval" },
				isArray: true,
			},
		},
	);
}

Incident.$inject = ["$resource"];
