import { confirmDialog } from "../functions/confirm.js";
import { copy } from "../functions/copy.js";

const UserProfileController = function UserProfileController(
	$scope,
	$q,
	$location,
	User,
	Flash,
) {
	$scope.alert = {};
	$scope.copy = copy;

	/**
	 * Payment subscription
	 * @type {Resource}
	 */
	$scope.subscription = User.subscription();

	$scope.deleteSocialAccount = async (index) => {
		const isConfirmed = await confirmDialog({
			title: "Disconnect social account?",
			text: "Are you sure that you want to disconnect social account?",
			confirmButtonText: "Yes, disconnect!",
		});

		if (isConfirmed) {
			const remove = $scope.me.oauths[index];
			User.deleteSocialAccount({ id: remove.id }, (response) => {
				if (response.ok) {
					$scope.me.oauths.splice(index, 1);
					const indexConnected = $scope.me.oauthsConnected.indexOf(
						remove.provider,
					);
					if (indexConnected > -1) {
						$scope.me.oauthsConnected.splice(indexConnected, 1);
					}

					$scope.me.oauthsConnectShow =
						$scope.me.oauths.length < $scope.me.oauthsConnect.length;
				}

				Flash.add(response.error ? "error" : "success", response.message);
			});
		}
	};

	$scope.resetPassword = (response) => {
		if (response.ok) {
			$scope.me.newPassword = "";
			$scope.me.oldPassword = "";
		}
	};

	$scope.saveUserProfile = (data, success) => {
		User.update(data, (response) => {
			if (success) {
				success(response); // Success callback
			}

			if (response.message) {
				Flash.add(response.error ? "error" : "success", response.message);
			}
		});
	};

	$scope.regenerateApiKey = async () => {
		const isConfirmed = await confirmDialog({
			title: "Regenerate API key?",
			text: "The current API key will be invalidated and any scripts or applications using this API key will need to be updated.",
			confirmButtonText: "Yes, regenerate API key!",
			icon: "question",
		});

		if (isConfirmed) {
			User.regenerateApiKey({}, (response) => {
				$scope.me.apiKey = response.apiKey;
			});
		}
	};

	$scope.highlight = {};
	if ($location.search().highlight) {
		$scope.highlight = $location.search().highlight;
	}
};

UserProfileController.$inject = ["$scope", "$q", "$location", "User", "Flash"];

export default UserProfileController;
