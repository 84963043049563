export const rules = {
	bindings: {
		rules: "<?",
		status: "<?",
		url: "<?",
	},

	template: `
	<section class="rules">
	
		<div ng-repeat="failure in status.failures">
			<p class="alert" ng-class="{'alert-danger': failure.severity == 1, 'alert-warning': failure.severity == 2}" ng-if="!failure.rule">
				{{failure.details}}
			</p>
		</div>
	
		<!-- Rules list -->
		<ul ng-form="rulesComponentForm">
			<li ng-repeat="rule in $ctrl.rules" ng-class="statuses[$index].status" class="grid gap-1.5 lg:grid-cols-[260px_160px_1fr_fit-content(100px)] mb-1.5 last:mb-2">
	
				<!-- Source -->
				<div>
					<ruletype type="rule.type" on-change="$ctrl.onRuleTypeChange(rule)"></ruletype>
				</div>
	
				<!-- Comparison -->
				<div>
					<select ng-model="rule.op" class="ok:bg-lime-100 dark:ok:bg-lime-900/50 warning:bg-yellow-100 dark:warning:bg-yellow-700/50 error:bg-red-100 dark:error:bg-red-900">
						<option ng-show="rule.type=='status'" value="eq">is equal to</option>
						<option value="sub">contains</option>
						<option value="!sub">does not contain</option>
						<option ng-show="rule.type!='status'" value="eqs">equals</option>
						<option value="match">matches</option>
						<option value="!match">does not match</option>
						<option ng-show="rule.type=='html'" value="xpath">matches XPath</option>
					</select>
				</div>
	
				<!-- Value -->
				<div>
					<textarea
						ng-trim="false"
						ng-if="rule.type!='status'"
						autoexpand
						rows="1"
						ng-disabled="rule.type=='status'"
						ng-model="rule.val"
						ng-change="$ctrl.onRuleValueChange(rule)"
						tabindex="{{$index}}"
						class="ok:bg-lime-100 dark:ok:bg-lime-900/50 warning:bg-yellow-100 dark:warning:bg-yellow-700/50 error:bg-red-100 dark:error:bg-red-900"
						ng-attr-placeholder="{{{true: 'Regular expression', false: ''}[rule.op.substr(-5)=='match']}}"></textarea>
	
					<combobox
						ng-if="rule.type=='status'"
						ng-disabled="rule.type!='status'"
						value="rule.val"
						placeholder="'Plase enter a value'"
						options="$ctrl.statusOptions"
					></combobox>
	
					<!-- Failures list -->
					<ol ng-if="statuses[$index].failures.length && $parent.rulesComponentForm.$pristine">
						<li ng-repeat="failure in statuses[$index].failures" class="error:text-red-900 dark:error:text-red-100 warning:text-yellow-900 dark:warning:text-yellow-400 mt-2">
							<p class="font-semibold">{{failure.message}}</p>
							<p class="text-sm">{{failure.details}}</p>
						</li>
					</ol>
				</div>
				
				<!-- Actions -->
				<div class="min-w-max">
					<button
						type="button"
						ng-click="$ctrl.loadCurrentValue(rule)"
						ng-show="$ctrl.url"
						ng-hide="['text','html','header'].includes(rule.type)"
						aria-hidden="true"
						title="Download current value">
						<i class="material-icons">file_download</i>
					</button>
					<button
						type="button"
						ng-click="$ctrl.removeRule($index)"
						aria-hidden="true"
						title="Remove rule">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
							<path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"></path>
						</svg>
					</button>
				</div>
			</li>
		</ul>
		<!-- /Rule list -->
	
		<div>
			<button type="button" ng-click="$ctrl.addRule()">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="w-5 h-5">
					<path fill="none" d="M0 0h24v24H0z"></path>
					<path d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm7 8H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" fill="currentColor"></path>
				</svg>
				Add rule
			</button>
			<button type="button" ng-show="$ctrl.rules.length" ng-click="$ctrl.duplicateLastRule()">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
				  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
				</svg>
        Duplicate last
			</button>
		</div>
	</section>
	`,
	controller: class RulesController {
		constructor($scope, CurrentValue) {
			this.$scope = $scope;
			this.CurrentValue = CurrentValue;
		}

		static get $inject() {
			return ["$scope", "CurrentValue"];
		}

		static get statusOptions() {
			return [
				{ value: "200", label: "Success (200)" },
				{ value: "404", label: "Not Found (404)" },
				{ value: "301", label: "Moved Permanently (301)" },
				{ value: "302", label: "Redirect (302)" },
			];
		}

		/**
		 * Callback při změněn hodnoty rulle - normalizujeme konce řádků
		 * @param rule
		 */
		onRuleValueChange(rule) {
			rule.val = rule.val?.replaceAll(/\r?\n/g, "\r\n");
		}

		/**
		 * Callback při změně rull type
		 * @param rule
		 */
		onRuleTypeChange(rule) {
			switch (rule.type) {
				case "status": {
					rule.op = "eq";
					break;
				}

				case "location": {
					rule.op = "eqs";
					break;
				}

				default: {
					rule.op = "sub";
					break;
				}
			}

			// Pokud není vyplněna hodnota, tak při změně `rule.type` použijeme aktuální hodnotu z webu
			if (!rule.val && this.url) {
				this.loadCurrentValue(rule);
			}
		}

		$onChanges() {
			if (this.status) {
				this.$scope.statuses = this.rules.map((rule, order) => {
					// Pokusíme se vyhledat pravidlo mezi failujícícmi pravidli
					// (vynecháváme failed.rule === null, to jsou common server errors)
					const isFailing = this.status.failures
						.filter(
							(failed) =>
								failed.rule &&
								failed.rule.origin === "check" &&
								failed.rule.order === order,
						)
						.slice(0, 1);
					return {
						failures: isFailing,
						status: isFailing.length > 0 ? "error" : "ok",
					};
				});
			}
		}

		/**
		 * Načte současnou hodnotu na vybrané URL
		 * @param rule
		 */
		loadCurrentValue(rule) {
			this.CurrentValue.get(
				{ url: this.url, ruletype: rule.type },
				(result) => {
					rule.val = result.value; // Set current value
				},
			);
		}

		/**
		 * Přidá nové, prázdné pravidlo
		 */
		addRule() {
			this.rules.push({ type: "text", op: "sub", val: "" });
		}

		/**
		 * Odstraní pravidlo
		 * @param $index
		 */
		removeRule($index) {
			this.rules.splice($index, 1);
			this.$scope?.statuses?.splice($index, 1);
		}

		/**
		 * Okopíruje poslední pravidlo
		 */
		duplicateLastRule() {
			const lastRule = this.rules.at(-1);
			this.rules.push({
				type: lastRule.type,
				op: lastRule.op,
				val: lastRule.val,
			});
		}
	},
};
