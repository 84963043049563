/**
 * Newline Delimited JSON parser/reader
 *
 * @see http://ndjson.org/
 * @see https://jsonlines.org/
 * @see https://github.com/mash/fetch-ndjson/blob/master/src/index.ts - inspirace
 * @see https://caniuse.com/mdn-api_response_body
 *
 * @param response
 * @returns {AsyncGenerator<unknown[]|any, void, *>}
 */
export async function* ndjson(response) {
	const reader = await response.body.getReader();
	const decoder = new TextDecoder("utf8");
	let buffer = "";

	while (true) {
		const { done, value } = await reader.read();

		if (done) {
			if (buffer.length > 0) {
				yield JSON.parse(buffer);
			}

			return; // hotovo
		}

		const chunk = decoder.decode(value, { stream: true });
		// debug(`chunk=${chunk}`);
		buffer += chunk;

		const parts = buffer.split(/\r?\n+/);

		// do bufferu uložíme první část, který může být nevalidní nekompletní JSON
		buffer = parts.pop();

		yield* parts.map(JSON.parse);
	}
}
