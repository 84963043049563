/**
 * Okopíruje text do schránky
 * @param text
 */
export function copy(text) {
	const textarea = document.createElement("textarea");
	textarea.value = text || event.currentTarget.textContent;
	document.body.append(textarea);
	textarea.select();
	document.execCommand("copy");
	textarea.remove();
}

window.copy = copy; // global function
