export const timelineOptions = {
	groupOrder(a, b) {
		return a.name - b.name;
	},

	zoomMin: 60_000,
	zoomMax: 2_629_800_000,

	showCurrentTime: false,
	showWeekScale: true,
	orientation: "both",
	cluster: true,

	tooltip: {
		delay: 50,
		followMouse: true,
		template(item) {
			if (item.isCluster) {
				return "Double click to zoom...";
			}

			return "Click to open...";
		},
	},

	groupTemplate(group) {
		return `<a href="#/test/${group.id}" title="Open check">${group.content}</a>`;
	},
};
