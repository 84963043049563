import md5 from "md5";
import { getInputLabel } from "./get-input-label.js";

/**
 * Serializuje data HTML formuláře
 * @param form
 * @returns {string}
 */
export function htmlFormDataSerialize(form) {
	const data = new FormData(form);
	return new URLSearchParams(data).toString();
}

/**
 * Return new DocumentFragment
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLTemplateElement
 * @see https://developer.mozilla.org/en-US/docs/Web/API/DocumentFragment
 *
 * @param html
 * @returns DocumentFragment
 */
function getDocumentFragment(html) {
	const inputHtml = document.createElement("template");
	inputHtml.innerHTML = html.trim();
	return inputHtml.content;
}

/**
 * Normalize HTML form to Twitter Bootstrap form
 * @param html
 * @param parameters
 * @returns {string}
 */
export function htmlFormNormalizer(html, parameters = "") {
	// Parse form params
	const formParameters = new URLSearchParams(parameters);

	// Input
	const inputDocument = getDocumentFragment(html);

	// Output
	const outputDocument = getDocumentFragment("<div></div>");
	const output = outputDocument.firstChild;

	for (const input of inputDocument.querySelectorAll(
		"input,textarea,button,select",
	)) {
		if (input.disabled) {
			continue;
		} // Skip disabled inputs

		// create unique ID
		const id = md5(input.outerHTML + Math.random().toString(36).slice(-5));
		const inputLabel = getInputLabel(inputDocument, input); // Get label

		// input box

		if (
			input.nodeName === "INPUT" &&
			input.name &&
			!["file", "password", "radio", "checkbox"].includes(input.type)
		) {
			output.insertAdjacentHTML(
				"beforeend",
				`
				<div class="mb-3">
					<label for="${id}">${inputLabel}</label>
					<input 
						id="${id}"
						type="${["hidden", "submit", "button", "range", "image"].includes(input.type) ? "text" : input.type}" 
						name="${input.name}" 
						autocomplete="off" 
						value="${formParameters.has(input.name) ? formParameters.get(input.name) : input.value}"
            placeholder="Please fill in the input named ${input.name}"/>
            <span class="mt-1 text-sm text-gray-500">
              &lt;${input.nodeName.toLowerCase()} type="${input.type}" name="${input.name}"${input.value ? ` value="${input.value}"` : ""}&gt;
            </span>
				</div>
			`,
			);
		}

		// Input type=password

		if (input.nodeName === "INPUT" && input.type === "password") {
			output.insertAdjacentHTML(
				"beforeend",
				`
			<div class="mb-3">
				<label for="${id}">${inputLabel}</label>
				<div class="inline-flex justify-center w-full">				
					<input 
						id="${id}"
						type="password"
						autocompletetype="password"
						x-autocompletetype="password" 
						name="${input.name}" 
						class="rounded-r-none"
						value="${formParameters.has(input.name) ? formParameters.get(input.name) : input.value}"
	          placeholder="Please fill password to input ${input.name}"
	        />
					<label class="cursor-pointer shadow-sm px-3 border-y border-r m-0 pt-2 rounded-r-md hover:bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
						<input type="checkbox" tabIndex="3" onClick="(function(el) {
											el.parentNode.previousElementSibling.type =  el.checked ? 'text' : 'password';
											el.parentNode.lastElementChild.innerHTML = el.checked ? 'visibility_off' : 'visibility';
										})(this)" style="display: none">
						<i class="material-icons">visibility</i>
					</label>
				</div>				
				<span class="mt-1 text-sm text-gray-500">
					&lt;${input.nodeName.toLowerCase()} type="${input.type}" name="${input.name}"&gt;
				</span>
			</div>
			`,
			);
		}

		// Button name= value=

		if (input.nodeName === "BUTTON" && input.name && input.type !== "reset") {
			output.insertAdjacentHTML(
				"beforeend",
				`
				<div class="mb-3">
					<label for="${id}">${input.textContent ? input.textContent.trim() : input.name}</label>
					<input 
					  id="${id}"
					  type="text"
					  name="${input.name}"
					  value="${formParameters.has(input.name) ? formParameters.get(input.name) : input.value}"
					  placeholder="Please fill the button or leave it empty"
					 >
					 <span class="mt-1 text-sm text-gray-500">
						 &lt;${input.nodeName.toLowerCase()} type="${input.type}" name="${input.name}"&gt;
					 </span>
				</div>
			`,
			);
		}

		// Input type=radio

		if (input.nodeName === "INPUT" && input.name && input.type === "radio") {
			if (formParameters.has(input.name)) {
				if (formParameters.getAll(input.name).includes(input.value)) {
					input.setAttribute("checked", true);
				} else {
					input.removeAttribute("checked");
				}
			}

			output.insertAdjacentHTML(
				"beforeend",
				`<div class="radio">
				<label for="${id}">
					<input
						id="${id}"
						type="radio"
						name="${input.name}"
						value="${input.value}"
						${input.checked ? ' checked="checked"' : ""}
				> ${inputLabel}
				</label>
				<span class="mt-1 text-sm text-gray-500">
					&lt;${input.nodeName.toLowerCase()} type="${input.type}" name="${input.name}" value="${input.value}"&gt;
				</span>
			</div>`,
			);
		}

		// Input type=checkbox

		if (input.nodeName === "INPUT" && input.name && input.type === "checkbox") {
			if (formParameters.has(input.name)) {
				if (formParameters.getAll(input.name).includes(input.value)) {
					input.setAttribute("checked", true);
				} else {
					input.removeAttribute("checked");
				}
			}

			output.insertAdjacentHTML(
				"beforeend",
				`<div class="checkbox">
				<label>
					<input
						id="${id}"
						type="checkbox"
						name="${input.name}"
						value="${input.value}"
						${input.checked ? ' checked="checked"' : ""}
					> ${inputLabel}
				</label>
				<span class="mt-1 text-sm text-gray-500">
					&lt;${input.nodeName.toLowerCase()} type="${input.type}" name="${input.name}" value="${input.value}"&gt;
				</span>
			</div>`,
			);
		}

		// Select, select multi

		if (input.nodeName === "SELECT" && input.name) {
			// Select/deselect selected :)
			if (formParameters.has(input.name)) {
				for (const option of input.options) {
					if (formParameters.getAll(input.name).includes(option.value)) {
						option.setAttribute("selected", true);
					} else {
						option.removeAttribute("selected");
					}
				}
			}

			output.insertAdjacentHTML(
				"beforeend",
				`
			<div class="mb-3">
				<label for="${id}">${inputLabel}</label>
				<select 
					id="${id}"
					name="${input.name}"
					${input.size > 0 ? ` size=${input.size}` : ""}
					${input.multiple ? " multiple" : ""}
					>
					${input.innerHTML}
				</select>
				 <span class="mt-1 text-sm text-gray-500">
				  &lt;${input.nodeName.toLowerCase()} name="${input.name}"${input.multiple ? " multiple" : ""}&gt;
				 </span>
			</div>`,
			);
		}

		// Textarea

		if (input.nodeName === "TEXTAREA" && input.name) {
			output.insertAdjacentHTML(
				"beforeend",
				`
			<div class="mb-3">
				<label for="${id}">${inputLabel}</label>
				<textarea
					id="${id}" 
					rows="3"
					name="${input.name}"
					placeholder="Please fill in the textarea named ${input.name}"					
				>${formParameters.has(input.name) ? formParameters.get(input.name) : input.value}</textarea>
				<span class="mt-1 text-sm text-gray-500">
					&lt;${input.nodeName.toLowerCase()} name="${input.name}"&gt;
				</span>
			</div>`,
			);
		}
	}

	return output.innerHTML.trim();
}
