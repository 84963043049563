import { api } from "./api";

export interface Project {
	name: string;
	url: string;
	title: string;
	created: string;
	status: string | null;
	period: string;
	timeout: number;
	delay: number;
	sslAlertPeriod: number;
	sslExpiration: string | null;
	checkDefaultErrors: boolean;
	uptimeEnabled: number;
	uptimeUrl: string;
	userAgent: string | null;
	userIds: string[];
	payerId: string;
	location: string;
	id: string;
	sslStatus: string;
}

export const Project = {
	get(id: string): Promise<Project> {
		return api.get(`project/${id}`).json();
	},

	create(url: string, addPresetChecks: boolean): Promise<Project> {
		return api
			.post("project/create", {
				json: { url, addPresetChecks },
				timeout: false,
			})
			.json();
	},
};
