export function ttUniqueEmail($http, $q) {
	return {
		require: "ngModel",
		link(scope, elm, attributes, ctrl) {
			scope.$watch(attributes.ngModel, () => {
				if (scope.canceller) {
					scope.canceller.resolve();
				}

				scope.canceller = $q.defer();
				$http({
					method: "POST",
					url: "/api/user/validate/email",
					data: { email: elm.val() },
					timeout: scope.canceller.promise,
				}).then(
					(response) => {
						ctrl.$setValidity("isTaken", !response.data.taken); // Is taken???
					},
					() => {
						ctrl.$setValidity("isTaken", false);
					},
				);
			});
		},
	};
}

ttUniqueEmail.$inject = ["$http", "$q"];
