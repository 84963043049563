export function Check($resource) {
	return $resource(
		"/api/form-check/:action",
		{},
		{
			searchHtmlForms: {
				method: "POST",
				params: { action: "search-html-forms" },
				isArray: true,
			},
			createFormTest: {
				method: "POST",
				params: { action: "create-form-test" },
			},
		},
	);
}

Check.$inject = ["$resource"];
