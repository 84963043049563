<template>
	<form method="post" @submit.prevent="onSubmit()">
		<div class="tabs">
			<ul>
				<li :class="{active: add === SINGLE_URL}">
					<label>
						<input v-model="add" :value="SINGLE_URL" hidden type="radio"> Single website
					</label>
				</li>
				<li :class="{active: add === MULTIPLE_URLS}">
					<label>
						<input v-model="add" :value="MULTIPLE_URLS" hidden type="radio"> Multiple websites
					</label>
				</li>
			</ul>
		</div>

		<div v-if="add === SINGLE_URL">
			<label for="url">To create a new project, enter the URL of your website's home page</label>
			<UrlInput id="url" v-model="url" autofocus class="mb-3" name="url" placeholder="e.g. https://testomato.com" required/>
		</div>

		<ListResults v-if="results.length" v-model:loading="loading" v-model:results="results"/>

		<template v-else>
			<div v-if="add === MULTIPLE_URLS">
				<label for="urls">Enter the URLs of your website's home page - <strong>one URL</strong> per line will be <strong>one project</strong>.</label>
				<UrlTextarea id="urls" v-model="urls" :max-urls="30" :placeholder="'e.g. https://testomato.com\nhttps://example.com'" autofocus cols="100" name="urls" required rows="5"/>
			</div>

			<div class="mb-3">
				<label for="addPresetChecks">
					<input id="addPresetChecks" v-model="addPresetChecks" class="mr-2" name="addPresetChecks" type="checkbox">
					Add preset checks to the project{{ add === MULTIPLE_URLS ? 's' : '' }}
				</label>
			</div>

			<div class="inline-flex flex-col">
				<button class="btn" type="submit">
					<IconLoading v-if="loading"/>
					<SaveIcon v-else/>
					Create new project{{ add === MULTIPLE_URLS ? 's' : '' }}
				</button>
			</div>
		</template>
	</form>
</template>

<script lang="ts" setup>
import { inject, ref } from "vue";
import { Project } from "../../api/api";
import IconLoading from "../icons/icon-loading.vue";
import SaveIcon from "../icons/save-icon.vue";
import type { Toast } from "../toast";
import UrlInput from "../url-input.vue";
import UrlTextarea from "../url-textarea.vue";
import ListResults from "./list-results.vue";

const toast = <Toast>inject("toast");

const SINGLE_URL = "single url";
const MULTIPLE_URLS = "multiple urls";

const loading = ref(false);
const add = ref(SINGLE_URL); // single or multiple input
const url = ref(""); // single URL
const results = ref([]); // multiple projects
const urls = ref(""); // multiple URLs
const addPresetChecks = ref(true); // add preset checks to a project

async function onSubmit() {
	loading.value = true;

	// single url
	if (add.value === SINGLE_URL) {
		try {
			const project = await Project.create(
				url.value,
				addPresetChecks.value as boolean,
			);
			window.location.href = `/project/${project.id}#/tests`;
			return;
		} catch (error) {
			toast.error(error?.message);
		}
	}

	// multiple urls
	if (add.value === MULTIPLE_URLS) {
		const requests = [];
		results.value = [];

		for (const url of urls.value.split("\n").filter(Boolean)) {
			requests.push(Project.create(url, addPresetChecks.value as boolean));
		}

		requests.map((request) => {
			request
				.then((project: Project) => {
					results.value.push({ status: "fulfilled", value: project });
				})
				.catch((error: Error) => {
					results.value.push({ status: "rejected", reason: error });
				});
		});

		await Promise.allSettled(requests);
		urls.value = "";
	}

	loading.value = false;
}
</script>
