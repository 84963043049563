export const groupRules = {
	bindings: {
		status: "<?",
		groupRulesCount: "=?",
		hasAnyFailing: "=?",
	},

	template: `
	<table ng-if="rules.length > 0" class="table m-0">
		<tr ng-repeat="rule in rules" ng-class="statuses[$index].status">
			<th class="w-1 align-top text-right ok:bg-lime-100 dark:ok:bg-lime-900 ok:border-lime-300 dark:ok:border-lime-800 warning:bg-yellow-100 dark:warning:bg-yellow-600 error:bg-red-100 dark:error:bg-red-900 error:border-red-200 dark:error:border-red-800" ng-switch="rule.type">
				<span class="inline-block pt-1" ng-switch-when="text">Text on Page</span>
				<span class="inline-block pt-1" ng-switch-when="html">HTML on Page</span>
				<span class="inline-block pt-1" ng-switch-when="status">HTTP Status</span>
				<span class="inline-block pt-1" ng-switch-when="header">HTTP Header</span>
				<span class="inline-block pt-1" ng-switch-when="location">Redirect Location</span>
			</th>
			<td class="w-1 align-top ok:bg-lime-100 dark:ok:bg-lime-900 ok:border-lime-300 dark:ok:border-lime-800 warning:bg-yellow-100 dark:warning:bg-yellow-600 error:bg-red-100 dark:error:bg-red-900 error:border-red-200 dark:error:border-red-800" ng-switch="rule.op">
				<span class="inline-block pt-1" ng-switch-when="eq">is equal to</span>
				<span class="inline-block pt-1" ng-switch-when="sub">contains</span>
				<span class="inline-block pt-1" ng-switch-when="!sub">does not contain</span>
				<span class="inline-block pt-1" ng-switch-when="eqs">equals</span>
				<span class="inline-block pt-1" ng-switch-when="match">matches</span>
				<span class="inline-block pt-1" ng-switch-when="!match">does not match</span>
				<span class="inline-block pt-1" ng-switch-when="xpath">matches XPath</span>
			</td>
			<td class="ok:bg-lime-100 dark:ok:bg-lime-900 ok:border-lime-300 dark:ok:border-lime-800 warning:bg-yellow-100 dark:warning:bg-yellow-600 error:bg-red-100 dark:error:bg-red-900 error:border-red-200 dark:error:border-red-800">
				<pre class="ok:bg-lime-300/50 dark:ok:bg-lime-600/50 error:bg-red-200/50 dark:error:bg-red-600/50 rounded-md py-1 px-2"><code>{{rule.val || '~ value not set ~'}}</code></pre>
				<ol ng-if="statuses[$index].failures.length" class="mt-2">
					<li ng-repeat="failure in statuses[$index].failures" class="text-red-900 dark:text-red-100">
						<p class="font-semibold">{{failure.message}}</p>
							<p class="text-sm">{{failure.details}}</p>
					</li>
				</ol>
			</td>
		</tr>
	</table>
	`,

	controller: class GroupRulesController {
		constructor($scope) {
			this.$scope = $scope;
		}

		static get $inject() {
			return ["$scope"];
		}

		$onChanges() {
			if (this.status) {
				this.$scope.rules = this.status.rules.filter(
					(rule) => rule.origin === "group",
				);
				this.groupRulesCount = this.$scope.rules.length;

				this.$scope.statuses = this.$scope.rules.map((rule, order) => {
					const isFailing = this.status.failures
						.filter(
							(failed) =>
								failed.rule.origin === "group" && failed.rule.order === order,
						)
						.slice(0, 1);
					if (isFailing.length > 0) {
						this.hasAnyFailing = true;
					}

					return {
						failures: isFailing,
						status: isFailing.length > 0 ? "error" : "ok",
					};
				});
			}
		}
	},
};
