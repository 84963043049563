export function sortable() {
	const classes = {
		drop: "drop",
		dragged: "dragged",
		ghost: "ghost",
	};

	let draggedParent;
	let flying;
	let fromIndex;
	let dropElement = null;
	let dropIndex = null;
	let dropped = false;
	const selected = {};

	// Select new target
	const select = (element) => {
		if (element.id in selected) {
			return;
		}

		selected[element.id] = element;
		// Set drag element
		dropElement = element;
		// Ser positioning of element
		dropIndex = Array.prototype.indexOf.call(draggedParent.children, element);
	};

	// Clean unselected without triggering event
	const cleanUnselected = () => {
		for (const id in selected) {
			const element = selected[id];
			if (element !== dropElement) {
				element.classList.remove(classes.drop);
				delete selected[id];
			}
		}
	};

	// Swap elements between
	const swapElements = (element1, element2) => {
		const parent = element2.parentNode;
		const next = element2.nextSibling;
		if (next === element1) {
			element2.before(element1);
		} else {
			element1.parentNode.insertBefore(element2, element1);
			if (next) {
				next.before(element1);
			} else {
				parent.append(element1);
			}
		}
	};

	return {
		restrict: "A",
		scope: {
			onSortStart: "&",
			onSortEnd: "&",
		},
		link(scope, element) {
			const dragged = element[0];

			dragged.draggable = true;
			dragged.addEventListener(
				"dragstart",
				(event) => {
					draggedParent = dragged.parentNode;

					flying = dragged; // Save crrently dragged element
					fromIndex = Array.prototype.indexOf.call(
						draggedParent.children,
						dragged,
					); // Get element index

					select(flying);
					dropped = false;

					event.dataTransfer.effectAllowed = "move";
					event.dataTransfer.dropEffect = "copy";

					event.dataTransfer.setData("text/json", event.target.id);

					// Add class ghost to element
					dragged.classList.add(classes.ghost);

					// Change ghost class
					setTimeout(() => {
						// If this action is performed without setTimeout, then
						// the moved object will be of this class.
						dragged.classList.add(classes.dragged);
					}, 0);

					// Callback
					if (scope.onSortStart) {
						scope.onSortStart({ element: flying, fromIndex, event });
					}

					event.stopImmediatePropagation();
				},
				false,
			);

			// Enter drop element
			dragged.addEventListener(
				"dragenter",
				(event) => {
					event.preventDefault();
					let target = event.target;
					while (target.parentNode !== draggedParent && target.parentNode) {
						target = target.parentNode;
					}

					select(target);
					target.classList.add(classes.drop);
				},
				false,
			);

			// Leave drop element
			dragged.addEventListener(
				"dragleave",
				(event) => {
					event.preventDefault();
					let target = event.target;
					while (target.parentNode !== draggedParent && target.parentNode) {
						target = target.parentNode;
					}

					if (dropElement === null || dropElement === target) {
						return;
					}

					dropElement.classList.remove(classes.drop, classes.ghost);
					dropElement = null;
					dropIndex = null;
				},
				false,
			);

			// Moving element to new position
			dragged.addEventListener("dragover", (event) => {
				let target = event.target;
				while (target.parentNode !== draggedParent && target.parentNode) {
					target = target.parentNode;
				}

				if (dropElement && target !== dropElement) {
					dropElement.classList.remove(classes.drop);
				}

				target.classList.add(classes.drop);
				// Set drag element
				select(target);
				cleanUnselected();
				event.preventDefault();
				event.stopPropagation();
			});

			dragged.addEventListener(
				"drop",
				(event) => {
					flying.classList.remove(classes.ghost);
					// Change ghost class
					setTimeout(() => {
						// If this action is performed without setTimeout, then
						// the moved object will be of this class.
						flying.classList.remove(classes.dragged);
					}, 0);
					dropElement.classList.remove(classes.drop);
					swapElements(flying, dropElement);
					dropped = true;
					event.stopPropagation();
					event.preventDefault();
				},
				false,
			);

			dragged.addEventListener(
				"dragend",
				(event) => {
					if (dropped && scope.onSortEnd) {
						scope.onSortEnd({
							element: flying,
							fromIndex,
							toIndex: dropIndex,
							event,
						});
					} else {
						if (dropElement) {
							dropElement.classList.remove(
								classes.ghost,
								classes.dragged,
								classes.drop,
							);
						}

						setTimeout(() => {
							flying.classList.remove(
								classes.ghost,
								classes.dragged,
								classes.drop,
							);
						}, 0);
					}

					dropElement = null;
					dropIndex = null;
					dropped = false;
					event.preventDefault();
					event.stopPropagation();
				},
				false,
			);
		},
	};
}
