import angular from "angular";
import { capitalize } from "./capitalize.filter.js";
import { duration } from "./duration.filter.js";
import { fromNow } from "./from-now.filter.js";
import { gravatar } from "./gravatar.filter.js";
import { hideDomain } from "./hide-domain.filter.js";
import { price } from "./price.filter.js";

export default angular
	.module("testomato.filter", [])
	.filter("gravatar", () => gravatar)
	.filter("capitalize", () => capitalize)
	.filter("hideDomain", () => hideDomain)
	.filter("duration", () => duration)
	.filter("fromNow", () => fromNow)
	.filter("price", () => price);
