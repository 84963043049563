/**
 * @name AddFormCheckController
 * @type AddFormCheckController
 */
export default class AddFormCheckController {
	constructor($scope, $location, Check, area) {
		this.$scope = $scope;
		this.$location = $location;
		this.Check = Check;
		this.area = area;

		$scope.area = area;
		$scope.forms = [];
		$scope.test = { name: "", notice: "" };
		$scope.selectedForm = null;
		$scope.selectedFormData = null;
		$scope.rules = [{ type: "status", op: "eq", val: 200 }];
	}

	static get $inject() {
		return ["$scope", "$location", "Check", "area"];
	}

	searchHtmlForms(url) {
		this.$scope.forms = [];
		this.$scope.formsNotFound = false;

		// search forms
		this.Check.searchHtmlForms(
			{ projectId: this.$scope.project.id, areaId: this.area.id, url },
			(forms) => {
				this.$scope.formsNotFound = forms.length === 0;
				this.$scope.forms = forms;
			},
		);
	}

	selectForm(form) {
		this.$scope.selectedForm = form;
		this.$scope.test.name = form.name;
	}

	createFormTest() {
		this.Check.createFormTest(
			{ projectId: this.$scope.project.id, areaId: this.area.id },
			{
				test: this.$scope.test,
				form: this.$scope.selectedForm,
				params: this.$scope.selectedFormParams,
				rules: this.$scope.rules,
			},
			(response) => {
				if (response.ok) {
					this.$location.path("/tests");
				}
			},
			(error) => {
				console.error(error);
			},
		);
	}
}
