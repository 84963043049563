/**
 * @param $resource
 * @returns {*}
 * @constructor
 */
export function CurrentValue($resource) {
	return $resource(
		"/api/currentValue",
		{},
		{
			get: { method: "GET", params: {} },
		},
	);
}

CurrentValue.$inject = ["$resource"];
