export default class AddCheckController {
	constructor($scope, $q, $location, area, Test, Area) {
		this.$scope = $scope;
		this.$location = $location;
		this.Test = Test;
		this.Area = Area;
		this.$q = $q;

		this.$scope.newCheckUrl = "";
		this.$scope.downloadingNewCheckUrl = false;
		this.$scope.test = {};
		this.$scope.area = area;
	}

	newCheckFromUrl() {
		if (this.$scope.newCheckUrl) {
			this.$scope.downloadingNewCheckUrl = true; // Downloading...

			this.Test.newFromUrl(
				{
					fromUrl: this.$scope.newCheckUrl,
					projectId: this.$scope.project.id,
					areaId: this.$scope.area.id,
				},
				(response) => {
					this.$scope.downloadingNewCheckUrl = false;
					this.$scope.test = response;
				},
				() => {
					this.$scope.downloadingNewCheckUrl = false;
				},
			);
		}
	}

	async saveNewCheck() {
		const promises = [];

		promises.push(
			this.Test.create({ projectId: this.$scope.project.id }, this.$scope.test)
				.$promise,
		);

		const forms = this.$scope.test.forms.filter((form) => form.selected);

		if (forms.length > 0) {
			promises.push(
				this.Test.addMultipleForms(
					{ projectId: this.$scope.project.id, areaId: this.$scope.area.id },
					forms,
				).$promise,
			);
		}

		this.$q.all(promises).then(() => {
			this.$location.path("/tests");
		});
	}

	static get $inject() {
		return ["$scope", "$q", "$location", "area", "Test", "Area"];
	}
}
