export function bytesToSize(bytes) {
	if (bytes === 0) {
		return "0 Byte";
	}

	const i = Number.parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
	return (
		Math.round(bytes / 1024 ** i, 2) + [" Bytes", " KB", " MB", " GB", " TB"][i]
	);
}
