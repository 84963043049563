/**
 * @see https://docs.angularjs.org/api/ng/filter/currency
 * @param {string}
 * @return {string}
 */
export function price(amount, currency) {
	const minimumFractionDigits = Number.isInteger(Number(amount)) ? 0 : 2;
	const price = new Intl.NumberFormat(
		(currency || "USD") === "USD" ? "en-US" : "de-DE",
		{
			style: "currency",
			currency: currency || "USD",
			minimumFractionDigits,
		},
	);
	return price.format(Number(amount));
}
