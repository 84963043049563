<template>
	<IconHome v-if="$attrs.href === '/dashboard'"/>
	<IconChervonRight v-else/>
	<a v-if="$attrs.href" class="hover:underline" v-bind="$attrs">
		<slot/>
	</a>
	<span v-else class="text-gray-500" v-bind="$attrs"><slot/></span>
</template>

<script setup>
import IconChervonRight from "../icons/icon-chervon-right.vue";
import IconHome from "../icons/icon-home.vue";
</script>