/**
 * @param $resource
 * @returns {*}
 * @constructor
 */
export function Ruleset($http, $q) {
	let ruletypes = [];
	const deferred = $q.defer();

	if (ruletypes.length > 0) {
		deferred.resolve(ruletypes);
	} else {
		$http({ method: "GET", url: "/api/ruletypes" }).then(
			(response) => {
				ruletypes = response.data;
				deferred.resolve(ruletypes);
			},
			() => {
				deferred.reject();
			},
		);
	}

	return deferred.promise;
}

Ruleset.$inject = ["$http", "$q"];
