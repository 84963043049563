import { bytesToSize } from "../../functions/bytes-to-size.js";
import { confirmDialog } from "../../functions/confirm.js";
import { startProject } from "../../functions/start-project.js";

const TestController = function TestController(
	$scope,
	$location,
	$routeParameters,
	$timeout,
	Test,
	test,
) {
	$scope.bytesToSize = bytesToSize;

	/**
	 * Editovaný test
	 * @type {Object}
	 */
	$scope.test = test;

	/**
	 * Akce vyvolaná uložením nastavení testu
	 */
	$scope.saveTest = () => {
		Test.update({ testId: $scope.test.id }, $scope.test, () => {
			$location.path("/tests");
		});
	};

	$scope.running = false;

	$scope.saveAndRun = () => {
		if ($scope.running) {
			return;
		}

		Test.update({ testId: $scope.test.id }, $scope.test, async (test) => {
			$scope.test = test;

			// run selected test
			$scope.running = true;
			for await (const data of startProject(
				$scope.project.id,
				$scope.test.areaId,
				[$scope.test.id],
			)) {
				if (data?.testId) {
					$scope.$apply(() => {
						$scope.test.status = data;
					});
				}
			}

			$scope.$apply(() => {
				$scope.running = false;
				$scope.testSettings?.rulesComponentForm.$setPristine();
				$scope.testSettings?.templateRulesComponentForm.$setPristine();
			});
		});
	};

	$scope.removeTest = async () => {
		const isConfirmed = await confirmDialog({
			title: "Delete check?",
			text: "Are you sure you want to delete this check?",
			confirmButtonText: "Yes, delete check!",
		});

		if (isConfirmed) {
			Test.delete({ testId: $scope.test.id }, () => {
				document.location = "#/tests";
			});
		}
	};

	$scope.addNoCacheHeaders = () => {
		const noCacheHeaders = [
			"Cache-Control: no-cache, no-store, must-revalidate",
			"Pragma: no-cache",
			"Expires: 0",
		];
		/** @var Array */
		const currentHeaders = (test.options.w === null ? "" : test.options.w)
			.trim()
			.split("\n");

		test.options.w = currentHeaders.concat(noCacheHeaders).join("\n");
		$scope.normalizeHeaders();
	};

	$scope.normalizeHeaders = () => {
		let httpHeaders = test.options.w.replaceAll(/^\s+|\s+$/gm, "");
		httpHeaders = httpHeaders.trim().split("\n");
		test.options.w = [...new Set(httpHeaders)].join("\n"); // Remove duplicities
	};
};

TestController.$inject = [
	"$scope",
	"$location",
	"$routeParams",
	"$timeout",
	"Test",
	"test",
];

export default TestController;
