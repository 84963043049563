export const combobox = {
	bindings: {
		id: "@?",
		options: "<?",
		placeholder: "<?",
		onChange: "&?",
		required: "<?",
		value: "=",
	},

	template: `
	<div
		class="relative"
		ng-init="open = false"	
		ng-keydown="$event.keyCode === 27 ? open = false : null"				
		ng-keyup="$event.keyCode === 27 ? open = false : null"
		ng-keypress="$event.keyCode === 27 ? open = false : null">
		<div class="inline-flex justify-center w-full">
			<input
				type="text"
				placeholder="{{$ctrl.placeholder}}"
				class="rounded-r-none z-10 ok:bg-lime-100 dark:ok:bg-lime-900/50 warning:bg-yellow-100 dark:warning:bg-yellow-700/50 error:bg-red-100 dark:error:bg-red-900" 
				autocomplete="off"											
				ng-model="$ctrl.value"
				ng-model-options="{debounce:500}"
				ng-change="$ctrl.onValueChange()"
				ng-required="$ctrl.required"
			/>
			<button 
					type="button" 
					class="rounded-l-none border-l-0 focus:ring-0"
					ng-click="open = !open"
				>
				<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
			</button>
		</div>
		<ul 
			ng-class="{hidden: !open}"
			class="hidden absolute z-50 text-base bg-white rounded divide-y divide-gray-200 md:drop-shadow-md dark:bg-gray-700 dark:divide-gray-600 focus:outline-none w-full">
			<li ng-repeat="option in $ctrl.options">
				<a class="block rounded-t py-2 px-3 text-sm text-gray-800 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" 
				ng-click="$ctrl.selectValue(option.value); $ctrl.onValueChange()">
				<span ng-if="!option.description">{{option.label}}</span>
				<strong ng-if="option.description">{{option.label}}</strong>
				<div class="small" ng-if="option.description">{{option.description}}</div>
				</a>
			</li>
		</ul>
	</div>
	`,
	controller: class ComboboxController {
		constructor($scope, $element, $timeout) {
			this.$scope = $scope;
			this.input = $element.find('input[type="text"]');
			this.$timeout = $timeout;
		}

		static get $inject() {
			return ["$scope", "$element", "$timeout"];
		}

		onValueChange() {
			if (this.onChange !== undefined) {
				this.$timeout(() => {
					this.onChange();
				});
			}
		}

		selectValue(value) {
			this.value = value;
			this.$scope.open = false;
		}
	},
};
