import { createRouter, createWebHistory } from "vue-router";
import routes from "./vue.routes.js";

// Create the router
const router = createRouter({
	history: createWebHistory(),
	routes: routes.map((route) => {
		route.path = `/app/${route.path}`; // Add /app/ prefix to all routes
		return route;
	}),
});

// Set the title of the page based on the route
router.beforeEach((to, from, next) => {
	document.title = `${to.meta.title} / Testomato`;
	next();
});

export default router;
