import { getCurrentProjectId } from "../functions/get-current-project-id.js";

const areasResolve = ($q, Project) => {
	const deferred = $q.defer();
	Project.areas(
		{ projectId: getCurrentProjectId() },
		(areas) => {
			deferred.resolve(areas);
		},
		(error) => {
			deferred.reject(error);
		},
	);

	return deferred.promise;
};

areasResolve.$inject = ["$q", "Project"];

export { areasResolve };
