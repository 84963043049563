import ProjectCreate from "./pages/project/create.vue";

const routes = [
	{
		path: "/project/create",
		name: "project-create",
		component: ProjectCreate,
		meta: { title: "Add website" },
	},
];

export default routes;
