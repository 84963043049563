import { uptimeChart } from "../charts/uptime-chart.js";

export const chartUptime = {
	bindings: {
		data: "=",
	},

	template: "<div></div>",

	controller: class UptimeChartController {
		constructor($scope, $element) {
			this.$scope = $scope;
			this.$element = $element;

			$scope.$watch(
				() => this.data,
				() => {
					if (this.data) {
						uptimeChart(this.$element[0], this.data.uptime);
					}
				},
			);
		}

		static get $inject() {
			return ["$scope", "$element"];
		}
	},
};
