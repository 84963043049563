import { type VNode, h, render } from "vue";
import type { Toast } from "./index";
import { type Notification, defaultOptions } from "./notification";
import Toasts from "./toasts.vue";

export default function useToast(globalOptions: Notification = {}): Toast {
	// render toast to document.body
	const toasts: VNode = h(Toasts);
	render(toasts, document.body);

	return {
		show(message: string, options: Notification = {}): void {
			const _options: Notification = {
				...defaultOptions,
				...globalOptions,
				message,
				...options,
			};

			toasts?.component.exposed.show(_options);
		},

		info(message: string, notification: Notification = {}): void {
			notification.type = "info";
			this.show(message, notification);
		},

		success(message: string, notification: Notification = {}): void {
			notification.type = "success";
			this.show(message, notification);
		},

		error(message: string, notification: Notification = {}): void {
			notification.type = "error";
			this.show(message, notification);
		},

		warning(message: string, notification: Notification = {}): void {
			notification.type = "warning";
			this.show(message, notification);
		},

		clean(): void {
			toasts.component.exposed.clean();
		},
	};
}
