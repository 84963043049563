import { Duration } from "luxon";

/**
 * Doba trvání lidsky čitelná v sekundách
 * @param {number} sekundy
 * @return {string}
 */
export function fromNow(s) {
	if (typeof s === "number") {
		const { days, hours, minutes, seconds } = Duration.fromObject({
			seconds: s,
		})
			.shiftTo("days", "hours", "minutes", "seconds")
			.toObject();
		if (days > 2) {
			return `in ${days} days ${hours} hours`;
		}

		if (!days && hours) {
			return `in ${hours} hours ${minutes} minutes`;
		}

		if (!days && !hours && minutes) {
			return `in ${minutes} minutes ${seconds} second`;
		}

		if (!days && !hours && !minutes) {
			return `in ${seconds} seconds`;
		}
	}
}
