import { createPopper } from "@popperjs/core";

/**
 * Jednoduchý D3 tooltip
 */
export class SimpleTooltip {
	constructor(chartBody) {
		this.tooltip = chartBody
			.append("div")
			.style("visibility", "hidden")
			.attr(
				"class",
				`
					absolute opacity-0 font-semibold rounded-md text-center text-xs bg-black 
					text-white dark:bg-white dark:text-black
					py-2 px-3 transition-opacity pointer-events-none
					w-max min-w-fit whitespace-pre-wrap -translate-x-1/2	 
					
					after:content-[''] after:block after:absolute
					after:left-1/2 after:-translate-x-1/2 after:top-full
					after:border-8 after:border-x-transparent after:border-b-transparent
					after:border-t-black dark:after:border-t-white
					after:transition-opacity after:pointer-events-none
			`,
			)
			.text("Tooltip");

		this.popper = createPopper(document.documentElement, this.tooltip.node(), {
			placement: "top",
			modifiers: [{ name: "offset", options: { offset: [0, 5] } }],
		});
	}

	/**
	 * Nastaví element u kterého tooltip zobrazíme
	 * @param {Element} element
	 * @return {SimpleTooltip}
	 */
	setElement(element) {
		this.popper.state.elements.reference = element;
		this.popper.update();
		return this;
	}

	/**
	 * Nastaví text tooltipu
	 * @param {string} text
	 * @return {SimpleTooltip}
	 */
	setText(text) {
		this.tooltip.text(text);
		return this;
	}

	/**
	 * Zobrazí tooltip
	 * @return {SimpleTooltip}
	 */
	show() {
		this.tooltip
			.transition()
			.duration(100)
			.style("opacity", 1)
			.style("visibility", "visible");
		return this;
	}

	/**
	 * Skryje tooltip
	 * @return {SimpleTooltip}
	 */
	hide() {
		this.tooltip
			.transition()
			.duration(200)
			.style("opacity", 0)
			.style("visibility", "hidden");
		return this;
	}
}
