import angular from "angular";
import "angular-resource";
import { Google } from "./google.service.js";
import { Ruleset } from "./ruleset.service.js";

angular
	.module("testomato.services", ["ngResource"])
	.service("Google", Google)
	.service("Ruleset", Ruleset)
	.factory("Ruleset", Ruleset);
