import { ndjson } from "./ndjson.js";

/**
 * Funkce startuje projekt a dokáže počkat na výsledky
 *
 * waitForResults = true - funkce čeká na výsledky a posuptně je vrací jako objekty
 * waitForResults = false - na výsledky se nečeká a vrací se objekt s odkazem na výsledky
 *
 * POZOR: API /start/area vrací objekty různého typu
 *
 * Pokud bude potřeba kratší timeout: https://stackoverflow.com/a/50101022/355316
 *
 * @param projectId
 * @param areaId
 * @param tests
 * @param waitForResults
 * @returns {AsyncGenerator<any, void, *>}
 */
export async function* startProject(
	projectId,
	areaId,
	tests,
	waitForResults = true,
) {
	const parameters = new URLSearchParams();

	// čekáme na výsledky
	parameters.append("keep-alive", waitForResults ? 1 : 0);

	// startujeme vybrané testy
	if (tests.length > 0) {
		parameters.append("tests", tests);
	}

	// request poslal uživatel
	parameters.append("web", 1);

	const response = await fetch(
		`/api/project/${projectId}/start/area/${areaId}?${parameters}`,
	);

	if (waitForResults) {
		yield* ndjson(response);
	} else {
		yield JSON.parse(await response.text());
	}
}
