export const getScript = (url, async = true, defer = true) =>
	new Promise((resolve, reject) => {
		let script = document.createElement("script");

		script.setAttribute("src", url);
		script.setAttribute("async", async);
		script.setAttribute("defer", defer);
		script.addEventListener("error", reject);

		script.addEventListener(
			"load",
			// biome-ignore lint/suspicious/noAssignInExpressions: this is a one-time event
			(script.onreadystatechange = () => {
				if (!script.readyState || /loaded|complete/.test(script.readyState)) {
					script.removeEventListener("load", script.load);
					script.onreadystatechange = null;
					script = undefined;
					resolve();
				}
			}),
		);

		document.head.append(script);
	});
