import * as d3 from "d3";
import { ResponseTimeTooltip } from "./response-time-tooltip.js";

/**
 * @typedef ResponseTimeRecord
 * @property {Date} responseDate
 * @property {number} dnsLookupTime
 * @property {number} initialConnectionTime
 * @property {number} sslTime
 * @property {number} requestTime
 * @property {number} waitingTime
 * @property {number} contentDownloadTime
 * @property {number} totalResponseTime
 */

/**
 * Vykreslí response timing chart
 * @see https://devdocs.io/d3/
 */
export class ResponseTimeChart {
	/**
	 * Vykreslí response time chart pomocí D3 knihovny
	 *
	 * @param {Element} element
	 * @param {ResponseTimeRecord[]} data
	 */
	constructor(element, data) {
		element.innerHTML = "";

		this.data = data.map((record) => ({
			...record,
			responseDate: new Date(record.responseDate),
		}));
		this.chartBody = d3
			.select(element)
			.append("figure")
			.attr("class", "relative");
		this.median = d3.median(this.data, (d) => d.totalResponseTime);
		this.width = this.chartBody.node().getBoundingClientRect().width;
		this.height = Math.max(
			this.chartBody.node().getBoundingClientRect().height,
			250,
		);
		this.maxResponseTime = d3.max(
			this.data,
			(value) => value.totalResponseTime,
		);
		this.spaceAbove = (this.maxResponseTime / 100) * 10; // 10% přídavek nad maximální hodnotu
		this.margin = {
			top: 10,
			right: 20,
			bottom: 25,
			left: 40,
		};
		this.curve = d3.curveBumpX; // @see https://github.com/d3/d3-shape

		// X scale
		this.x = d3
			.scaleTime()
			.domain(d3.extent(this.data, (record) => record.responseDate))
			.range([this.margin.left, this.width - this.margin.right]);

		// Y scale
		this.y = d3
			.scaleLinear()
			.range([this.height - this.margin.bottom, this.margin.top])
			.domain([0, this.maxResponseTime + this.spaceAbove]);

		this.svg = this.chartBody
			.append("svg")
			.attr("width", this.width)
			.attr("height", this.height)
			.attr("viewBox", [0, 0, this.width, this.height])
			.attr("preserveAspectRatio", "xMinYMin meet")
			.attr("xmlns", "http://www.w3.org/2000/svg")
			.attr("style", "max-width: 100%; height: auto; height: intrinsic;");

		this.addHorizontalGuidlines();
		this.addVerticalGuidlines();

		this.addAreaChart();
		this.addChartAxes();
		this.addLineChart();
		this.addDataPoints();
	}

	addMedianLine() {
		this.svg
			.append("line")
			.attr("class", "stroke-red-500")
			.attr("x1", this.margin.left)
			.attr("y1", this.y(this.median))
			.attr("x2", this.width - this.margin.right)
			.attr("y2", this.y(this.median))
			.attr("stroke-width", 5)
			.attr("stroke-opacity", 0.5);
	}

	addChartAxes() {
		this.yAxisLeft = d3
			.axisLeft(this.y)
			.tickFormat((time) => (time < 1000 ? time : `${time / 1000} s`));

		this.svg
			.append("g")
			.attr("class", "text-gray-800 dark:stroke-gray-400 dark:text-gray-700")
			.attr("transform", `translate(${this.margin.left}, 0)`)
			.call(this.yAxisLeft);

		this.xAxisBottom = d3.axisBottom(this.x);

		this.xAxisG = this.svg
			.append("g")
			.attr("class", "text-gray-800 dark:stroke-gray-400 dark:text-gray-700")
			.attr("transform", `translate(0,${this.height - this.margin.bottom})`)
			.call(this.xAxisBottom);
	}

	addAreaChart() {
		const median = d3.median(this.data, (d) => d.totalResponseTime);
		const threshold = (median * 100) / this.maxResponseTime;

		// Přidat median do grafu
		this.chartBody
			.append("div")
			.attr(
				"class",
				`absolute top-0 right-0 m-2
				font-semibold text-sm bg-opacity-50 dark:bg-opacity-50
				rounded bg-gray-100 dark:bg-gray-700 px-2 py-1
			`,
			)
			.text(`Median ${Math.floor(median)} ms`);

		const colors = [
			{ offset: "0%", color: "rgba(132,204,22,0)" },
			{ offset: `${threshold - 1}%`, color: "#84cc16" },
			{ offset: `${threshold + 1}%`, color: "#ef4444" },
			{ offset: "100%", color: "#ef4444" },
		];

		const area = d3
			.area()
			.x((record) => this.x(record.responseDate))
			.y0(this.height - this.margin.bottom)
			.y1((record) => this.y(record.totalResponseTime))
			.curve(this.curve);

		this.svg
			.append("linearGradient")
			.attr("id", "area-gradient")
			.attr("gradientUnits", "userSpaceOnUse")
			.attr("x1", 0)
			.attr("y1", this.y(0))
			.attr("x2", 0)
			.attr("y2", this.y(this.maxResponseTime))
			.selectAll("stop")
			.data(colors)
			.join("stop")
			.attr("offset", (d) => d.offset)
			.attr("stop-color", (d) => d.color);

		this.svg
			.append("path")
			.datum(this.data)
			.attr("fill", "url(#area-gradient)")
			.attr("fill-opacity", 0.5)
			.attr("stroke", "none")
			.attr("d", area);
	}

	addLineChart() {
		this.line = d3
			.line()
			.x((record) => this.x(record.responseDate))
			.y((record) => this.y(record.totalResponseTime))
			.curve(this.curve);

		this.svg
			.append("path")
			.datum(this.data)
			.attr("class", "stroke-lime-500 dark:stroke-lime-600")
			.attr("fill", "none")
			.attr("stroke-width", 2)
			.attr("stroke-linejoin", "round")
			.attr("stroke-linecap", "round")
			.attr("d", this.line)
			.raise();
	}

	addDataPoints() {
		const responseTimeTooltip = new ResponseTimeTooltip(this.chartBody);

		this.svg
			.selectAll("dots")
			.data(this.data)
			.enter()
			.append("circle")
			.attr(
				"class",
				"opacity-0 fill-white dark:fill-white stroke-red-500 dark:stroke-red-600",
			)
			.attr("stroke-width", 1.5)
			.attr("cx", (record) => this.x(record.responseDate))
			.attr("cy", (record) => this.y(record.totalResponseTime))
			.attr("r", 5)
			.on("mouseover", (event) => {
				const dot = d3.select(event.target);
				/** @type {ResponseTimeRecord} */
				const record = dot.data().pop();

				// zobrazí bod
				dot.style("opacity", 1);

				// zobrazí tooltip
				responseTimeTooltip
					.setElement(event.toElement)
					.updateContent(record)
					.show();
			})
			.on("mouseout", (event) => {
				// skryje bod
				d3.select(event.target).style("opacity", 0);

				responseTimeTooltip.hide();
			});
	}

	addVerticalGuidlines() {
		const verticalGuidlines = d3
			.axisTop()
			.tickFormat("")
			.tickSize(-this.height + this.margin.top + this.margin.bottom)
			.tickSizeOuter(0)
			.scale(this.x);

		this.svg
			.append("g")
			.attr("fill", "none")
			.attr("class", "text-gray-300 dark:text-gray-700")
			.attr(
				"transform",
				`
				translate(0 ${this.margin.top})`,
			)
			.call(verticalGuidlines)
			.call((g) => g.select(".domain").remove())
			.lower();
	}

	addHorizontalGuidlines() {
		const tickValues = [
			100, 250, 500, 1000, 2000, 3000, 4000, 5000, 7000, 10_000,
		].filter((value) => value <= this.maxResponseTime + this.spaceAbove);

		const horizontalGuidlines = d3
			.axisRight()
			.tickFormat("")
			.tickValues(tickValues)
			.tickSize(this.width - this.margin.left - this.margin.right)
			.tickSizeOuter(0)
			.scale(this.y);

		this.svg
			.append("g")
			.attr("fill", "none")
			.attr("class", "text-gray-300 dark:text-gray-700")
			.attr(
				"transform",
				`
				translate(${this.margin.left}, 0)`,
			)
			.call(horizontalGuidlines)
			.call((g) => g.select(".domain").remove())
			.lower();
	}
}
