/**
 * Automatically add protocol to the possible URL
 *
 * @param string
 * @returns {string}
 */
export function addProtocol(string) {
	let url = string
		.trim() // remove spaces
		.replace(/^\W+/, "") // sanizize url beginning
		.replace(/^(?!https?:\/\/)[^:]+:\/\//, "https://"); // always https:// or http://

	// 1. Try to parse the URL for the first time
	try {
		const hostname = new URL().hostname;

		if (!hostname) {
			throw new Error("Missing hostname");
		}
	} catch {
		// 2. Try to parse the URL for the second time
		if (
			!/^(?:h$|ht$|htt$|http$|https?$|https?:\/?\/?)/i.test(url) &&
			URL.canParse(`https://${url}`)
		) {
			url = `https://${url}`;
		}
	}

	// 3. Return the original string
	return url;
}
