<template>
	<ul class="my-3 flex flex-col gap-2">
		<li v-for="(result, index) in results" :key="index">
			<div v-if="result.status === 'fulfilled'" class="flex items-center gap-1.5 text-green-800">
				<IconCheck class="bg-green-500 text-white rounded-full p-0.5"/>
				<a :href="`/project/${result.value.id}#/tests`" class="hover:underline">{{ result.value.title }}</a>
			</div>
			<div v-if="result.status === 'rejected'" class="flex items-center gap-1.5 text-red-800">
				<IconClose class="bg-red-500 text-white rounded-full p-0.5"/>
				<span v-html="result.reason?.message"></span>
			</div>
		</li>
	</ul>
	<div class="flex gap-1">
		<button class="btn btn-primary" type="button" @click="results = []">
			<IconChevronLeft/>
			Go back
		</button>
		<a class="btn" href="/dashboard">
			<IconLoading v-if="loading"/>
			<IconHome v-else/>
			Dashboard
		</a>
	</div>
</template>

<script lang="ts" setup>
import type { PropType } from "vue";
import type { Project } from "../../api/project";
import IconCheck from "../icons/icon-check.vue";
import IconChevronLeft from "../icons/icon-chevron-left.vue";
import IconClose from "../icons/icon-close.vue";
import IconHome from "../icons/icon-home.vue";
import IconLoading from "../icons/icon-loading.vue";

const results = defineModel("results", {
	type: Array as PropType<PromiseSettledResult<Project>[]>,
	required: true,
});
const loading = defineModel("loading", { type: Boolean, required: true });
</script>