export const billingAddress = {
	bindings: {
		billingAddress: "=",
		country: "=?",
	},

	template: `
	<fieldset class="panel">
		<h5 class="panel-header">
			<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-5">
				<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
			</svg>
			Billing Address
		</h5>
		<div class="panel-body">
			<div class="grid lg:grid-cols-3 gap-3">
				<div class="mb-3">
					<label for="firstName" class="required">First name</label>
					<input
						id="firstName"
						name="firstName"
						maxlength="80"
						type="text"
						placeholder="First name"
						required="required"
						ng-model="$ctrl.billingAddress.firstName"
					/>
				</div>
				<div class="mb-3">
					<label for="name" class="required">Last name</label>
					<input
						id="lastName"
						name="lastName"
						maxlength="80"
						type="text"
						placeholder="Last name"
						required="required"
						ng-model="$ctrl.billingAddress.lastName"
					/>
				</div>
				<div class="mb-3">
					<label for="phone" class="optional">Phone number</label>
					<input
						id="phone"
						name="phone"
						type="tel"
						maxlength="128"
						placeholder="555-2368"
						ng-model="$ctrl.billingAddress.phone"
						autocomplete="tel"
					/>
				</div>
			</div>
	
			<div class="grid lg:grid-cols-4 gap-3">
				<div class="mb-3 lg:col-span-2">
					<label for="street" class="required">Street address</label>
					<input
						id="street"
						name="street"
						maxlength="128"
						type="text"
						placeholder="Street"
						ng-model="$ctrl.billingAddress.street"
						required="required"
						autocomplete="shipping street-address"
					/>
				</div>
	
				<div class="mb-3">
					<label for="city" class="required">City</label>
					<input
						id="city"
						name="city"
						maxlength="50"
						type="text"
						placeholder="City"
						ng-model="$ctrl.billingAddress.city"
						autocomplete="shipping address-level2"
					/>
				</div>
	
				<div class="mb-3">
					<label for="zip" class="required">Postal code</label>
					<input
						id="zip"
						name="zip"
						maxlength="15"
						type="text"
						placeholder="Postal code"
						required="required"
						ng-model="$ctrl.billingAddress.zip"
						autocomplete="shipping postal-code"
					/>
				</div>
			</div>
	
			<div class="grid lg:grid-cols-12 gap-3">
				<div class="lg:col-span-5">
					<label for="country" class="required">Country</label>
					<select
						id="country"
						name="country"
						required="required"
						ng-model="$ctrl.country"
						ng-options="country as (country.alpha2 + ' - ' + country.name) for country in countries track by country.alpha3"
						ng-change="$ctrl.selectCountry($ctrl.country)"
					>
						<option value="" disabled>Select a country</option>
					</select>
				</div>
	
				<div
					class="lg:col-span-3"
					ng-show="$ctrl.country.alpha2 === 'US'">
					<label for="region">State</label>
					<input
						type="text"
						id="region"
						name="region"
						maxlength="2"
						placeholder="e.g. DC"
						ng-model="$ctrl.billingAddress.region"
						autocomplete="shipping address-level1"
					/>
				</div>
			</div>
		</div>
	</fieldset>
	
	<fieldset class="panel">
		<h5 class="panel-header">
			<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-5">
				<path fill-rule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clip-rule="evenodd"></path>
			</svg>
			Company information
		</h5>
		<div class="panel-body">
			<div class="grid lg:grid-cols-3 gap-3">
				<div>
					<label for="companyName" ng-class="{'optional' : !$ctrl.billingAddress.companyName, 'required': $ctrl.billingAddress.companyName}">Company name</label>
					<input
						type="text"
						id="companyName"
						name="companyName"
						placeholder="Company name"
						ng-model="$ctrl.billingAddress.companyName"
					/>
				</div>
	
				<div>
					<label for="companyVatNumber" ng-class="{'optional' : !$ctrl.billingAddress.companyName || !$ctrl.country.isEuMember, 'required': $ctrl.country.isEuMember && $ctrl.billingAddress.companyName}">VAT number</label>

					<div class="flex items-center">
						<span ng-show="$ctrl.country.isEuMember" class="mr-2">{{ $ctrl.country.alpha2 }}</span>
						<input
							type="text"
							name="companyVatNumber"
							ng-model="$ctrl.billingAddress.companyVatNumber"
							ng-required="$ctrl.billingAddress.companyName && $ctrl.country.isEuMember"
							hidden
						>
						<input
							type="text"
							id="companyVatNumber"
							name="companyVatNumberRaw"
							ng-pattern="/^[a-zA-Z0-9._-]+$/"
							maxlength="20"
							ng-required="$ctrl.billingAddress.companyName && $ctrl.country.isEuMember"
							placeholder="VAT identification number"
							ng-change="$ctrl.updateCompanyVatNumber()"
							ng-model="vatNumber"
						>
					</div>
				</div>
	
				<div>
					<label for="companyNumber" class="optional">Registration number</label>
					<input
						id="companyNumber"
						name="companyNumber"
						type="text"
						maxlength="20"
						placeholder="Company registration number"
						ng-model="$ctrl.billingAddress.companyNumber"
					/>
				</div>
			</div>
		</div>
	</fieldset>
	
	<fieldset class="panel">
		<h5 class="panel-header">
			<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-5">
				<path d="M0 0h24v24H0z" fill="none"></path><path d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z"></path>
			</svg>
			Invoices delivery
		</h5>
		<div class="panel-body">
			<div class="required">
				<label for="email" class="required">Send an invoice to following emails</label>
				<div class="flex gap-2 mb-3 last:mb-0">
					<input
						type="email"
						name="email"
						placeholder="Main company email"
						required="required"
						ng-model="$ctrl.billingAddress.email"
					/>
					<input
						type="text"
						name="emailCopy"
						ng-model="$ctrl.billingAddress.emailCopy"
						hidden
					/>
					<button
						type="button"
						class="btn btn-secondary"
						ng-click="$ctrl.addEmailCopy(); $ctrl.updateEmailCopy()"
						ng-disabled="emails.length >= 3">
						<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-5"><path d="M0 0h24v24H0z" fill="none"></path><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path></svg>
						 Add email
					</button>
				</div>
	
				<div class="flex gap-1 mb-3 last:mb-0" ng-repeat="email in emails track by $index">
					<input
						type="email"
						placeholder="Send an invoice copy to email"
						required="required"
						autocomplete="no"
						ng-change="$ctrl.updateEmailCopy()"
						ng-model="emails[$index]"
					/>
					<button
						type="button"
						class="btn btn-secondary"
						ng-click="$ctrl.removeEmailCopy($index); $ctrl.updateEmailCopy()">
						<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-5">
							<path d="M0 0h24v24H0z" fill="none"></path><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
						</svg>
					</button>
				</div>
			</div>
		</div>
	</fieldset>
	`,

	controller: class BillingAddressController {
		constructor($scope, $http, $filter) {
			this.$scope = $scope;
			this.$http = $http;
			this.$filter = $filter;

			this.$scope.countries = [];
			this.$scope.emails = [];
			this.$scope.vatNumber = "";
		}

		static get $inject() {
			return ["$scope", "$http", "$filter"];
		}

		$onInit() {
			this.$http({ method: "GET", url: "/api/country/countries" }).then(
				(response) => {
					this.$scope.countries = response.data;
					this.country = this.$filter("filter")(response.data, {
						alpha3: this.billingAddress.country,
					})[0];
					this.$scope.vatNumber = this.country.isEuMember
						? this.billingAddress.companyVatNumber.replace(
								this.country.alpha2,
								"",
							)
						: this.billingAddress.companyVatNumber;
				},
				(error) => {
					console.error(error);
				},
			);

			// Init billingAddress
			this.$scope.$watch(
				() => this.billingAddress,
				() => {
					this.$scope.emails = this.billingAddress.emailCopy.trim()
						? this.billingAddress.emailCopy
								.split(",")
								.map((email) => email.trim())
						: [];
				},
			);
		}

		addEmailCopy() {
			if (this.$scope.emails.length < 3) {
				this.$scope.emails.push("");
			}
		}

		removeEmailCopy(index) {
			this.$scope.emails.splice(index, 1);
		}

		updateEmailCopy() {
			this.billingAddress.emailCopy = this.$scope.emails.join(",");
		}

		selectCountry(country) {
			this.billingAddress.country = country.alpha3;
			this.billingAddress.companyVatNumber = country.isEuMember
				? country.alpha2 + (this.$scope?.vatNumber || "")
				: this.$scope.vatNumber;
		}

		updateCompanyVatNumber() {
			this.billingAddress.companyVatNumber = this.country.isEuMember
				? this.country.alpha2 + (this.$scope?.vatNumber || "")
				: this.$scope.vatNumber;
		}
	},
};
