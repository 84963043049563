import Choices from "choices.js";

export const ruletype = {
	bindings: {
		type: "=?",
		onChange: "&?",
	},

	template: `
		<select ng-model="ctl.type" ng-change="ctl.onValueChange()" class="rule-element">
			<optgroup ng-repeat="(ruleGroup, rules) in ruletypes" label="{{ruleGroup}} checks">
				<option ng-repeat="(checkValue, checkLabel) in ruletypes[ruleGroup]" value="{{checkValue}}">{{checkLabel}}</option>
			</optgroup>
		</select>
	`,
	controller: class RuleTypeController {
		constructor($scope, $element, $timeout, Ruleset) {
			this.$scope = $scope;
			this.$element = $element;
			this.$timeout = $timeout;
			this.Ruleset = Ruleset;
		}

		static get $inject() {
			return ["$scope", "$element", "$timeout", "Ruleset"];
		}

		/**
		 * Při změně hodnoty zavolá callback onChange (pokud je definovan)
		 */
		onValueChange() {
			if (this.onChange !== undefined) {
				this.$timeout(() => {
					this.onChange();
				});
			}
		}

		/**
		 * Na SELECTu v šabloně aktivuje Choices.js
		 */
		activateChoices() {
			this.$timeout(() => {
				const selectElement = this.$element.find("select")[0];
				new Choices(selectElement, {
					itemSelectText: "",
					shouldSort: false,
					searchResultLimit: 999, // No limit
					searchFields: ["label"],
					fuseOptions: {
						threshold: 0.3,
						ignoreLocation: true,
					},
					classNames: {
						containerInner: "form-select",
						itemSelectable: "selectable",
						groupHeading: "group-title",
						item: "item",
						list: "list",
						listDropdown: "dropdown",
					},
				});
			});
		}

		$onInit() {
			this.Ruleset.then((data) => {
				this.$scope.ruletypes = data;
				this.activateChoices();
			});
		}
	},

	controllerAs: "ctl",
};
