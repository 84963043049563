import slugify from "@sindresorhus/slugify";
import { escape } from "lodash";
import { DataSet } from "vis-data";

/**
 * @see https://visjs.github.io/vis-timeline/docs/timeline/
 * @param {array} incidents
 * @param {Date} min
 * @returns {{groups: DataSet, items: DataSet}}
 */
export function getTimelineData(incidents, min) {
	const items = new DataSet();
	const groups = new DataSet();

	const unique = new Set();

	for (const incident of incidents) {
		const start = new Date(incident.created * 1000);
		const end =
			incident.status === "resolved"
				? new Date(incident.resolved * 1000)
				: new Date();

		items.add({
			id: incident._id,
			group: incident.testId,
			start: start < min ? min.toISOString() : start.toISOString(),
			end: end.toISOString(),
			className: `${incident.resolved ? "resolved" : "unresolved"} ${slugify(`${incident.shorthand}-error`)}`,
			content: escape(incident.message),
		});

		for (const incident of incidents) {
			if (!unique.has(incident.testId)) {
				groups.add({ id: incident.testId, content: incident.testName });
				unique.add(incident.testId);
			}
		}
	}

	return { items, groups };
}
