import angular from "angular";
import "angular-resource";
import { Area } from "./area.resource.js";
import { Auth } from "./auth.resource.js";
import { Authentication } from "./authentication.resource.js";
import { Check } from "./check.resource.js";
import { CurrentValue } from "./current-value.resource.js";
import { Incident } from "./incident.resource.js";
import { Permission } from "./permission.resource.js";
import { Project } from "./project.resource.js";
import { Role } from "./role.resource.js";
import { Template } from "./template.resource.js";
import { Test } from "./test.resource.js";
import { User } from "./user.resource.js";

angular
	.module("testomato.resources", ["ngResource"])
	.factory("Auth", Auth)
	.factory("Project", Project)
	.factory("Test", Test)
	.factory("Check", Check)
	.factory("Area", Area)
	.factory("User", User)
	.factory("Permission", Permission)
	.factory("Role", Role)
	.factory("Incident", Incident)
	.factory("Template", Template)
	.factory("Authentication", Authentication)
	.factory("CurrentValue", CurrentValue);
