export function Area($resource) {
	return $resource(
		"/api/area/:areaId/:action",
		{},
		{
			update: { method: "PUT", params: {} },
			remove: { method: "DELETE", params: {} },

			// We need to set Content-Type to undefined,
			// to make the browser set it to multipart/form-data
			// and fill in the correct *boundary*.
			// setting Content-Type to multipart/form-data manually
			// will fail to fill in the boundary parameter of the request.
			saveForm: {
				method: "POST",
				params: { action: "saveForm" },
				headers: { "Content-Type": undefined },
			},
			createFromForm: { method: "POST", params: { action: "createFromForm" } },
		},
	);
}

Area.$inject = ["$resource"];
