import { IssueChart } from "./issue-chart.js";
import { ResponseTimeChart } from "./response-time-chart.js";
import { uptimeChart } from "./uptime-chart.js";

/**
 * Stará se o vyreslení grafů pro stránku reportů
 * @param {array} incidents
 * @param {array} uptime
 * @param {array} timing
 */
window.drawReportCharts = ({ incidents, uptime, responseTimes }) => {
	if (incidents.length > 0) {
		// Issue type chart
		const issueTypeChartElement = document.querySelector("#issues-type-chart");
		new IssueChart(issueTypeChartElement, incidents);
	}

	// Uptime chart
	if (Object.hasOwn(uptime, "uptimeByDays") && uptime.uptimeByDays.length > 0) {
		const uptimeChartElement = document.querySelector("#uptime-chart");
		uptimeChart(uptimeChartElement, uptime.uptime);
	}

	// reponse time chart
	if (responseTimes.length > 0) {
		const responseTimeChartElement = document.querySelector(
			"#response-time-chart",
		);
		new ResponseTimeChart(responseTimeChartElement, responseTimes);
	}
};
