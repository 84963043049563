import dropin from "braintree-web-drop-in";

/**
 * @see https://braintree.github.io/braintree-web-drop-in/docs/current/index.html
 * @see https://github.com/braintree/braintree-web-drop-in
 *
 * Testovací karty:
 *
 * @see https://developers.braintreepayments.com/guides/credit-cards/testing-go-live/php
 * @see https://developers.braintreepayments.com/guides/3d-secure/testing-go-live/php#testing - seznam testovacích karet pro 3DS
 *
 * @see https://developers.braintreepayments.com/guides/3d-secure/recurring-transactions/javascript/v3#braintree-recurring-billing-subscriptions
 *
 * Field	Value
 *
 * Number (successful with no challenge): 4000000000001000
 * Number (successful with challenge): 4000000000001091
 * Number (unsuccessful with challenge): 4000000000001109
 *
 * Expiration Date (for sandbox testing, year must be exactly 3 years in the future)
 * CVV: 123
 * Postal Code: 12345
 *
 * @see https://codepen.io/braintree/pen/KjWqGx
 *
 * Example na GitHub
 * https://github.com/KuriousAgency/commerce-braintree/blob/master/src/assetbundles/dropinui/dist/js/DropinUi.js
 */
export const braintreeWebDropIn = {
	bindings: {
		clientToken: "@",
		billingAddress: "<",
		country: "<",
		amount: "<",
	},

	require: {
		form: "^", // Inject parent form to controller
	},

	template: `
		<div class="panel">
			<h5 class="panel-header">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
				  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
				</svg>
				Payment Method
			</h5>
			<div class="panel-body">
				<input type="hidden" ng-model="isPaymentMethodValid" ng-init="isPaymentMethodValid = null" required />
				<input type="hidden" name="nonce" value="" />
				<p class="p-4 my-2 text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" ng-if="errors" ng-repeat="error in errors">
					<strong ng-switch="error.type">
	          <span ng-switch-when="CUSTOMER">Customer error: </span>
	          <span ng-switch-when="MERCHANT">Merchant error: </span>
	          <span ng-switch-when="NETWORK">Network error: </span>
	          <span ng-switch-when="INTERNAL">Internal error: </span>
	          <span ng-switch-when="UNKNOWN">Uknown error: </span>
					</strong>
					{{error.message}}
				</p>
				<div id="braintree-web-drop-in-container"></div>
			</div>
		</div>
	`,

	controller: class PaymentController {
		constructor($scope) {
			this.$scope = $scope;
		}

		static get $inject() {
			return ["$scope"];
		}

		get $form() {
			return this.form.$$element[0];
		}

		// @see https://braintree.github.io/braintree-web/current/ThreeDSecure.html
		get threeDSecureParameters() {
			// The amount of the transaction in the current merchant account's currency.
			// This must be expressed in numbers with an optional decimal (using .) and precision up to the hundredths place.
			// For example, if you're processing a transaction for 1.234,56 € then amount should be 1234.56.
			// amount string shall match follow regex [\d]+.[\d]{2}
			const amount = Number.parseFloat(this.amount).toFixed(2);

			// The phone number associated with the billing address. Only numbers; remove dashes, parenthesis and other characters.
			const phoneNumber = this.billingAddress.phone?.replace(/\D/g, "");

			// ASCII-printable characters required, else will throw a validation error
			const givenName =
				this.billingAddress.firstName?.normalize(
					"NFKD",
				) /* .replace(/[\u0300-\u036F]/g, '') */;
			const surname =
				this.billingAddress.lastName?.normalize(
					"NFKD",
				) /* .replace(/[\u0300-\u036F]/g, '') */;

			// The 2 letter code for US states, and the equivalent for other countries.
			const region =
				this.billingAddress.region?.normalize("NFKD")?.slice(0, 2) || "";

			return {
				amount,
				email: this.billingAddress.email,
				// @see https://braintree.github.io/braintree-web/current/ThreeDSecure.html#~billingAddress
				billingAddress: {
					givenName,
					surname,
					phoneNumber,
					streetAddress: this.billingAddress?.street,
					extendedAddress: "",
					locality: this.billingAddress?.city,
					region,
					postalCode: this.billingAddress?.zip,
					countryCodeAlpha2: this.country?.alpha2,
				},
				// @see https://braintree.github.io/braintree-web/current/ThreeDSecure.html#~additionalInformation
				additionalInformation: {
					workPhoneNumber: this.billingAddress?.phone,
					shippingGivenName: givenName,
					shippingSurname: surname,
					shippingPhone: phoneNumber,
					shippingAddress: {
						streetAddress: this.billingAddress?.street,
						extendedAddress: "",
						locality: this.billingAddress?.city,
						region,
						postalCode: this.billingAddress?.zip,
						countryCodeAlpha2: this.country?.alpha2,
					},
				},
			};
		}

		set isPaymentMethodValid(value) {
			this.$scope.$apply(() => {
				this.$scope.isPaymentMethodValid = value ? true : null;
			});
		}

		set errors(errors) {
			this.$scope.$apply(() => {
				this.$scope.errors = errors ? [...errors] : [];
			});
		}

		set nonce(value) {
			this.$form.nonce.setAttribute("value", value);
		}

		$onInit() {
			dropin
				.create({
					authorization: this.clientToken,
					container: "#braintree-web-drop-in-container",
					/**
					 * 3DS Platby
					 * @see https://developers.braintreepayments.com/guides/3d-secure/client-side/javascript/v3
					 */
					threeDSecure: true,
					/**
					 * We do not recommend enabling Vault Manager if you are using Braintree's recurring billing;
					 * doing so would give your customers the ability to delete payment methods associated with subscriptions.
					 * @see https://developers.braintreepayments.com/guides/recurring-billing/overview
					 */
					vaultManager: false,
					locale: "en_US",
					paypal: {
						flow: "vault",
					},
				})
				.then((dropinInstance) => {
					this.onDropinInstanceCreated(dropinInstance);
				})
				.catch((error) => {
					this.isPaymentMethodValid = false;
					throw error;
				});
		}

		onDropinInstanceCreated(dropinInstance) {
			if (dropinInstance.isPaymentMethodRequestable()) {
				// This will be true if you generated the client token
				// with a customer ID and there is a saved payment method
				// available to tokenize with that customer.
				this.isPaymentMethodValid = true;
			}

			// This event is emitted when the payment method available in
			// Drop-in changes. This includes when the state of Drop-in
			// transitions from having no payment method available
			// to having a payment method available and when the
			// payment method available changes.

			dropinInstance.on("paymentMethodRequestable", () => {
				this.isPaymentMethodValid = true;
			});

			// This event is emitted when there is no payment method
			// available in Drop-in. This event is not fired if there
			// is no payment method available on initialization.
			// @see https://braintree.github.io/braintree-web-drop-in/docs/current/Dropin.html#event:noPaymentMethodRequestable
			dropinInstance.on("noPaymentMethodRequestable", () => {
				this.isPaymentMethodValid = false;
			});

			this.$form.addEventListener("submit", (event) => {
				event.preventDefault(); // Before form submit
				this.errors = null; // Reset error

				dropinInstance
					.requestPaymentMethod({ threeDSecure: this.threeDSecureParameters })
					.then((payload) => {
						// Payload response structure
						// @see https://developers.braintreepayments.com/reference/response/payment-method-nonce/php
						// @see https://braintree.github.io/braintree-web/current/ThreeDSecure.html#~verifyPayload

						if (
							// payload.liabilityShiftPossible indicates that the payment method was eligible for 3D Secure.
							// If liabilityShifted is false, then the user failed 3D Secure authentication.
							// In this situation, the card brands recommend asking the user for another form of payment.
							// However, if you have server-side risk assessment processes that allow for it,
							// you can still use the new nonce to create a transaction. If you want to use a nonce
							// that did not pass 3D Secure authentication, you need to set the required option
							// to false in your server integration.

							(payload.liabilityShiftPossible && payload.liabilityShifted) ||
							// liabilityShiftPossible is not possible
							!payload.liabilityShiftPossible ||
							// payload.type : other payment methods eg. PayPalAccount, AndroidPayCard, ....
							payload.type !== "CreditCard"
						) {
							// Valid payment method
							this.isPaymentMethodValid = true;
							this.nonce = payload.nonce;
							this.$form.submit();
						} else {
							// Invalid payment method
							this.isPaymentMethodValid = false;
							dropinInstance.clearSelectedPaymentMethod();
							this.errors = getErrorsFrom(payload);
						}
					})
					.catch((error) => {
						this.isPaymentMethodValid = false;
						dropinInstance.clearSelectedPaymentMethod();
						this.errors = getErrorsFrom(error);
					});
			});
		}
	},
};

function* getErrorsFrom(error) {
	// @see https://developer.paypal.com/braintree/docs/guides/3d-secure/legacy-3d-secure/server-side/php#status-codes

	switch (error?.threeDSecureInfo?.status) {
		case "unsupported_card":
		case "unsupported_account_type": {
			yield {
				message:
					"We can't accept this card type - only credit and debit can be accepted. Please try again or choose a different payment method.",
				type: "MERCHANT",
			};
			break;
		}

		case "unsupported_three_d_secure_version": {
			yield {
				message:
					"Our merchant account is not set up to use the 3D Secure version requested. Please try again or choose a different payment method.",
				type: "MERCHANT",
			};
			break;
		}

		case "authentication_bypassed":
		case "authenticate_rejected":
		case "authenticate_error": {
			yield {
				message:
					"An error occurred within the 3D Secure authentication system. Please try again or choose a different payment method.",
				type: "CUSTOMER",
			};
			break;
		}

		case "authentication_unavailable": {
			yield {
				message:
					"The card network is unavailable. Please try again or choose a different payment method.",
				type: "CUSTOMER",
			};
			break;
		}

		case "lookup_not_enrolled":
		case "lookup_enrolled":
		case "lookup_bypassed":
		case "lookup_error": {
			yield {
				message: `3D Secure authentication failed or timeouted with code: ${error.threeDSecureInfo.status}.`,
				type: "CUSTOMER",
			};
			yield {
				message: "Please try again or choose a different payment method.",
				type: "CUSTOMER",
			};
			break;
		}

		case "authenticate_signature_verification_failed": {
			yield {
				message: "Returned authentication message is no longer valid.",
				type: "MERCHANT",
			};
			break;
		}

		case "authenticate_failed":
		case "authenticate_frictionless_failed": {
			yield {
				message:
					"Payment authentication failed. Please try again or choose a different payment method.",
				type: "CUSTOMER",
			};
			break;
		}

		case "challenge_required": {
			yield {
				message: "Payment authentication unsuccessful (failed or timeouted).",
				type: "CUSTOMER",
			};
			break;
		}

		case undefined: {
			// Nedostali jsme staus kód (je to jiný druh chyby)
			yield {
				message:
					error?.error?.message ||
					error?.message ||
					"Please try again or choose a different payment method.",
				type: error?.error?.type || error?.type || "UNKNOWN",
			};

			if (error?.details?.originalError) {
				yield* getErrorsFrom(error.details.originalError);
			}

			break;
		}

		default: {
			// Není cases, ale nějaký status kód jsme dostali
			yield {
				message:
					"Payment authentication unsuccessful. Please try again or choose a different payment method.",
				type: "CUSTOMER",
			};
			yield {
				message: `3D Secure authentication failed with status code: ${error.threeDSecureInfo.status}`,
				type: "CUSTOMER",
			};
			break;
		}
	}

	// Vypsat chybu do console
	if (error?.error || error?.threeDSecureInfo) {
		console.error(error?.error || error?.threeDSecureInfo);
	}
}
