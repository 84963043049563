import flip from "@popperjs/core/lib/modifiers/flip.js";
import preventOverflow from "@popperjs/core/lib/modifiers/preventOverflow.js";
import { createPopper } from "@popperjs/core/lib/popper-lite.js";
import "./dropdown.directive.css";

export function dropdown() {
	return {
		restrict: "A",
		link(scope, element, attributes) {
			// Popper create instance

			element.on("click", async () => {
				const menu = document.querySelector(`#${attributes.dropdown}`);
				menu.classList.toggle("hidden");
				element[0].setAttribute(
					"aria-expanded",
					!menu.classList.contains("hidden"),
				);

				createPopper(
					element[0],
					document.querySelector(`#${attributes.dropdown}`),
					{
						placement: attributes.placement || "bottom-start",
						modifiers: [flip, preventOverflow],
					},
				);

				// close dropdown menu on Escape or click somewhere else
				function closeMenu(event) {
					if (
						event.type === "click" &&
						(event.target !== element[0] || event.key === "Escape")
					) {
						menu.classList.add("hidden");
						element[0].setAttribute("aria-expanded", false);

						// remove close menu handlers
						document.removeEventListener("click", closeMenu);
						document.removeEventListener("keydown", closeMenu);
					}
				}

				document.addEventListener("click", closeMenu);
				document.addEventListener("keydown", closeMenu);
			});
		},
	};
}
