import type { App } from "vue";
import type { Notification } from "./notification";
import useToast from "./use-toast";

export type Toast = {
	show(message: string, notification?: Notification): void;
	info(message: string, notification?: Notification): void;
	success(message: string, notification?: Notification): void;
	error(message: string, notification?: Notification): void;
	warning(message: string, notification?: Notification): void;
	clean(): void;
};

export default {
	install(app: App, notification: Notification = {}) {
		const toastPlugin: Toast = useToast(notification);
		app.provide<Toast>("toast", toastPlugin);
	},
};
