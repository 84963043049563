import { ResponseTimeChart } from "../charts/response-time-chart.js";

export const chartResponseTime = {
	bindings: {
		data: "=",
	},

	template: "<div></div>",

	controller: class ResponseTimeChartController {
		constructor($scope, $element) {
			this.$scope = $scope;
			this.$element = $element;

			$scope.$watch(
				() => this.data,
				() => {
					if (this.data) {
						new ResponseTimeChart($element[0], this.data);
					}
				},
			);
		}

		static get $inject() {
			return ["$scope", "$element"];
		}
	},
};
