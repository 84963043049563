/**
 * Form validation for older browsers
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/:user-invalid
 * @see https://caniuse.com/?search=user-invalid
 */
try {
	if (typeof window !== "undefined") {
		document.querySelector(":user-invalid");
	}
} catch {
	document.addEventListener("DOMContentLoaded", () => {
		for (const input of document.querySelectorAll("input")) {
			input.addEventListener("change", (event) =>
				event.target.classList.add("dirty"),
			);
		}
	});
}
