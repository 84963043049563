// index posledního vybraného testu
export function ttTestBox() {
	return {
		restrict: "A",
		link($scope, element) {
			const statusClasses = "ok warning error running unknown";

			$scope.$watch("test.run", (run) => {
				if (run === "disabled") {
					element.removeClass(statusClasses).addClass("disabled");
				} else {
					element.removeClass("disabled");
					if ($scope.test.status) {
						element.addClass($scope.test.status.status);
					} else {
						element.addClass("unknown");
					}
				}
			});

			$scope.$watch("test.status.status", (status) => {
				element.removeClass(statusClasses);
				element.addClass(status || "unknown");
			});
		},
	};
}
