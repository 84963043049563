/**
 * Sestavá pole s rozsahem
 * @param start
 * @param stop
 * @param step
 * @returns {unknown[]}
 */
export function range(start, stop, step = 1) {
	return Array.from(
		{ length: (stop - start) / step + 1 },
		(_, i) => start + i * step,
	);
}
