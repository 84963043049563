import angular from "angular";
import { autoexpand } from "./autoexpand.directive.js";
import { autofocus } from "./autofocus.directive.js";
import { dropdown } from "./dropdown.directive.js";
import { selectable } from "./selectable.directive.js";
import { sortable } from "./sortable.directive.js";
import { ttTestBox } from "./tt-test-box.directive.js";

export default angular
	.module("testomato.directive", [])
	.directive("dropdown", dropdown)
	.directive("autofocus", autofocus)
	.directive("autoexpand", autoexpand)
	.directive("sortable", sortable)
	.directive("selectable", selectable)
	.directive("ttTestBox", ttTestBox);
