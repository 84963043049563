const TemplateDirectiveController = function TemplateDirectiveController(
	$scope,
	Template,
) {
	const originalTemplateVariables = $scope.templateVars || [];

	const initTemplateVariables = (templateId) => {
		const template = $scope.templates.findLast((t) => t.id === templateId);

		if (template) {
			let templateVariables = (template.rules || []).reduce(
				(previous, rule) =>
					previous.concat(
						(rule.val.match(/%[^%\s]+%/g) || []).map((m) => ({
							name: m.slice(1, -1),
							val: "",
						})),
					),
				[],
			);

			// Pouze unikátní
			const hash = {};
			templateVariables = templateVariables.filter((vrr) => {
				if (hash[vrr.name]) {
					return false;
				}

				hash[vrr.name] = 1;
				return true;
			});

			// Použijeme aktuální hodnoty
			$scope.templateVars = templateVariables.map((vrr) => {
				const current = originalTemplateVariables.findLast(
					(v) => v.name === vrr.name,
				);
				return current || vrr;
			});
		}
	};

	$scope.templates = [];

	$scope.shown = false;

	$scope.$watch("templates.length", (length) => {
		$scope.shown = length > 1;
	});

	$scope.$watch("templateId", (templateId) => {
		initTemplateVariables(templateId);
	});

	Template.user((templates) => {
		templates.unshift({ id: false, name: "-- not selected --" });
		$scope.templates = templates;
		initTemplateVariables($scope.templateId);
	});
};

TemplateDirectiveController.$inject = ["$scope", "Template"];

export default TemplateDirectiveController;
