import angular from "angular";
import { confirmDialog } from "../../functions/confirm.js";
import { startProject } from "../../functions/start-project.js";
import {
	getAreaDetailsVisibility,
	saveAreaDetailsVisibility,
} from "./area-details-functions.js";
import createCheckCopy from "./create-check-copy.js";

const ProjectChecksCtrl = function ProjectCheckCtrl(
	$scope,
	$rootScope,
	$location,
	$http,
	$timeout,
	$filter,
	$q,
	status,
	areas,
	Project,
	Area,
	Test,
	User,
	Flash,
	Authentication,
) {
	/**
	 * Zavolá se po úspěšném stažení status
	 * @param status
	 * @return {Promise<void>}
	 */
	const statusOnSuccess = async (status) => {
		if (status.error) {
			Flash.error(status.text);
			return;
		}

		$scope.status = status;
		const incomplete = new Map();
		for (const test of $scope.status.tests) {
			if (!test.status && test.run !== "disabled") {
				if (incomplete.has(test.areaId)) {
					incomplete.get(test.areaId).push(test.id);
				} else {
					incomplete.set(test.areaId, [test.id]);
				}
			}
		}

		// Dopuštění nekompletních testů
		for (const [areaId, testsToRun] of incomplete) {
			await $scope.startTests(areaId, testsToRun);
		}

		if (Object.getOwnPropertyNames(incomplete).length === 0) {
			// Nic se nestartovalo
			$scope.stepsProgress[2] = 1;
			$scope.stepsProgress[3] = 1;
		}
	};

	const tickingAreas = [];

	const setupAreaTimers = (areas) => {
		let timeout = 3600; // Hour is default

		const tick = (i) => {
			// Pokud je Area v editačním modu, neodpočítáváme
			if (areas[i].edit !== true) {
				areas[i].nextRun--;
			}

			if (areas[i].nextRun >= 0) {
				$timeout(() => {
					tick(i);
				}, 1000);
			} else {
				if (!$scope.run.includes(areas[i].id)) {
					// Jakože běží testy
					$scope.run.push(areas[i].id);
					const tests = $scope.status.tests;
					for (const test of tests) {
						if (test.areaId === areas[i].id && test.run !== "disabled") {
							test.status = { status: "running" };
						}
					}
				}

				$timeout(() => {
					$scope.refreshAreas().then(() => {
						if (areas[i].nextRun > 0 || areas[i].nextRun < -30) {
							// Testy doběhly nebo se něco posralo
							let index_ = $scope.run.indexOf(areas[i].id);
							$scope.run.splice(index_, 1);
							$scope.retrieveLastStatus();
							if (areas[i].nextRun > 300) {
								// Přestaneme tikat
								index_ = tickingAreas.indexOf(i);
								tickingAreas.splice(index_, 1);
							}
						}

						tick(i);
					});
				}, 5000);
			}
		};

		let index = 0;
		for (const area of areas) {
			if (area.nextRun && area.nextRun > 0) {
				// Poslední hodina
				if (area.nextRun < 3600) {
					timeout = 60;
				}

				// Posledních 5 minut
				if (area.nextRun <= 300 && !tickingAreas.includes(index)) {
					tickingAreas.push(index);
					tick(index);
				}
			}

			index++;
		}

		$timeout(() => {
			$scope.refreshAreas().then(() => {
				setupAreaTimers(areas);
			});
		}, timeout * 1000);
	};

	/**
	 * Seznam ID označených testů
	 * @type {Array}
	 */
	$scope.selected = [];

	/**
	 * Dostane seznam testId vybraných testů
	 * @param selected
	 */
	$scope.$on("test.selected.change", (event, selected) => {
		$scope.selected = selected;
	});

	/**
	 * Znovunačte seznam protected areas a výsledky autentizací
	 */
	$scope.refreshAreas = () => {
		const deferred = $q.defer();
		Project.areas(
			{ projectId: $scope.project.id },
			(areas) => {
				// $scope.areas = areas způsobí překreslení celého view a neudržení scroll position
				for (const [i, area] of areas.entries()) {
					if (
						$scope.areas[i] && // Pokud je Area v editačním modu, nepřekreslujeme
						$scope.areas[i].edit !== true
					) {
						angular.extend($scope.areas[i], area);
					}
				}

				$rootScope.$broadcast("areasLoaded");
				deferred.resolve(areas);
			},
			(error) => {
				deferred.reject(error);
			},
		);
		return deferred.promise;
	};

	/**
	 * Získá poslední výsledky testů: zavolá API, a zpracuje odpověď
	 */
	$scope.retrieveLastStatus = () => {
		$scope.refreshAreas().then(() => {
			Project.status({ projectId: $scope.project.id }, statusOnSuccess);
		});
	};

	/**
	 * Payment subscription
	 * @type {Object}
	 */
	$scope.subscription = null;
	$scope.subscription = User.subscription({ projectId: $scope.project.id });

	/**
	 * Seznam areas
	 */
	$scope.areas = areas;

	/**
	 * Výsledky posledních testu
	 * @type {{}}
	 */
	$scope.status = {};

	/**
	 * Běžící testy
	 * @type {Array}
	 */
	$scope.run = [];

	/**
	 * Progress jednotlivých kroků
	 * @type {Array}
	 */
	$scope.stepsProgress = [0, 0, 0, 0];

	/**
	 * Možnosti pro nastavení run checks
	 * @type {Array}
	 */
	$scope.validPeriods = null;

	/**
	 * Možnosti pro nastavení uptime monitoring
	 * @type {Array}
	 */
	$scope.uptimeOptions = null;

	Project.options({ projectId: $scope.project.id }, (options) => {
		// Find project period label
		let projectPeriodLabel = "?";
		for (const period of options.testing) {
			if (period.value === $scope.project.period) {
				projectPeriodLabel = period.label;
			}
		}

		$scope.validPeriods = options.testing;
		$scope.uptimeOptions = options.uptime;
		// $scope.locations = options.location;
		$scope.validPeriods.pop(); // Remove never
		$scope.validPeriods.unshift({
			value: true,
			label: `-- same as project (${projectPeriodLabel}) --`,
		});
	});

	/**
	 * Akce, která nastartuje testy
	 * @param areaId
	 * @param testsToRun
	 * @return {Promise<void>}
	 */
	$scope.startTests = async (areaId, testsToRun) => {
		if ($scope.run.includes(areaId) || !$scope.project.permissions.run) {
			return;
		}

		$scope.run.push(areaId);

		// sestavím si seznam testů pro spuštění
		if (testsToRun === undefined) {
			testsToRun = [];
			for (const test of $scope.status.tests) {
				if (test.areaId === areaId && test.run !== "disabled") {
					testsToRun.push(test.id);
				}
			}
		}

		// projdu seznam testů a nastavím jim stav running a uložím si předchozí stav
		for (const test of $scope.status.tests) {
			if (testsToRun.includes(test.id)) {
				if (!test?.status) {
					test.status = {};
				}

				test.status.prevstatus = test?.status?.status || null;
				test.status.status = "running";
			}
		}

		for await (const data of startProject(
			$scope.project.id,
			areaId,
			testsToRun || false,
		)) {
			if (data?.testId) {
				const result = $filter("filter")($scope.status.tests, {
					id: data.testId,
				})[0];
				const lastChange =
					result.status.prevstatus === data.status
						? result.status.lastChange
						: status.now;

				$scope.$apply(() => {
					result.status = data;
					result.status.lastChange = lastChange;
				});
			}
		}

		// Testy, pro které nemáme výsledek, vrátíme původní stav

		for (const test of $scope.status.tests) {
			if (
				test.areaId === areaId &&
				test.status &&
				test.status.status === "running"
			) {
				if (test.status.prevstatus) {
					test.status.status = test.status.prevstatus;
					delete test.status.prevstatus;
				} else {
					test.status = null;
				}
			}
		}

		$timeout(() => {
			const i = $scope.run.indexOf(areaId);
			$scope.run.splice(i, 1);

			// Stáhneme si ještě project status
			Project.status(
				{ projectId: $scope.project.id, withTests: 0 },
				(status) => {
					if (status.error) {
						return;
					}

					$scope.status.project = status.project;
				},
			);

			// A protected areas
			$scope.refreshAreas();
		});
	};

	/**
	 * Seznam označených check detailu
	 * @type {Array}
	 */
	$scope.showChecksDetails = {};

	/**
	 * Uloží změnu pořadí testů
	 * @param fromIndex
	 * @param toIndex
	 * @param tests
	 */
	$scope.reorderTests = (fromIndex, toIndex, tests) => {
		if (fromIndex !== toIndex) {
			$timeout(() => {
				tests[fromIndex] = tests.splice(toIndex, 1, tests[fromIndex])[0];

				const testIds = tests.map((test) => test.id);
				// Save new order
				Project.sortTests(
					{ projectId: $scope.project.id },
					{ testIds },
					(project) => {
						$scope.project = project;
					},
				);
			}, 200);
		}
	};

	/**
	 * Akce, která enabluje/disabluje check podle aktuálního stavu
	 * @param check
	 */
	$scope.enableDisableCheck = (check) => {
		Test.enable(
			{ testId: check.id },
			{ enable: !check.enabled },
			async (check) => {
				// Najdu test v seznamu a provedu aktualizaci
				const found = $scope.status.tests.findIndex((ch) => ch.id === check.id);
				if (found !== -1) {
					$scope.status.tests[found] = check;
				}

				// Spustí kontrolu pokud byl test povolen
				if (check.enabled) {
					await $scope.startTests(check.areaId, [check.id]);
				}

				// Stáhnu nové subscription (kvůli omezení počtu testů)
				User.subscription({ projectId: $scope.project.id }, (subscription) => {
					$scope.subscription = subscription;
				});
			},
		);
	};

	/**
	 * Akce, která smaže check z projektu
	 * @param check
	 */
	$scope.deleteCheck = async (check) => {
		const isConfirmed = await confirmDialog({
			title: "Delete check?",
			text: "Are you sure you want to delete this check?",
			confirmButtonText: "Yes, delete check!",
		});

		if (isConfirmed) {
			$scope.selected = $scope.selected.filter((id) => id !== check.id);
			Test.delete({ testId: check.id }, () => {
				const found = $scope.status.tests.findIndex((ch) => ch.id === check.id);
				if (found !== -1) {
					$scope.status.tests.splice(found, 1);
				}
			});
		}
	};

	/**
	 * Akce, která smaže všechny vybrané testy
	 * @param selectedChecks
	 * @return {Promise<void>}
	 */
	$scope.deleteSelectedChecks = async (selectedChecks) => {
		const isConfirmed = await confirmDialog({
			title: "Delete selected checks?",
			text: "Are you sure you want to delete all selected checks?",
			confirmButtonText: "Yes, delete selected!",
		});

		if (isConfirmed) {
			Test.batchRemove(
				{ projectId: $scope.project.id },
				{ testIds: selectedChecks },
				() => {
					$scope.status.tests = $scope.status.tests.filter(
						(test) => !selectedChecks.includes(test.id),
					);
					$scope.status.total -= selectedChecks.length;
					$scope.selected = [];
				},
			);
		}
	};

	/**
	 * Po uložení area credentials
	 * @param area
	 */
	$scope.saveArea = async (area) => {
		Area.update({ areaId: area.id }, area, (newArea) => {
			area = newArea;
			$scope.startTests(area.id);
			Flash.success("Group settings successfully saved");
		});
	};

	/**
	 * Akce, která smaže protected area z projektu
	 * @param areaId
	 */
	$scope.removeArea = async (areaId) => {
		const isConfirmed = await confirmDialog({
			title: "Delete group?",
			text: "Are you sure you want to delete this group with all checks?",
			confirmButtonText: "Yes, delete group!",
		});

		if (isConfirmed) {
			Area.remove({ areaId }, () => {
				$scope.areas = $scope.areas.filter((area) => area.id !== areaId);
				$scope.status.tests = $scope.status.tests.filter(
					(test) => test.areaId !== areaId,
				);
				$scope.status.total = $scope.status.tests.length;
			});
		}
	};

	/**
	 * Duplikovani checku
	 * @param test
	 */
	$scope.duplicateCheck = (test) => {
		const tests = $scope.status.tests;
		const index = tests.indexOf(test);
		const testCopy = createCheckCopy(test);

		Test.copy({ testId: test.id }, (newTest) => {
			testCopy.id = newTest.id;
			$scope.status.tests.splice(
				index >= 0 ? index + 1 : tests.length,
				0,
				testCopy,
			);
			$scope.status.total++;
		});
	};

	/**
	 * Akce, která uloží nastavení uptime
	 */
	$scope.saveUptime = () => {
		Project.update(
			{ projectId: $scope.project.id },
			{
				uptimeEnabled: $scope.project.uptimeEnabled,
				uptimeUrl: $scope.project.uptimeUrl,
			},
			(project) => {
				Flash.success("Uptime settings successfully saved");
				$scope.project.uptimeEnabled = project.uptimeEnabled;
				$scope.project.uptimeUrl = project.uptimeUrl;
			},
		);
	};

	/**
	 * Akce. která smaže projekt
	 */
	$scope.deleteProject = async () => {
		const isConfirmed = await confirmDialog({
			title: "Delete project?",
			text: "Are you sure you want to delete this project? (This action cannot be undone!)",
			confirmButtonText: "Yes, delete project!",
		});

		if (isConfirmed) {
			Project.remove({ projectId: $scope.project.id }, () => {
				Flash.success("Project was successfully deleted");
				window.setTimeout(() => {
					window.location.pathname = "/";
				}, 400);
			});
		}
	};

	/**
	 * Provede ověření credentials
	 * @param area
	 */
	$scope.verifyCredentials = (area) => {
		$scope.showResponse = true;
		const embedResponse = document.querySelector("#response");
		Authentication.verify(
			{ area },
			(response) => {
				embedResponse.setAttribute("src", response);
				embedResponse.style.height = `${Math.ceil(window.screen.height * 0.75)}px`;
			},
			(error) => {
				Flash.error(error);
				console.error(error);
			},
		);
	};

	/**
	 * Uloží nastavení viditelnosti detailů checků
	 * @param project
	 * @param area
	 */
	$scope.saveAreaDetailsVisibility = (project, area) =>
		saveAreaDetailsVisibility(area.showChecksDetails, project.id, area.id);

	/**
	 * Získá nastavení viditelnosti detailů checků
	 * @param project
	 * @param area
	 * @return {boolean}
	 */
	$scope.getAreaDetailsVisibility = (project, area) =>
		getAreaDetailsVisibility(project.id, area.id);

	statusOnSuccess(status).then();
	setupAreaTimers(areas);

	$scope.$on("retrieveLastStatus", () => {
		$scope.retrieveLastStatus();
	});
};

ProjectChecksCtrl.$inject = [
	"$scope",
	"$rootScope",
	"$location",
	"$http",
	"$timeout",
	"$filter",
	"$q",
	"status",
	"areas",
	"Project",
	"Area",
	"Test",
	"User",
	"Flash",
	"Authentication",
];

export default ProjectChecksCtrl;
