import { htmlFormPreview } from "../forms/html-form-preview.js";

export const formPreview = {
	bindings: {
		formHtml: "<",
		formAction: "<?",
	},

	template: `
		<div class="p-4 mx-2 my-3 relative rounded bg-gray-100 dark:bg-gray-800 outline outline-offset-4 outline-dashed outline-2 outline-gray-300 dark:outline-gray-800 before:content-['HTML_Form_preview'] before:text-sm before:opacity-50 before:absolute before:italic before:right-4">
			<p ng-if="$ctrl.formAction">Action: <code class="text-muted">{{$ctrl.formAction}}</code></p>
			<form ng-bind-html="formTrustedHtml" disabled></form>
		</div>
		`,

	controller: class FormPreviewController {
		constructor($sce, $scope) {
			this.$sce = $sce;
			this.$scope = $scope;
		}

		static get $inject() {
			return ["$sce", "$scope"];
		}

		$onChanges() {
			if (this.formHtml) {
				this.$scope.formTrustedHtml = this.$sce.trustAsHtml(
					htmlFormPreview(this.formHtml),
				);
			}
		}

		submit(event) {
			event.preventDefault(); // Do not submit this form at all...
		}
	},
};
