export function Test($resource) {
	return $resource(
		"/api/test/:testId/:action",
		{},
		{
			// multiple actions
			addMultipleForms: {
				method: "POST",
				params: { action: "add-multiple-forms" },
			},

			// create test wizard
			newFromUrl: { method: "POST", params: { action: "new-from-url" } },

			// test CRUD
			create: { method: "POST", params: {} },
			update: { method: "PUT", params: {} },
			delete: { method: "DELETE", params: {} },
			copy: { method: "COPY", params: {} },
			enable: { method: "POST", params: { action: "enable" } },

			// batch actions
			batchSave: { method: "POST", params: { action: "batch-save" } },
			batchRemove: { method: "POST", params: { action: "batch-remove" } },
		},
	);
}

Test.$inject = ["$resource"];
