export function testomatoErrorHandler($q, Flash) {
	return {
		response(response) {
			return response;
		},

		responseError(response) {
			let message;
			if (
				response.data.causedBy &&
				/mongo/i.test(response.data.causedBy.type)
			) {
				message =
					"Sorry, looks like something went wrong! Please refresh this page.";
			} else if (response.data.message && !response.data.show) {
				// Show - ukazujeme si to sami
				message = response.data.message;
			}

			if (message) {
				Flash.add(response.data.error ? "error" : "info", message);
			}

			return $q.reject(response);
		},
	};
}

testomatoErrorHandler.$inject = ["$q", "Flash"];
