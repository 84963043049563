<template>
	<div class="note mb-3 max-w-none">
		<h3>Sorry, you cannot add another project</h3>
		<p class="mb-3">
			You have reached the <strong>maximum number of projects</strong>
			allowed by your current subscription plan.
		</p>
		<a class="btn btn-success rounded-full no-underline" href="/pricing">
			<IconHeart/>
			Upgrade your subscription
		</a>
	</div>
</template>

<script lang="ts" setup>
import IconHeart from "../icons/icon-heart.vue";
</script>