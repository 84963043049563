import { confirmDialog } from "../functions/confirm.js";

export const cancelSubscription = {
	bindings: {
		refunds: "@",
		nextBillingDate: "@",
	},

	template:
		'<button type="button" class="btn btn-secondary" ng-click="$ctrl.confirmSubscriptionCancellation()">Cancel subscription</button>',

	controller: class CancelSubscriptionController {
		async confirmSubscriptionCancellation() {
			const isConfirmed = await confirmDialog({
				title: "Are you sure you want to cancel your subscription?",
				html: `<p>
								When you cancel your subscription at any point during your monthly or annual cycle, your 
								<strong>Testomato account remains active </strong> until the end of your billing period
								<em>(at ${this.nextBillingDate})</em>. 
							</p>${this.refunds ? '<p class="font-weight-bold text-danger">Testomato does not offer any refund for your remaining credit.</p>' : ""}`,
				confirmButtonText: "Yes, cancel Subscription!",
			});

			if (isConfirmed) {
				window.location.href = "/user/payments/payment-plan/cancel";
			}
		}
	},
};
