export default class MainController {
	constructor($scope, $location, auth) {
		this.$scope = $scope;
		$scope.$location = $location;
		$scope.class = null;
		$scope.auth = {};
		$scope.user = null;

		auth.then((auth) => {
			$scope.auth = auth;
			$scope.user = auth.user;
		});

		$scope.envName =
			window.location.hostname === "www.testomato.com" ? "stable" : "trunk";

		$scope.$on("$includeContentLoaded", () => {
			// $scope.testomato.commons.setDateForRegistrations(); // nastaví datum pro určení timezony prohlížeče
		});

		$scope.$on("$routeChangeStart", () => {
			$scope.class = $location
				.path()
				.toLowerCase()
				.trim()
				.replaceAll(/[^a-z\d\s-]/g, "");
		});
	}
}

MainController.$inject = ["$scope", "$location", "auth"];
