/* global google */
import { getScript } from "./get-script.js";

export function Google($q, $rootScope) {
	const deferred = $q.defer();

	getScript("https://www.google.com/jsapi")
		.then(() => {
			google.load("visualization", "1", {
				packages: ["corechart"],
				language: "en",
				callback() {
					$rootScope.$apply(() => {
						deferred.resolve(google);
					});
				},
			});
		})
		.catch(() => {
			deferred.reject();
		});

	return deferred.promise;
}

Google.$inject = ["$q", "$rootScope"];
