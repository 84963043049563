import angular from "angular";
import "angular-route";
import "angular-cookies";
import "angular-animate";
import "angular-resource";
import "angular-sanitize";
import angularDatePicker from "angular-date-picker";
import "angular-loading-bar";
import { config } from "./app.config.js";
// Modules
import "./modules/testomato.data.project.module.js";
import "./flash/flash.module.js";
import "./filters/index.js";
import "./directives/index.js";
import "./factories/index.js";
import "./components/index.js";
import "./resources/index.js";
import "./services/index.js";
import { signUpForm } from "./directives/sign-up-form.directive.js";
// Directives
import { ttUniqueEmail } from "./directives/tt-unique-email.directive.js";
// Controllers
import MainController from "./main-controller.js";
import AddCheckController from "./project/add-check/add-check-controller.js";
import AddFormCheckController from "./project/add-form-check/add-form-check-controller.js";
import AddMultipleChecksController from "./project/add-multiple-checks/add-multiple-checks-controller.js";
import CreateGroupController from "./project/create-group/create-group-controller.js";
import ProjectChecksCtrl from "./project/project-checks/project-checks-ctrl.js";
import ProjectController from "./project/project-controller.js";
import CreateProjectController from "./project/project-create/create-project-controller.js";
import ProjectReportsController from "./project/project-reports/project-reports-controller.js";
import ProjectSettingsController from "./project/project-settings/project-settings-controller.js";
import ManageUsersController from "./project/project-users/manage-users-controller.js";
import SignUpController from "./project/sign-up-controller.js";
import TemplateDirectiveController from "./project/template-directive-controller.js";
import TestController from "./project/test/test-controller.js";
import TemplatesController from "./templates/templates-controller.js";
import UserProfileController from "./user/user-profile-controller.js";

angular
	.module("testomato", [
		"ngCookies",
		"ngRoute",
		"ngAnimate",
		"flash",
		angularDatePicker,
		"angular-loading-bar",
		"testomato.resources",
		"testomato.services",
		"testomato.factories",
		"testomato.data.project",
		"testomato.filter",
		"testomato.directive",
		"testomato.components",
	])
	.controller("MainController", MainController)
	.controller("ProjectChecksCtrl", ProjectChecksCtrl)
	.controller("AddCheckController", AddCheckController)
	.controller("AddFormCheckController", AddFormCheckController)
	.controller("AddMultipleChecksController", AddMultipleChecksController)
	.controller("CreateGroupController", CreateGroupController)
	.controller("TestController", TestController)
	.controller("ProjectSettingsController", ProjectSettingsController)
	.controller("ManageUsersController", ManageUsersController)
	.controller("ProjectReportsController", ProjectReportsController)
	.controller("TemplateDirectiveController", TemplateDirectiveController)
	.controller("UserProfileController", UserProfileController)
	.controller("ProjectController", ProjectController)
	.controller("TemplatesController", TemplatesController)
	.controller("SignUpController", SignUpController)
	.controller("CreateProjectController", CreateProjectController)
	.directive("ttUniqueEmail", ttUniqueEmail)
	.directive("signUpForm", signUpForm)

	.config(config);
