/**
 * @name createGroupController
 * @type CreateGroupController
 */
export default class CreateGroupController {
	constructor($scope, $http, $location, Area, Authentication, User) {
		this.$scope = $scope;
		this.$http = $http;
		this.$location = $location;
		this.Area = Area;
		this.User = User;
		this.Authentication = Authentication;

		// Init values
		$scope.area = {
			name: "",
			rules: [],
			httpAuth: null,
			form: null,
			public: true,
			projectId: $scope.project.id,
		};
		$scope.auth = {
			type: undefined,
			url: "",
			search: false,
			forms: [],
		};

		// Getting subscription
		$scope.isAllowedCreateProtectedArea = true;
		User.subscription({ projectId: $scope.project.id }, (subscription) => {
			$scope.isAllowedCreateProtectedArea =
				subscription.isAllowed.createProtectedArea;
		});

		$scope.showResponse = false;
	}

	/**
	 * Vyhledá metody autentifikace
	 */
	searchAuthenticationMethod(url, projectId) {
		if (url) {
			this.$scope.auth.search = true;
			this.$scope.auth.type = null;
			this.$scope.auth.forms = [];

			this.Authentication.search(
				{ url, projectId },
				(auth) => {
					this.$scope.auth = auth;
					this.$scope.area.public = false;
					this.$scope.area.httpAuth = null;
					this.$scope.area.form = null;
				},
				(error) => {
					this.$scope.area.public = true;
					this.$scope.area.httpAuth = null;
					this.$scope.area.form = null;
					this.$scope.auth.search = false;
					console.error(error);
				},
			);
		}
	}

	/**
	 * Akce vyvolaná vybrání formuláře ze seznamu
	 * @param form
	 */
	selectForm(form) {
		this.$scope.area.form = form;
	}

	/**
	 * Uložím novou areu
	 */
	save(area) {
		this.Area.save({ projectId: this.$scope.project.id }, area, () => {
			this.$location.path("/tests");
		});
	}

	/**
	 * Otestuje autentikaci jestli funguje
	 */
	verifyCredentials(area) {
		this.$scope.showResponse = true;
		const embedResponse = document.querySelector("#response");

		this.Authentication.verify(
			{ area },
			(response) => {
				embedResponse.setAttribute("src", response);
				embedResponse.style.height = `${Math.ceil(window.screen.height * 0.75)}px`;
			},
			(error) => {
				console.error(error);
			},
		);
	}

	/**
	 * Odstrani autentifikaci
	 */
	removeAuth() {
		// Přenastavím area
		this.$scope.area.httpAuth = null;
		this.$scope.area.form = null;
		this.$scope.area.public = true;

		// Data
		this.$scope.auth.type = undefined;
		this.$scope.auth.url = undefined;
		this.$scope.auth.forms = [];
	}

	static get $inject() {
		return ["$scope", "$http", "$location", "Area", "Authentication", "User"];
	}
}
