import { getCurrentProjectId } from "../functions/get-current-project-id.js";

const ProjectController = function ProjectController($scope, Project) {
	$scope.project = { id: getCurrentProjectId() };

	this.$onInit = () => {
		Project.get({ projectId: $scope.project.id }, (project) => {
			$scope.project = project;
		});
	};
};

ProjectController.$inject = ["$scope", "Project"];

export default ProjectController;
