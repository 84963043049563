import { isValidUrl } from "../functions/is-valid-url.js";

export const multiple = {
	bindings: {
		items: "=?",
		urlPrefix: "@?",
		inputClass: "@?",
		inputPlaceholder: "@?",
		inputMaxCount: "@?",
	},

	template: `
		<ng-form name="multipleUrlInputsForm">
			<div class="flex mb-3 last:mb-0"			 
			ng-class="{'has-error': multipleUrlInputsForm['url_input_' + $index].$invalid}"
			ng-repeat="item in $ctrl.items track by $index">
				<input
					type="url"
					name="url_input_{{$index}}"
					class="rounded-r-none z-10 {{$ctrl.inputClass}}"
					placeholder="{{$ctrl.inputPlaceholder}}"
					ng-model="$ctrl.items[$index]"
					ng-keydown="$ctrl.onKeyDown($event, $index)"
					ng-keyup="$ctrl.onInputKeyUp($event, $index)"
					ng-paste="$ctrl.onInputPaste($event, $index)"
					ng-blur="$ctrl.onInputBlur($event, $index)"
					ng-change="$ctrl.onInputChange()"
					autocomplete="off"
					autofocus="$index === 0"
					pattern="^([Hh][Tt][Tt][Pp][Ss]?:\\/\\/)[a-zA-Z0-9-\u00A1-\uFFFF]{1,63}(?:\\.[a-zA-Z0-9+&@#\\/%=~_|\\-\\[\\]'()*;:,$!?]{1,})+"
					ng-model-options="{ allowInvalid: true }"
					tabindex="$index"
				>
        <button
          type="button" 
          class="rounded-l-none px-3 border-l-0 focus:ring-0 {{$ctrl.inputClass}}"
          ng-disabled="$ctrl.items.length === 1" 
          ng-click="$ctrl.removeInput($index)"
          tabindex="-1">
          <i class="material-icons">close</i>
          </button>
			</div>
		</ng-form>
	`,
	controller: class MultipleInputController {
		constructor($scope, $element, $timeout) {
			this.$scope = $scope;
			this.$element = $element;
			this.$timeout = $timeout;
		}

		static get $inject() {
			return ["$scope", "$element", "$timeout"];
		}

		onInputChange() {
			if (this.inputMaxCount && this.items.length >= this.inputMaxCount) {
				return;
			}

			// přidáme poslední prázný input, pokud není
			if (this.items.at(-1) !== "") {
				this.items.push("");
			}
		}

		onKeyDown($event, $index) {
			// check if new input can be added
			if (this.inputMaxCount && this.items.length >= this.inputMaxCount) {
				return;
			}

			// up and down arrow
			if ($event.keyCode === 38 || $event.keyCode === 40) {
				this.$timeout(() => {
					const nextPrevious =
						this.$element.find("input")[
							$index + ($event.keyCode === 38 ? -1 : 1)
						];
					if (nextPrevious) {
						nextPrevious.focus();
					}
				});
			}

			// allow add new element with enter or tab key
			if ($event.keyCode === 13) {
				$event.preventDefault(); // prevent form submit

				this.items.splice($index + 1, 0, ""); // Add element on $index

				// DO NOT $timeout with $evalAsync !!! it causes errors with focusing next element
				this.$timeout(() => {
					const next = this.$element.find("input")[$index + 1];
					if (next) {
						next.focus();
					}
				});
			}
		}

		onInputBlur($event, $index) {
			if ($event.target.value) {
				const url =
					isValidUrl($event.target.value) ||
					isValidUrl(this.urlPrefix + $event.target.value);
				if (url) {
					this.$scope.$evalAsync(() => {
						this.items[$index] = url.toString();
					});
				}
			}
		}

		/**
		 * Po dokončení psaní (debounce 450ms) se zkusí zadaná hodnota prefixnout
		 * @param $event
		 * @param $index
		 */
		onInputKeyUp($event, $index) {
			if ($event.target.value) {
				if (/^\d/gi.test($event.target.value)) {
					// uživatel začal číslem (čekáme dokud nedopíše IP adresu)
					if (
						/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/gi.test(
							$event.target.value,
						)
					) {
						this.$scope.$evalAsync(() => {
							this.items[$index] = `https://${$event.target.value}`;
						});
					}
				} else if (
					!/^(ht?t?p?s?:?\/?\/?)$/gi.test($event.target.value) &&
					!isValidUrl($event.target.value)
				) {
					const url = isValidUrl(this.urlPrefix + $event.target.value);

					if (url) {
						this.$scope.$evalAsync(() => {
							this.items[$index] = url.toString().replace(/\/$/, "");
						});
					}
				}
			}
		}

		removeInput($index) {
			if (this.items.length > 1) {
				this.items.splice($index, 1);
			}
		}

		onInputPaste($event, $index) {
			if ($event.originalEvent.clipboardData !== undefined) {
				const urls = $event.originalEvent.clipboardData
					.getData("text/plain")
					.trim()
					.split(/\r?\n/)
					.map((url) => url.trim())
					.map((url) => isValidUrl(url) || isValidUrl(this.urlPrefix + url))
					.filter(Boolean);

				if (urls.length === 1) {
					return;
				}

				this.$scope.$evalAsync(() => {
					// změním URL
					if (!$event.target.value) {
						this.items[$index] = urls.shift();
					}

					// Add other lines
					for (const url of urls) {
						if (this.inputMaxCount && this.items.length >= this.inputMaxCount) {
							break; // končím, mám dost
						}

						this.items.push(url);
					}
				});

				$event.preventDefault();
			}
		}
	},
};
