/**
 * Funkce vyledá label
 * @param inputHtml
 * @param input
 * @returns {string|*}
 */
export function getInputLabel(inputHtml, input) {
	const labelElement = input.id
		? inputHtml.querySelector(`label[for="${input.id}"]`)
		: input.closest("label");

	// Cleanup labelElement
	if (labelElement) {
		for (const n of labelElement.querySelectorAll(
			"input,textarea,button,select",
		)) {
			n.remove();
		}
	}

	// Check label textContent
	if (labelElement?.textContent?.trim()) {
		return labelElement.textContent.trim();
	}

	return input.name;
}
