export const paymentPlanSelect = {
	bindings: {
		currency: "@",

		paymentInterval: "@",
		paymentPlanName: "@",
		paymentPlanId: "@",

		monthlyPrice: "@",
		yearlyPrice: "@",

		selectedPrice: "=?",
		selectedCurrency: "=?",
		selectedPaymentInterval: "=?",
	},

	template: `<input type="text" name="paymentPlanId" ng-model="$ctrl.paymentPlanId" required hidden/>
<input type="text" name="amount" ng-model="$ctrl.selectedPrice" required hidden/>
<input type="text" name="currency" ng-model="$ctrl.selectedCurrency" required hidden/>
<input type="text" name="paymentInterval" ng-model="$ctrl.selectedPaymentInterval" required hidden/>

<div class="panel">
	<h5 class="panel-header">Chose payment plan</h5>
	<div class="panel-body">
		<h1 class="mb-2">
			<strong class="text-2xl">Testomato {{ $ctrl.paymentPlanName }} Payment Plan</strong>
			<span ng-switch="$ctrl.selectedPaymentInterval" class="text-gray-500 dark:text-gray-500">
						<span ng-switch-when="year">- yearly paid subscription</span>
						<span ng-switch-when="month">- monthly paid subscription</span>
			</span>
		</h1>

		<ul class="grid grid-cols-2 gap-3 my-3">

			<!-- Yearly payment interval -->
			<li ng-class="{selected: $ctrl.selectedPaymentInterval === 'year'}">
				<label class="flex justify-between border dark:border-gray-700 px-4 py-3 rounded-md cursor-pointer selected:ring dark:ring-blue-900 dark:ring-offset-gray-900 ring-offset-1 mb-0">
					<div>
						<input
							type="radio"
							value="year"
							class="peer mr-2"
							ng-change="$ctrl.selectPaymentInterval('year')"
							ng-model="$ctrl.selectedPaymentInterval">
						<span class="peer-checked:font-medium">yearly</span>
					</div>
					<div>
						for <span class="font-medium">{{ $ctrl.yearlyPrice | price : $ctrl.currency }} / year</span>
					</div>
				</label>
			</li>

			<!-- Monthly payment interval -->
			<li ng-class="{selected: $ctrl.selectedPaymentInterval === 'month'}">
				<label class="flex justify-between border dark:border-gray-700 px-4 py-3 rounded-md cursor-pointer selected:ring dark:ring-blue-900 dark:ring-offset-gray-900 ring-offset-1 mb-0">
					<div>
						<input
							type="radio"
							value="month"
							class="peer mr-2"
							ng-change="$ctrl.selectPaymentInterval('month')"
							ng-model="$ctrl.selectedPaymentInterval">
						<span class="peer-checked:font-medium">monthly</span>
					</div>
					<div>
						for <span class="font-medium">{{ $ctrl.monthlyPrice | price :  $ctrl.currency }} / month</span>
					</div>
				</label>
			</li>
		</ul>

		<span class="font-semibold text-lime-600 ml-5" ng-show="$ctrl.selectedPaymentInterval === 'month'">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="inline-flex align-top w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
				</svg>
				Save {{ $ctrl.monthlyPrice * 12 - $ctrl.yearlyPrice | price : $ctrl.currency}} yearly with annual subscription				
		</span>
	</div>
</div>
	`,

	controller: class PaymentPlanSelectController {
		constructor($scope) {
			this.$scope = $scope;
		}

		static get $inject() {
			return ["$scope"];
		}

		$onInit() {
			this.selectPaymentInterval(this.paymentInterval);
		}

		selectPaymentInterval(paymentInterval) {
			if (paymentInterval === "month" && this.monthlyPrice) {
				this.selectedPaymentInterval = paymentInterval;
				this.selectedCurrency = this.currency;
				this.selectedPrice = this.monthlyPrice;
			}

			if (paymentInterval === "year" && this.yearlyPrice) {
				this.selectedPaymentInterval = paymentInterval;
				this.selectedCurrency = this.currency;
				this.selectedPrice = this.yearlyPrice;
			}
		}
	},
};
