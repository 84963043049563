import { startProject } from "../app/functions/start-project.js";

const checkClasses = [
	"ok",
	"error",
	"warning",
	"disabled",
	"running",
	"completed",
	"unknown",
];

/**
 * Nastartuje testy pro vybranou skupinu
 * @param group
 * @returns {*}
 */
async function startGroup(group) {
	const projectId = group.dataset?.projectid;
	const groupId = group.dataset?.groupid;
	const checkIds = [...group.querySelectorAll("li.test:not(.disabled)")].map(
		(check) => check.dataset?.testid,
	);

	for await (const data of startProject(projectId, groupId, checkIds, true)) {
		if (data?.testId) {
			const check = group.querySelector(`[data-testid="${data.testId}"]`);
			check.classList.remove(...checkClasses);
			check.classList.add(data?.status || "unknown");
		}
	}
}

/**
 *
 * @param event
 * @return {Promise<void>}
 */
window.runChecks = async (event) => {
	event.preventDefault();
	event.target.disabled = true;

	// najdu si nadřazený projekt
	const project = event.target.closest("[data-projectid]");

	// označit všechny testy jako spuštěné
	const checks = project.querySelectorAll("[data-testid]:not(.disabled)");

	for (const check of checks) {
		check.classList.remove(...checkClasses);
		check.classList.add("running");
	}

	// najdu si skupiny testů a všechny pustím
	const groupsResults = [...project.querySelectorAll("[data-groupid]")].map(
		startGroup,
	);

	// počkám si na dokončení stahování
	await Promise.allSettled(groupsResults);

	// povolím zase tlačítko
	event.target.disabled = false;
};
