const testResolve = ($q, $location, $route, Test) => {
	const d = $q.defer();
	Test.get({ testId: $route.current.params.testId }, (test) => {
		d.resolve(test);
	});
	return d.promise;
};

testResolve.$inject = ["$q", "$location", "$route", "Test"];

export { testResolve };
